/*
    Developer's test list page
*/

import React, { Component } from 'react'
import Dashboard from '../dashboard'
import { connect } from 'react-redux'
import BigList from '../big-list'
import axios from 'axios'

class TestListPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            batteryID: props.match.params.batteryID,
            listItems: [],
            tests: []
        }
    }

    async componentDidMount() {
        await this.getTestDirectory()
        this.createTestListItems()
    }

    createNewTest = () => {
        const data = {
            batteryID: this.state.batteryID
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/test/create', data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            // return destination to BigList
            return `/developer/batteries/${this.state.batteryID}/tests/${response.data.testID}/info`
        })
        .catch(error => { console.log(error) })
    }

    createTestListItems = () => {
        let items = this.state.tests.map((test) => {
            return {
                destination: `/developer/batteries/${this.state.batteryID}/tests/${test._id}/info`,
                filterValue: `${test.name || ''}`.toLowerCase(),
                title: `${test.name || ''}`,
                uniqueKey: test._id
            }
        })

        this.setState({
            listItems: items
        })
    }

    didReorderTest = (fromIndex, toIndex) => {
        // reorder local copy of test list
        let newTests = this.state.tests
        let movedTest = newTests.splice(fromIndex, 1).pop()
        newTests.splice(toIndex, 0, movedTest)
        this.setState({
            tests: newTests
        }, () => this.createTestListItems())

        // send changes to api
        const data = {
            batteryID: this.state.batteryID,
            fromIndex: fromIndex,
            toIndex: toIndex
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/battery/moveTest', data, config)
        .then(response => {
            this.getTestDirectory()
            .then(() => {
                this.createTestListItems()
            })
        })
        .catch(error => { console.log(error) })
    }

    getTestDirectory() {
        const data = {
            batteryID: this.state.batteryID
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/battery/testDirectory', data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            this.setState({
                tests: response.data.tests
            })
        })
        .catch(error => { console.log(error) })
    }

    render() {
        return (
            <Dashboard>
                <BigList
                    didReorder={this.didReorderTest}
                    items={this.state.listItems}
                    onClickCreateNew={this.createNewTest}
                    reorderable
                    title='Tests'
                />
            </Dashboard>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.token
})

export default connect(mapStateToProps, null)(TestListPage)