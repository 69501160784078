/*
    List of reports
*/

import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from './dashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import BigList from './big-list'
import { useParams } from 'react-router-dom'

const ReportListPage = () =>
{
    const token = useSelector(state => state.token)
    const { batteryID, testID }  = useParams()

    const [listItems, setListItems] = useState([])
    const [reports, setReports] = useState([])


    const getReports = useCallback(async () =>
    {
        const data =
        {
            testID: testID
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/report/forTest', data, config)
        .catch(error => { console.log(error) })

        setReports(response.data.reports)
    }, [testID, token])

    const createReport = useCallback(async () =>
    {
        const data =
        {
            testID: testID
        }
  
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/report/create', data, config)
        .catch(error => { console.log(error) })

        // return destination to BigList
        return `/developer/batteries/${batteryID}/tests/${testID}/reports/${response.data.reportID}/info`
    }, [batteryID, testID, token])

    const createReportListItems = useCallback(() =>
    {
        let items = reports.map(report =>
        {
            return {
                destination: `/developer/batteries/${batteryID}/tests/${testID}/reports/${report._id}/info`,
                filterValue: `${report.name || ''}`.toLowerCase(),
                title: `${report.name || ''}`,
                uniqueKey: report._id
            }
        })

        setListItems(items)
    }, [batteryID, reports, testID])
    

    useEffect(() =>
    {
        getReports()
    }, [getReports])

    useEffect(() =>
    {
        createReportListItems()
    }, [reports, createReportListItems])


    return (
        <Dashboard>
            <BigList
                items={listItems}
                onClickCreateNew={createReport}
                title='Reports'
            />
        </Dashboard>
    )
}


export default ReportListPage