import React, { useCallback, useState, useEffect } from 'react'
import styles from '../../../style/item-content/function-detail-toolbar.module.css'
import { Transforms } from 'slate'
import { useSlateStatic } from 'slate-react'
import Editor from '../item-content-editor'
import { DefaultFunctionNode, Expression } from '../../expression'

/*
    Function Detail Toolbar
*/

const FunctionDetailToolbar = () =>
{
    const editor = useSlateStatic()

    const [activeNode, setActiveNode] = useState(null)
    const [selection, setSelection] = useState(null)
    const [expression, setExpression] = useState({})
    const [updateExpression, setUpdateExpression] = useState({})
    const [initExpression, setInitExpression] = useState({})
    
    // update selection and activeNode when editor.selection changes to a non-null value
    useEffect(() =>
    {
        if (editor.selection)
        {
            let activeNode = Editor.getNode(editor, 'function')
            setSelection(editor.selection)
            setActiveNode(activeNode)
            setExpression(activeNode ? activeNode.expression : {})
            setUpdateExpression(activeNode ? activeNode.updateExpression : {})
            setInitExpression(activeNode ? activeNode.initExpression : {})
        }
    }, [editor, editor.selection])


    return  <div className={styles['container']}>
                {/* evaluated on click */}
                <Expression
                    node={expression}
                    onChange={useCallback((newExpression) =>
                    {
                        if (!selection) { return }
                        setExpression(newExpression)
                        Transforms.select(editor, selection)

                        Editor.withoutNormalizing(editor, ()  =>
                        {
                            // wrap
                            if (!activeNode)
                            {
                                Editor.wrap(editor, DefaultFunctionNode)
                                Transforms.collapse(editor, { edge: 'anchor' }) // TOOD: bandaid for bug(?), the selection used to wrap can't be used with getNode anymore
                            }

                            // update
                            Editor.setNodeProperties(editor, 'function', { expression: newExpression })
                        })
                    }, [activeNode, editor, selection])}
                    placeholder='click'
                />
                {/* evaluated on data change */}
                <Expression
                    node={updateExpression}
                    onChange={useCallback((newUpdateExpression) =>
                    {
                        if (!selection) { return }
                        setUpdateExpression(newUpdateExpression)
                        Transforms.select(editor, selection)

                        Editor.withoutNormalizing(editor, ()  =>
                        {
                            // wrap
                            if (!activeNode)
                            {
                                Editor.wrap(editor, DefaultFunctionNode)
                                Transforms.collapse(editor, { edge: 'anchor' }) // TOOD: bandaid for bug(?), the selection used to wrap can't be used with getNode anymore
                            }

                            // update
                            Editor.setNodeProperties(editor, 'function', { updateExpression: newUpdateExpression })
                        })
                    }, [activeNode, editor, selection])}
                    placeholder='update'
                />
                {/* evaluated at initial render */}
                <Expression
                    node={initExpression}
                    onChange={useCallback((newInitExpression) =>
                    {
                        if (!selection) { return }
                        setInitExpression(newInitExpression)
                        Transforms.select(editor, selection)

                        Editor.withoutNormalizing(editor, ()  =>
                        {
                            // wrap
                            if (!activeNode)
                            {
                                Editor.wrap(editor, DefaultFunctionNode)
                                Transforms.collapse(editor, { edge: 'anchor' }) // TOOD: bandaid for bug(?), the selection used to wrap can't be used with getNode anymore
                            }

                            // update
                            Editor.setNodeProperties(editor, 'function', { initExpression: newInitExpression })
                        })
                    }, [activeNode, editor, selection])}
                    placeholder='init'
                />
            </div>
}

export default FunctionDetailToolbar