import React, { createContext, useCallback, useContext, useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'


const ActionsContext                = createContext({})
const CurrentItemContext            = createContext({})
const DataContext                   = createContext({})
const IsAdministeringTestContext    = createContext(undefined)
const TimestampContext              = createContext({})


export const PollingContext = ({ children, offline }) =>
{
    const [currentItem, setCurrentItem]                     = useState(undefined)
    const [currentItemTimestamp, setCurrentItemTimestamp]   = useState(0)
    const [data, setData]                                   = useState({}) // the latest copy of the api's data with local changes mixed on top.
    const [dataTimestamp, setDataTimestamp]                 = useState(0)
    const [pollInterval, setPollInterval]                   = useState(1000)
    const [isAdministeringTest, setIsAdministeringTest]     = useState(undefined)
    const [sessionID, setSessionID]                         = useState(null)
    
    const source_poll                                       = useRef(axios.CancelToken.source())
    const source_sendLocalChanges                           = useRef(axios.CancelToken.source())
    const localChanges                                      = useRef({}) // changes to data triggered by the user/locally; until sync with api
    const localChanges_outgoingBuffer                       = useRef({}) // local changes are moved here while syncing with api
    

    const poll = useCallback(async () =>
    {
        const data =
        {
            currentItemTimestamp,
            dataTimestamp,
            sessionID
        }
      
        const config =
        {
            cancelToken: source_poll.current.token,
            headers:
            {
                'Content-Type': 'application/json'
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/session/poll', data, config)
        .catch(error => { console.log(error) })

        if (!response.data) throw new Error('Failed to retrieve poll response data.')

        ReactDOM.unstable_batchedUpdates(() =>
        {
            setPollInterval(response.data.pollingInterval)
            setIsAdministeringTest(response.data.isAdministeringTest)

            if (response.data.currentItemTimestamp !== undefined)
            {
                // set new currentItem values
                setCurrentItemTimestamp(response.data.currentItemTimestamp)
                setCurrentItem(response.data.currentItem)
            }

            if (response.data.dataTimestamp !== undefined)
            {
                // set new data values
                setDataTimestamp(response.data.dataTimestamp)
                setData(prevData =>
                {
                    // clear local changes buffer
                    if (Object.keys(localChanges_outgoingBuffer.current).length > 0) localChanges_outgoingBuffer.current = {}
        
                    // merge local changes onto response
                    return {...response.data.data, ...localChanges.current}
                })
            }

            if (response.data.isAdministeringTest === false)
            {
                // clear content and data, since there is no test
                setCurrentItem(undefined)
                setData({})
                localChanges.current = {}
                localChanges_outgoingBuffer.current = {}
            }
        })
    }, [currentItemTimestamp, dataTimestamp, sessionID])

    // cancel active requests and reset data
    const resetData = useCallback(() =>
    {
        // TODO: test and decide on supporting online mode here.
        if (!offline) throw new Error('DataContext:resetData needs to be tested in online mode before use.')

        // cancel requests
        source_poll.current.cancel()
        source_sendLocalChanges.current.cancel()

        // reset vars to init values
        setData({})
        setPollInterval(1000)
        localChanges.current = {}
        localChanges_outgoingBuffer.current = {}
    }, [offline])

    // send data changes to api
    const sendLocalChanges = useCallback(async () =>
    {
        // don't do anything if there aren't any local changes
        if (Object.keys(localChanges.current).length === 0) return

        // copy local changes to buffer
        localChanges_outgoingBuffer.current = {...localChanges.current}

        // clear local changes
        localChanges.current = {}

        // TODO: modified buffer will need to go away when expressions refactors undefined to null
        const modifiedBuffer = {}
        for (const [key, value] of Object.entries(localChanges_outgoingBuffer.current))
        {
            modifiedBuffer[key] = value === undefined ? null : localChanges_outgoingBuffer.current[key]
        }

        // send local changes to the api
        const requestData =
        {
            data: modifiedBuffer,
            sessionID: sessionID
        }

        const config =
        {
            cancelToken: source_sendLocalChanges.current.token,
            headers:
            {
                'Content-Type': 'application/json'
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/session/setData', requestData, config)
        .catch(error =>
        {
            // reset data timestamp to force update next poll
            setDataTimestamp(0)

            console.log(error)
        })
    }, [sessionID])

    // upload local changes, then download latest data
    const syncWithApi = useCallback(async () =>
    {
        await sendLocalChanges()
        await poll()
    }, [poll, sendLocalChanges])

    // TODO: remove data from dep list so this doesn't trigger renders when used as a hook. will need to test everywhere it's used in a dep list to make sure the change doesn't break anything.
    // call back for context subscribers to submit changes to data
    const updateData = useCallback(newData =>
    {
        const currentData = {...data, ...localChanges_outgoingBuffer.current, ...localChanges.current}
        let dataWillChange = false
        for (const [key, value] of Object.entries(newData))
        {
            if (value !== currentData[key])
            {
                dataWillChange = true

                // apply new data to local changes
                localChanges.current[key] = value
            }
        }

        setData(prevState =>
        {
            if (dataWillChange) return {...prevState, ...localChanges_outgoingBuffer.current, ...localChanges.current}
            else return prevState 
        })
    }, [data])


    // sync data with api on interval
    useEffect(() =>
    {
        if (offline) return

        let newIntervalID = setInterval(
            () => syncWithApi(),
            pollInterval
        )
        return () => clearInterval(newIntervalID)
    }, [pollInterval, offline, syncWithApi])


    return  <ActionsContext.Provider value={{ resetData, setCurrentItem, setSessionID, updateData }}>
                <CurrentItemContext.Provider value={currentItem}>
                    <DataContext.Provider value={data}>
                        <IsAdministeringTestContext.Provider value={isAdministeringTest}>
                            <TimestampContext.Provider value={{ currentItemTimestamp, dataTimestamp }}>
                                {children}
                            </TimestampContext.Provider>
                        </IsAdministeringTestContext.Provider>
                    </DataContext.Provider>
                </CurrentItemContext.Provider>
            </ActionsContext.Provider>
}


export const useCurrentItem = () =>
{
    const currentItem = useContext(CurrentItemContext)
    return currentItem
}

export const useData = () =>
{
    const data = useContext(DataContext)
    if (!data) throw new Error('The "useData" hook must be used inside the PollingContext context.')
    return data
}

export const useDataActions = () =>
{
    const actions = useContext(ActionsContext)
    if (!actions) throw new Error('The "useDataActions" hook must be used inside the PollingContext context.')
    return actions
}

export const useIsAdministeringTest = () =>
{
    const isAdministeringTest = useContext(IsAdministeringTestContext)
    return isAdministeringTest
}

export const useTimestamps = () =>
{
    const timestamps = useContext(TimestampContext)
    if (!timestamps) throw new Error('The "useTimestamps" hook must be used inside the PollingContext context.')
    return timestamps
}