/*
    Examiner's list of batteries
*/

import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../dashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import BigList from '../big-list'


const BatteryListPage = () =>
{
    const [batteries, setBatteries] = useState([])
    const [listItems, setListItems] = useState([])
    const token = useSelector(state => state.token)



    const createBatteryListItems = useCallback(() =>
    {
        let items = batteries.map((battery) =>
        {
            return {
                destination: `/examiner/batteries/${battery._id}/info`,
                filterValue: `${battery.name || ''}`.toLowerCase(),
                title: `${battery.name || ''}`,
                uniqueKey: battery._id
            }
        })

        setListItems(items)
    }, [batteries])

    const getBatteries = useCallback(async () =>
    {
        let response = await axios.get(process.env.REACT_APP_API_URL + '/battery/directory',
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        })
        .catch(error => { console.log(error) })

        setBatteries(response.data.batteries)
    }, [token])


    useEffect(() =>
    {
        getBatteries()
    }, [getBatteries])

    useEffect(() =>
    {
        createBatteryListItems()
    }, [batteries, createBatteryListItems])


    return (
        <Dashboard>
            <BigList
                allowCreateNew={false}
                items={listItems}
                title='Batteries'
            />
        </Dashboard>
    )
}


export default BatteryListPage