import { createEditor, Node } from 'slate'
import { Slate, withReact } from 'slate-react'
import Editor from '../item-content/item-content-editor'
import forItemContent from './item-content-plugin'
import React, { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useMemo, useState } from 'react'
import { withHistory } from 'slate-history'
import { useCurrentItem, useData, useDataActions, useTimestamps } from '../item-content/polling-context'


/*
    A wrapper that provides slate context
*/
const ItemContentContext = (
                                {
                                    children,
                                    examinee = false,
                                    examiner = false,
                                    interactionEnabled = true,
                                    placeholder = undefined, // optional. displays when no content
                                    readOnly = true,
                                    value: propsValue = undefined // optional. overrides examinee/examiner flags
                                },
                                ref
                            ) =>
{
    const currentItem = useCurrentItem()
    const data = useData()
    const { updateData } = useDataActions()
    const { currentItemTimestamp } = useTimestamps()
    // The data hooks reach function elements before value updates via slate.
    // The timestamp copy gets uppdated at the same time as value, so it can be compared with the hook's timestamp value to check if slate is using the most recent content.
    const [currentItemTimestamp_copy, setCurrentItemTimestamp_copy] = useState(null)
    const [value, setValue] = useState(null) // local working copy of current item content document
    

    const editor = useMemo(() =>
    {
        // create editor with plugins
        const editor = forItemContent(withHistory(withReact(createEditor())))

        // init custom properties
        editor.data = {}

        return editor
    }, [])

    const defaultValue = [
        {
            type: 'paragraph',
            children: [{
                text: '',
                fontFamily: 'Roboto',
                fontSize: 20
            }],
        },
    ]

    useImperativeHandle(ref, () => {return {getFileNames, getValue}})

    const getFileNames = () =>
    {
        const fileNames = []
        for (const [node, ] of Node.nodes(editor))
        {
            switch (node.type)
            {
                default: break
                case 'audio':
                case 'image':
                case 'video': fileNames.push(node.url.split('/').pop())
            }
        }
        return fileNames
    }

    const getValue = () => { return value }


    useLayoutEffect(() => {editor.interactionEnabled = interactionEnabled}, [interactionEnabled, editor, editor.interactionEnabled])
    useLayoutEffect(() => {editor.readOnly = readOnly}, [readOnly, editor, editor.readOnly])
    useLayoutEffect(() => {editor.sendData = updateData}, [updateData, editor, editor.sendData]) 
    useLayoutEffect(() =>
    {
        // make a working copy of the current document
        if (propsValue) setValue(propsValue)
        else if (examinee && currentItem) setValue(currentItem.content.examinee.document)
        else if (examiner && currentItem) setValue(currentItem.content.examiner.document)
        else setValue(null)

        // copy the timestamp
        setCurrentItemTimestamp_copy(currentItemTimestamp)
    }, [currentItem, currentItemTimestamp, examinee, examiner, propsValue])
    useLayoutEffect(() =>
    {
        if (!editor.readOnly) return

        // update editor's copy of data
        editor.data = data

        // normalize all funcs
        Editor.normalizeAll(editor, 'function')
    }, [data, editor])


    return  <Slate
                children={children}
                editor={editor}
                onChange={useCallback(value => { setValue(value) }, [])}
                value={value || placeholder || defaultValue}
                currentItemTimestamp={currentItemTimestamp_copy} // gets assigned to editor
            />
}

export default forwardRef(ItemContentContext)