import * as ActionType from './action-types'

const initialState = {
    sessionID: '',
    token: '',
    userInfo: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case ActionType.getUserInfo:
            return {
                ...state,
                userInfo: action.userInfo
            }
        case ActionType.logout:
            return {
                ...state,
                token: '',
                userInfo: {}
            }
        case ActionType.setSessionID:
            return {
                ...state,
                sessionID: action.sessionID
            }
        case ActionType.setToken:
            return {
                ...state,
                token: action.token
            }
        default:
            return state
    }
}

export default reducer