import React, { Component } from 'react'
import styles from '../style/font-size-wrapper.module.css'

class FontSizeWrapper extends Component {

    constructor(props) {
        super(props)

        // declare reference to div wrapper
        this.wrapper = React.createRef()

        // initialize state
        this.state = {
            height: 1, // px
            width: 1 // px
        }

        // ensure function fontSizeForDimensions(heightPx, widthPx) is provided
        if (!this.props.fontSizeForDimensions) throw new Error('function fontSizeForDimensions must be provided.')

        // bind
        this.updateDimensions = this.updateDimensions.bind(this)
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions)
    }

    componentDidUpdate() {
        //  handle client dimension change caused by something other than a browser resize.
        if (this.state.height !== this.wrapper.current.clientHeight || this.state.width !== this.wrapper.current.clientWidth) {
            this.updateDimensions()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    updateDimensions() {
        this.setState({
                height: this.wrapper.current.clientHeight,
                width: this.wrapper.current.clientWidth
            })
    }

    render() {
        return (
            <div
                className={styles['container']}
                style={{
                    fontSize: `${this.props.fontSizeForDimensions(this.state.height, this.state.width)}px`
                }}
                ref={this.wrapper}
            >
                {this.props.children}
            </div>
        )
    }
}

export default FontSizeWrapper