import React from 'react'
import styles from '../../../style/item-content/color-picker.module.css'
import SizeWrapper from '../../size-wrapper'
import AspectRatioWrapper from '../../aspect-ratio-wrapper'

/*
    a color picker component
*/


const ColorPicker = ({
    color,
    colors = ['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#EB144C', '#9900EF'],
    didPickColor = (color) => {} }) => {

    return <div className={styles['container']} >
            {
                // colors
                colors.map((c, i) => {
                    return (
                        <SizeWrapper key={'sizeWrapper' + i} >
                            <AspectRatioWrapper
                                aspectRatio={1}
                                key={'aspectRatioWrapper' + i}
                            >
                                <div
                                    className={styles['color'] + ' ' + (c === color ? styles['selected'] : null)}
                                    onMouseDown={(event) =>
                                    {
                                        event.preventDefault()
                                        didPickColor(c)
                                    }}
                                    style={{ backgroundColor: c }}
                                />
                            </AspectRatioWrapper>
                        </SizeWrapper>
                    )
                })
            }
    </div>
}

export default ColorPicker