import React, { useState } from 'react'
import styles from '../../../style/item-content/audio-detail-toolbar.module.css'
import { Button, Slider } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { Transforms } from 'slate'
import { useSlate } from 'slate-react'
import Editor from '../item-content-editor'

/*
    Audio detail toolbar
*/

const AudioDetailToolbar = () =>
{
    const editor = useSlate()
    const [selection, setSelection] = useState(null)

    return <div className={styles['container']}>
        <div className={styles['upload-container']}>
            <Button
                icon={<UploadOutlined />}
                onMouseDown={event => {
                    event.preventDefault()
                    if (!editor.selection) {
                        Transforms.collapse(editor, { edge: 'end' })
                        setSelection(null)
                    }
                    document.getElementById('upload-audio').click()
                }}
                title='upload audio'
            />
        </div>
        <div className={styles['size-container']}>
            <Slider
                max={100}
                min={10}
                onBlur={() => { if (selection)
                    {
                        // Transforms.select(editor, selection)
                        setSelection(null)
                    }
                }}
                onChange={value => {
                    if (!selection || !value) return
                    Transforms.select(editor, selection) // TODO: send as 'at' to toggle
                    Editor.toggleInline(editor, 'width', value, true)
                }}
                onFocus={(event) => {
                    event.preventDefault()
                    setSelection(editor.selection)
                }}
                value={Editor.getNodeProperty(editor, 'audio', 'width')}
            />
        </div>
    </div>
}

export default AudioDetailToolbar