import React, { useMemo, useState } from 'react'
import Editor from '../item-content-editor'
import styles from '../../../style/item-content/item-content-toolbar.module.css'
import { Element, Transforms } from 'slate'
import { useSlate, useSlateStatic } from 'slate-react'
import { Button, Dropdown, Menu, Modal, Select } from 'antd'
import { AimOutlined, AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined, AudioOutlined, BoldOutlined, CalendarOutlined, EyeInvisibleOutlined, FontColorsOutlined, FunctionOutlined, GatewayOutlined, HighlightOutlined, ItalicOutlined, MenuOutlined, PictureOutlined, QuestionOutlined, RollbackOutlined, SaveOutlined, StrikethroughOutlined, TableOutlined, UnderlineOutlined, VideoCameraOutlined } from '@ant-design/icons'
import ColorPicker from './color-picker'
import Fonts from '../../../config/fonts'
import axios from 'axios'
import { useSelector } from 'react-redux'
import AudioDetailToolbar from './audio-detail-toolbar'
import FadeDetailToolbar from './fade-detail-toolbar'
import FunctionDetailToolbar from './function-detail-toolbar'
import HideDetailToolbar from './hide-detail-toolbar'
import ImageDetailToolbar from './image-detail-toolbar'
import TableDetailToolbar from './table-detail-toolbar'
import VideoDetailToolbar from './video-detail-toolbar'

/*
Toolbar for editing a slate document
*/

const Toolbar = ({
    contentID,
    cancelEditing = () => {},
    saveChanges = () => {}
}) =>
{
    const editor = useSlate()
    const [detailToolbar, setDetailToolbar] = useState(null) // string, name of the detail toolbar

    const marks = Editor.marks(editor)
    const backgroundColor = marks ? marks['backgroundColor'] : null
    const color = marks ? marks['color'] : null

    return editor.readOnly === false &&
    <div className={styles['container']}>
        <div className={styles['main-container']}>
            <MenuButton
                cancelEditing={cancelEditing}
                icon={<MenuOutlined />}
                saveChanges={saveChanges}
                title='menu'
            />
            <FontSizeSelect title='font size' />
            <FontFamilySelect title='font family' />
            <MarkButton type='bold' title='bold' icon={<BoldOutlined />} />
            <MarkButton type='italic' title='italic' icon={<ItalicOutlined />} />
            <MarkButton type='underline' title='underline' icon={<UnderlineOutlined />} />
            <MarkButton type='strikethrough' title='strikethrough' icon={<StrikethroughOutlined />} />
            {/* <WrapButton
                icon={<InteractionOutlined />}
                nodeProps={{
                    currentOutlineIndex: 0,
                    outlines: ['', '0.1vmin solid #EB144C', '0.1vmin solid #0693E3']
                }}
                title='outline cycle'
                type='outlineCycle'
            /> */}
            <DetailButton
                active={detailToolbar === 'fontColorPicker'}
                icon={<FontColorsOutlined />}
                onMouseDown={event =>
                {
                    event.preventDefault()
                    setDetailToolbar(detailToolbar === 'fontColorPicker' ? null : 'fontColorPicker')
                }}
                style={{ color: color }}
                title='font color'
            />
            <DetailButton
                active={detailToolbar === 'backgroundColorPicker'}
                icon={<HighlightOutlined />}
                onMouseDown={event =>
                {
                    event.preventDefault()
                    setDetailToolbar(detailToolbar === 'backgroundColorPicker' ? null : 'backgroundColorPicker')
                }}
                style={{ color: backgroundColor }}
                title='background color'
            />
            <DetailButton
                active={detailToolbar === 'fade'}
                highlighted={Editor.isInlineActive(editor, 'type', 'fade')}
                icon={<EyeInvisibleOutlined />}
                onMouseDown={event =>
                {
                    event.preventDefault()
                    setDetailToolbar(detailToolbar === 'fade' ? null : 'fade')
                }}
                title='fade'
            />
            <WrapButton
                icon={<GatewayOutlined />}
                title='text input'
                type='input'
            />
            <WrapButton
                icon={<CalendarOutlined />}
                title='date input'
                type='dateInput'
            />
            <DetailButton
                active={detailToolbar === 'hide'}
                highlighted={Editor.isBlockActive(editor, 'type', 'hide')}
                icon={<EyeInvisibleOutlined />}
                onMouseDown={event =>
                {
                    event.preventDefault()
                    setDetailToolbar(detailToolbar === 'hide' ? null : 'hide')
                }}
                title='hide'
            />
            <DetailButton
                active={detailToolbar === 'function'}
                highlighted={Editor.isInlineActive(editor, 'type', 'function')}
                icon={<FunctionOutlined />}
                onMouseDown={event =>
                {
                    event.preventDefault()
                    setDetailToolbar(detailToolbar === 'function' ? null : 'function')
                }}
                title='function'
            />
            <DetailButton
                active={detailToolbar === 'table'}
                highlighted={Editor.isInlineActive(editor, 'type', 'table')}
                icon={<TableOutlined />}
                onMouseDown={event =>
                {
                    event.preventDefault()
                    setDetailToolbar(detailToolbar === 'table' ? null : 'table')
                }}
                title='table'
            />
            <AlignButton value='start' title='align left' icon={<AlignLeftOutlined />} />
            <AlignButton value='center' title='align center' icon={<AlignCenterOutlined />} />
            <AlignButton value='end' title='align right' icon={<AlignRightOutlined />} />
            <FileUploadButton
                active={detailToolbar === 'image'}
                type='image'
                title='image'
                icon={<PictureOutlined />}
                contentID={contentID}
                onMouseDown={event =>
                {
                    event.preventDefault()
                    setDetailToolbar(detailToolbar === 'image' ? null : 'image')
                }}
            />
            <FileUploadButton
                active={detailToolbar === 'video'}
                type='video'
                title='video'
                icon={<VideoCameraOutlined />}
                contentID={contentID}
                onMouseDown={event =>
                {
                    event.preventDefault()
                    setDetailToolbar(detailToolbar === 'video' ? null : 'video')
                }}
            />
            <FileUploadButton
                active={detailToolbar === 'audio'}
                type='audio'
                title='audio'
                icon={<AudioOutlined />}
                contentID={contentID}
                onMouseDown={event =>
                {
                    event.preventDefault()
                    setDetailToolbar(detailToolbar === 'audio' ? null : 'audio')
                }}
            />
        </div>
        <div className={styles['detail-container']}>
            {renderDetailBar(editor, detailToolbar)}
        </div>
    </div>
}

const renderDetailBar = (editor, type) =>
{
    switch (type)
    {
        case 'audio': return <AudioDetailToolbar />
        case 'fade': return <FadeDetailToolbar />
        case 'fontColorPicker':
            return  <ColorPicker
                        color={Editor.marks(editor) ? Editor.marks(editor)['color'] : null}
                        didPickColor={(color) => { Editor.toggleMark(editor, 'color', color) }}
                    />
        case 'function': return <FunctionDetailToolbar />
        case 'backgroundColorPicker':
            return  <ColorPicker
                        color={Editor.marks(editor) ? Editor.marks(editor)['backgroundColor'] : null}
                        didPickColor={(color) => { Editor.toggleMark(editor, 'backgroundColor', color) }}
                    />
        case 'hide': return <HideDetailToolbar />
        case 'image': return <ImageDetailToolbar />
        case 'table': return <TableDetailToolbar />
        case 'video': return <VideoDetailToolbar />
        default: return null
    }
}

const AlignButton = ({icon, title, value}) =>
{
    const editor = useSlate()

    return <Button
        icon={icon}
        onMouseDown={event =>
        {
            event.preventDefault()

            const isActive = Editor.isPropertyActive(editor, 'textAlign', value)

            const [...match] = Editor.nodes(editor,
            {
                match: node => Element.isElement(node),
                mode: 'lowest'
            })

            for (const [, path] of match)
            {
                Transforms.setNodes(editor,
                {
                    textAlign: isActive ? null : value
                },
                {
                    at: path
                })
            }
        }}
        style={buttonStyle}
        title={title}
        type={Editor.isPropertyActive(editor, 'textAlign', value) ? 'primary' : 'default'}
    />
}

// const BlockButton = ({icon, property, title, value }) =>
// {
//     const editor = useSlate()

//     return <Button
//         icon={icon}
//         onMouseDown={event =>
//         {
//             event.preventDefault()
//             Editor.toggleBlock(editor, property, value)
//         }}
//         style={buttonStyle}
//         title={title}
//         type={Editor.isBlockActive(editor, property, value) ? 'primary' : 'default'}
//     />
// }

const DetailButton = ({ active, highlighted, icon, onMouseDown, style = {}, title }) =>
{
    return  <Button
                icon={icon}
                onMouseDown={onMouseDown}
                shape={active ? 'round' : null}
                style={{
                    ...buttonStyle,
                    ...style
                }}
                title={title}
                type={highlighted ? 'primary' : 'default'}
            />
}

const FileUploadButton = ({active, contentID, icon, onMouseDown, title, type}) =>
{
    const editor = useSlate()
    const token = useSelector(state => state.token)
    const [isLoading, setIsLoading] = useState(false)

    return <>
            <input
                id={`upload-${type}`}
                onChange={async event =>
                {
                    if (!event.target.files.length) return
                    event.persist()
                    setIsLoading(true)
                    const url = await uploadFile(event.target.files[0], contentID, token)
                    switch (type)
                    {
                        case 'audio':
                            Editor.insertAudio(editor, url, event.target.files[0].name)
                            break
                        case 'image':
                            Editor.insertImage(editor, url, event.target.files[0].name)
                            break
                        case 'video':
                            Editor.insertVideo(editor, url, event.target.files[0].name)
                            break
                        default:
                            break
                    }
                    setIsLoading(false)
                }}
                style={{display: 'none'}}
                type='file'
            />
            <Button
                icon={icon}
                loading={isLoading}
                onMouseDown={onMouseDown}
                shape={active ? 'round' : null}
                style={buttonStyle}
                title={title}
            />
        </>
}

const FontFamilySelect = ({title}) =>
{
    const editor = useSlate()
    const [selection, setSelection] = useState(null)

    let fontOptions = useMemo(
        () =>
        {
            return Fonts.map((font, i) =>
            {
                return (
                    <Select.Option key={font.font}>
                        <label style={{ fontFamily: font.font }}>
                            {font.font}
                        </label>
                    </Select.Option>
                )
            })
        },
        []
    )

    const marks = Editor.marks(editor)
    const font = marks ? marks['fontFamily'] : undefined

    return <Select
                dropdownMatchSelectWidth={false}
                onBlur={() => { if (selection) { Transforms.select(editor, selection) } }}
                onSelect={(value) =>
                {
                    if (!selection) return
                    Transforms.select(editor, selection)
                    Editor.toggleMark(editor, 'fontFamily', value)
                    // save the updated selection
                    setSelection(editor.selection)
                }}
                onFocus={(event) =>
                {
                    event.preventDefault()
                    setSelection(editor.selection)
                }}
                placeholder='family'
                showArrow={false}
                showSearch
                style={{ flex: '0 1 10%', maxWidth: '10%', minWidth: '10%' }}
                title={title}
                value={font}
            >
                {fontOptions}
            </Select> 

}

const FontSizeSelect = ({title}) =>
{
    const editor = useSlate()
    const [selection, setSelection] = useState(null)

    let sizeOptions = useMemo(
        () => {
            let sizeValues = []
            for (let i=10; i<=200; i++)
            {
                sizeValues.push(i)
            }
            
            return sizeValues.map((size, i) =>
            {
                return (
                    <Select.Option key={size}>
                        {size}
                    </Select.Option>
                )
            })
        },
        []
    )

    const marks = Editor.marks(editor)
    const fontSize = marks ? marks['fontSize'] : undefined

    return <Select
                onBlur={() =>
                {
                    if (!selection) return
                    Transforms.select(editor, selection)
                }}
                onSelect={(value) =>
                {
                    if (!selection) return
                    Transforms.select(editor, selection)
                    Editor.toggleMark(editor, 'fontSize', value)
                    // save the updated selection
                    setSelection(editor.selection)
                }}
                onFocus={(event) =>
                {
                    event.preventDefault()
                    setSelection(editor.selection)
                }}
                placeholder='size'
                showArrow={false}
                showSearch
                style={{ flex: '0 1 8%', maxWidth: '8%', minWidth: '8%' }}
                title={title}
                value={fontSize}
            >
                {sizeOptions}
            </Select> 
}

const MarkButton = ({icon, title, type, value}) =>
{
    const editor = useSlate()

    return <Button
        icon={icon}
        onMouseDown={event =>
        {
            event.preventDefault()
            Editor.toggleMark(editor, type, value)
        }}
        style={buttonStyle}
        title={title}
        type={Editor.isMarkActive(editor, type, value) ? 'primary' : 'default'}
    />
}

const MenuButton = ({ cancelEditing, icon, saveChanges, title }) =>
{
    const editor = useSlateStatic()
    const [visibleModal, setVisibleModal] = useState(null)

    const menu = (
        <Menu
            onClick={({ item, key, keyPath, domEvent }) =>
            {
                domEvent.preventDefault()
                onClickMenu({ item, key, keyPath, domEvent })
            }} 
        >
            <Menu.Item key='save'>
                <SaveOutlined />
                <span>Save</span>
            </Menu.Item>
            <Menu.Item key='cancel'>
                <RollbackOutlined />
                <span>Cancel</span>
            </Menu.Item>
            <Menu.Item key='data' disabled>
                <AimOutlined />
                <span>Data</span>
            </Menu.Item>
            <Menu.Item key='help' disabled>
                <QuestionOutlined />
                <span>Help</span>
            </Menu.Item>
        </Menu>
    )

    const onClickMenu = ({ item, key, keyPath, domEvent }) =>
    {
        domEvent.preventDefault()

        switch (key)
        {
            case 'cancel':
                cancelEditing()
                break
            case 'data':
                setVisibleModal('data')
                break
            case 'help':
                setVisibleModal('help')
                break
            case 'save':
                Editor.normalize(editor, {force: true})
                saveChanges()
                break
            default:
                break
        }
    }

    return  <>
                <Dropdown
                    overlay={menu}
                    placement='bottomLeft'
                    trigger={['click']}
                >
                    <Button
                        icon={icon}
                        style={buttonStyle}
                        title={title}
                    />
                </Dropdown>
                <Modal
                    destroyOnClose
                    onCancel={() => setVisibleModal(null)}
                    onOk={() => setVisibleModal(null)}
                    title='Data'
                    visible={visibleModal === 'data'}
                >
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                </Modal>
                <Modal
                    destroyOnClose
                    onCancel={() => setVisibleModal(null)}
                    onOk={() => setVisibleModal(null)}
                    title='Help'
                    visible={visibleModal === 'help'}
                >
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                </Modal>
            </>
}

const uploadFile = async (file, contentID, token) => {

    // generate presigned upload
    const data =
    {
        contentID: contentID,
        fileName: file.name
    }
  
    const config =
    {
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + token
        }
    }

    return axios.post(process.env.REACT_APP_API_URL + '/content/generatePresignedUpload', data, config)
    .then(response =>
    {
        const destinationURL = response.data.url + '/' + response.data.fields.key

        // upload the file
        let data = new FormData()
        data.append('key', response.data.fields['key'])
        data.append('bucket', response.data.fields['bucket'])
        data.append('X-Amz-Algorithm', response.data.fields['X-Amz-Algorithm'])
        data.append('X-Amz-Credential', response.data.fields['X-Amz-Credential'])
        data.append('X-Amz-Date', response.data.fields['X-Amz-Date'])
        data.append('Policy', response.data.fields['Policy'])
        data.append('X-Amz-Signature', response.data.fields['X-Amz-Signature'])
        data.append('file', file)

        return axios.post(response.data.url, data)
        .then(response =>
        {
            return destinationURL
        })
        .catch(error => { throw new Error(`There was a problem uploading ${file.name}: ${error.message}`) })
    })
    .catch(error => { throw new Error(`There was a problem uploading ${file.name}: ${error.message}`) })
}

const WrapButton = ({icon, nodeProps, title, type}) =>
{
    const editor = useSlate()
    let activeNode = Editor.getNode(editor, type)

    return <Button
        icon={icon}
        onMouseDown={event =>
        {
            event.preventDefault()
            if (activeNode) {Editor.unWrap(editor, type)}
            else {Editor.wrap(editor, { type, children: [], ...nodeProps})}
        }}
        style={buttonStyle}
        title={title}
        type={activeNode ? 'primary' : 'default'}
    />
}

// TODO: think about removing the antd button so i can use className instead of style
const buttonStyle =
{
    cursor: 'default',
    flex: '0 1 10%',
    height: '100%',
    fontSize: '1.75vmin',
    width: 'unset'
}

export default Toolbar