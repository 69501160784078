/*
    Examiner's session managment page
*/

import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Dashboard from '../dashboard'
import axios from 'axios'
import styles from '../../style/examiner/session-list-page.module.css'
import { useHistory } from 'react-router-dom'
import { Button, Input, Cascader, Select } from 'antd'
import BigList from '../big-list'


const SessionListPage = () =>
{
    const history = useHistory()
    const token = useSelector(state => state.token)
    const [batteries, setBatteries] = useState([]) // key: batteryID // TODO: rename to batteryOptions
    const [examinees, setExaminees] = useState({}) // Key: examineeID
    const [selectedExamineeIDs, setSelectedExamineeIDs] = useState({}) // key: sessionID
    const [selectedLabels, setSelectedLabels] = useState({}) // key: sessionID
    const [selectedTestIDs, setSelectedTestIDs] = useState({}) // key: sessionID
    const [sessionIDs, setSessionIDs] = useState([])


    /*
    sessionListItems:
    [
        {
            body
            destination
            filterValue
            subtitle
            title
            uniqueKey
        },
    ]
    */
    const [sessionListItems, setSessionListItems] = useState({})
    const [sessionInfo, setSessionInfo] = useState({})
    

    const getBatteries = useCallback(() =>
    {
        axios.get(process.env.REACT_APP_API_URL + '/battery/directory', 
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        })
        .then(response =>
        {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error(`could not get battery directory`)

            let batteries = response.data.batteries.map(async (battery) =>
            {
                // get test info
                const data =
                {
                    batteryID: battery._id
                }
                        
                const config =
                {
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + token
                    }
                }

                return axios.post(process.env.REACT_APP_API_URL + '/battery/testDirectory', data, config)
                .then(response =>
                {
                    // check to make sure a data object was returned
                    if (response.data == null) throw new Error(`could not get battery ${battery._id} test directory`)

                    // create test options
                    let testOptions = response.data.tests.map((test) =>
                    {
                        return {
                            value: test._id,
                            label: test.name,
                            isLeaf: true
                        }
                    })

                    return {
                        value: battery._id,
                        label: battery.name,
                        isLeaf: false,
                        children: testOptions
                    }
                })
                .catch(error => { console.log(error) })
            })

            Promise.all(batteries)
            .then(value =>
            {
                setBatteries(value)
            })
            .catch(error => { console.log(error) })
        })
        .catch(error => { console.log(error) })
    }, [token])

    const getExaminees = useCallback(() =>
    {
        axios.get(process.env.REACT_APP_API_URL + '/examinee/directory', 
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        })
        .then(response =>
        {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            setExaminees(response.data.examinees)
        })
        .catch(error => { console.log(error) })
    }, [token])

    const getSessionInfo = useCallback(() =>
    {
        let promises = sessionIDs.map(sessionID =>
        {
            const data =
            {
                sessionID: sessionID
            }
        
            const config =
            {
                headers:
                {
                    'Content-Type': 'application/json'
                }
            }
            
            return axios.post(process.env.REACT_APP_API_URL + '/session/info', data, config)
            .then(response =>
            {
                // check to make sure a data object was returned
                if (response.data == null) throw new Error('no response data')
                return {[sessionID]: response.data}
            })
            .catch(error => { console.log(error) })
        })

        Promise.all(promises)
        .then(value =>
        {
            if (value.length === 0) return

            let result = value.reduce((accumulator, currentValue) =>
            {
                return {...accumulator, ...currentValue}
            })
            setSessionInfo(result)
        })
        .catch(error => { console.log(error) })
    }, [sessionIDs])

    const getSessionIDs = useCallback(() =>
    {
        axios.get(process.env.REACT_APP_API_URL + '/session/getSessionIDs',
        {
            headers: 
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        })
        .then(response =>
        {
            if (response.data.sessionIDs == null) throw new Error('no response data')
            setSessionIDs(response.data.sessionIDs)
        })
        .catch(error => { console.log(error) })
    }, [token])

    const startTest = useCallback((sessionID) =>
    {
        const data =
        {
            examineeID: selectedExamineeIDs[sessionID],
            recordLabel: selectedLabels[sessionID],
            sessionID: sessionID,
            testID: selectedTestIDs[sessionID]
        }

        const config = 
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        axios.post(process.env.REACT_APP_API_URL + '/session/startTest', data, config)
        .then(response =>
        {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')
            
            // redirect to examiner test page
            history.push(`/examiner/test/${sessionID}`)
        })
        .catch(error => { console.log(error) })
    }, [history, selectedExamineeIDs, selectedLabels, selectedTestIDs, token])

    const createSessionListItemBodyElement = useCallback((sessionID) =>
    {
        let info = sessionInfo[sessionID]

        return (!info || info.hasActiveTest) ?
            null
            : <>
                <div className={styles['input-container']}>
                    <Select
                        allowClear
                        filterOption
                        onChange={newID => setSelectedExamineeIDs(prev => { return {...prev, [sessionID]: newID} })}
                        optionFilterProp='filtervalue'
                        placeholder='Examinee'
                        showSearch
                    >
                        {
                            Object.values(examinees).map(examinee =>
                            {
                                return (
                                    <Select.Option
                                        filtervalue={`${examinee.firstName} ${examinee.lastName} ${examinee.label}`}
                                        key={examinee._id}
                                    >
                                        {`${examinee.firstName} ${examinee.lastName}`}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                </div>
                <div className={styles['input-container']}>
                    <Cascader
                        onChange={newID => setSelectedTestIDs(prev => { return {...prev, [sessionID]: newID[1]} })}
                        options={batteries}
                        placeholder='Test'
                    />
                </div>
                <div className={styles['input-container']}>
                    <Input // TODO: figure out how to make this not render all items on key down
                        allowClear
                        onChange={event => setSelectedLabels(prev => { return {...prev, [sessionID]: event.target.value} })}
                        onPressEnter={(e) => e.target.blur()}
                        placeholder='Label'
                        value={selectedLabels[sessionID]}
                    />
                </div>
                <div className={styles['item-button-container']}>
                    <Button
                        className={styles['item-button']}
                        disabled={!selectedExamineeIDs[sessionID] || !selectedLabels[sessionID] || !selectedTestIDs[sessionID]}
                        onClick={() => startTest(sessionID)}
                        type='primary'
                    >
                        Start Test
                    </Button>
                </div>
            </>
    }, [examinees, batteries, selectedExamineeIDs, selectedLabels, selectedTestIDs, sessionInfo, startTest])

    const createSessionListItems = useCallback(() =>
    {
        let listItems = {}
        for(const [sessionID, info] of Object.entries(sessionInfo))
        {
            listItems[sessionID] =
            {
                body: createSessionListItemBodyElement(sessionID),
                destination: info.hasActiveTest ? `/examiner/test/${sessionID}` : null,
                filterValue: `${sessionID.toLowerCase()} ${info.examineeFirstName} ${info.examineeLastName}`,
                subtitle: `${sessionID || ''}`,
                title: `${info.examineeFirstName || ''} ${info.examineeLastName || ''}`,
                uniqueKey: sessionID
            }
        }
        setSessionListItems(listItems)
    }, [createSessionListItemBodyElement, sessionInfo])

    const createSession = useCallback(() =>
    {
        const data = {}
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        axios.post(process.env.REACT_APP_API_URL + '/session/create', data, config)
        .then(getSessionIDs)
        .catch(error => { console.log(error) })
    }, [getSessionIDs, token])


    // init
    useEffect(() =>
    {
        getBatteries()
        getExaminees()
        getSessionIDs()
    }, [getBatteries, getExaminees, getSessionIDs])

    useEffect(() =>
    {
        getSessionInfo()
    }, [getSessionInfo, sessionIDs])

    useEffect(() =>
    {
        createSessionListItems()
    }, [batteries, createSessionListItems, examinees])


    return (
        <Dashboard>
            <div className={styles['card']}>
                <div className={styles['list-container']}>
                    <BigList
                        createNew_customLabel='New Session'
                        items={Object.values(sessionListItems)}
                        onClickCreateNew={createSession}
                        title='Sessions'
                    />
                </div>
            </div>
        </Dashboard>
    )
}

export default SessionListPage