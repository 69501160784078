/*
    A page for editing data tables.
*/

import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../dashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from '../../style/info-card.module.css'
import Spreadsheet from '../spreadsheet'
import Input from '../input'
import { EditOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons' // TEMP: DeleteOutlined
import { Switch } from 'antd'


const TablePage = () =>
{
    const { reportID, tableID } = useParams()
    const token = useSelector(state => state.token)

    const [columns, setColumns] = useState({}) // { colName: { rowName: value } }
    const [columnDataTypes, setColumnDataTypes] = useState({}) // { colName: 'bool' || 'number' || 'string'}
    const [isEditing, setIsEditing] = useState(false)
    const [name, setName] = useState(null)
    const [appendColumnNames, setAppendColumnNames] = useState(false) // if column name should be appended to data for this table and report.
    const [appendTableName, setAppendTableName] = useState(false) // if table name should be appended to data for this table and report.
    const [examineeAges, setExamineeAges] = useState('') // string of examinee age array
    const [renderAgain, setRenderAgain] = useState(false) // set to true when editing row. render is not triggered by embeded objects.


    const addCol = useCallback((name, type) =>
    {
        setColumns(prev =>
        {
            return {...prev, [name]: {}}
        })
        setColumnDataTypes(prev =>
        {
            prev[name] = type
            return prev
        })
    }, [])

    const addRow = useCallback((name) =>
    {
        if (Object.keys(columns).length < 1) return

        const lastIndex = Object.keys(columns).length - 1
        const firstColName = Object.keys(columns)[lastIndex]
        const firstColType = columnDataTypes[firstColName]
        let defaultData = null
        if (firstColType === 'bool') defaultData = false
        else if (firstColType === 'number') defaultData = 0
        else if (firstColType === 'string') defaultData = ''

        setColumns(prev =>
        {
            let newCols = {...prev}
            newCols[firstColName][name] = defaultData
            return newCols
        })
        setRenderAgain(true)
    }, [columns, columnDataTypes])

    const deleteCol = useCallback(name =>
    {
        setColumns(prev =>
        {
            let newCols = {...prev}
            delete newCols[name]
            return newCols
        })
        setColumnDataTypes(prev =>
        {
            let newCols = {...prev}
            delete newCols[name]
            return newCols
        })
    }, [])

    const deleteRow = useCallback(name =>
    {
        setColumns(prev =>
        {
            let newCols = {...prev}
            for (const col of Object.values(newCols))
                delete col[name]
            return newCols
        })
        setRenderAgain(true)
    }, [])

    const editColName = useCallback((currentName, newName) =>
    {
        setColumns(prev =>
        {
            prev[newName] = prev[currentName]
            delete prev[currentName]
            return prev
        })
        setColumnDataTypes(prev =>
        {
            prev[newName] = prev[currentName]
            delete prev[currentName]
            return prev
        })
    }, [])

    const editDataType = useCallback((colName, type) =>
    {
        setColumnDataTypes(prev =>
        {
            return {...prev, [colName]: type}
        })

        // cast value
        setColumns(prev =>
        {
            const newValue = {...prev}
            for (const [rowName, value] of Object.entries(prev[colName]))
            {
                switch (type)
                {
                    case 'bool':
                        if (typeof value === 'boolean') newValue[colName][rowName] = value
                        else if (typeof value === 'number') newValue[colName][rowName] = !!value
                        else if (typeof value === 'string') newValue[colName][rowName] = value === 'true'
                        break

                    case 'number':
                        if (typeof value === 'boolean') newValue[colName][rowName] = value ? 1 : 0
                        else if (typeof value === 'number') newValue[colName][rowName] = value
                        else if (typeof value === 'string') newValue[colName][rowName] = parseFloat(value)
                        break
                        
                    case 'string':
                        if (typeof value === 'boolean') newValue[colName][rowName] = `${value}`
                        else if (typeof value === 'number') newValue[colName][rowName] = `${value}`
                        else if (typeof value === 'string') newValue[colName][rowName] = `${value}`
                        break

                    default: break
                }
            }

            return newValue
        })
    }, [])

    const editRowName = useCallback((currentName, newName) =>
    {
        setColumns(prev =>
        {
            for (const col of Object.values(prev))
            {
                if (col[currentName] != null)
                {
                    col[newName] = col[currentName]
                    delete col[currentName]
                }
            }
            return {...prev}
        })
        setRenderAgain(true)
    }, [])

    const editValue = useCallback((colName, rowName, value) =>
    {
        setColumns(prev =>
        {
            const newValue = {...prev}
            newValue[colName][rowName] = value
            return newValue
        })
    }, [])

    const getReport = useCallback(async () =>
    {
        const data =
        {
            reportID
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/report/get', data, config)
        .catch(error => { console.log(error) })

        if (response.data.report.table_namingRules[tableID])
        {
            setAppendColumnNames(!!response.data.report.table_namingRules[tableID].appendColumnNames)
            setAppendTableName(!!response.data.report.table_namingRules[tableID].appendTableName)
        }

        if (response.data.report.table_conditions[tableID])
        {
            const ages = response.data.report.table_conditions[tableID]['examinee_age_equals'] || []
            setExamineeAges(ages.toString())
        }
    }, [reportID, tableID, token])

    const getTable = useCallback(async () =>
    {
        const data =
        {
            tableID
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/table/get', data, config)
        .catch(error => { console.log(error) })

        setColumns(response.data.table.columns)
        setColumnDataTypes(response.data.table.columnDataTypes)
        setName(response.data.table.name)
    }, [tableID, token])

    const endEditing = useCallback(() =>
    {
        setIsEditing(false)
        getReport()
        getTable()
    }, [getReport, getTable])

    const saveReport = useCallback(async () =>
    {
        // get report
        const config_get =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/report/get', {reportID}, config_get)
        .catch(error => { console.log(error) })

        // conditions
        const ages_array = examineeAges.replace(/\s/g, ',').replace(/[^\d,]/g, '').split(',').map(x => Number.parseInt(x)).filter(x => !Number.isNaN(x))
        const table_conditions = response.data.report.table_conditions
        if (!table_conditions[tableID]) table_conditions[tableID] = {} // lazy init
        table_conditions[tableID]['examinee_age_equals'] = ages_array


        // naming rules
        const table_namingRules = response.data.report.table_namingRules
        if (!table_namingRules[tableID]) table_namingRules[tableID] = {} // lazy init
        table_namingRules[tableID]['appendColumnNames'] = appendColumnNames
        table_namingRules[tableID]['appendTableName'] = appendTableName

        // edit report
        const data =
        {
            reportID,
            table_conditions,
            table_namingRules
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/report/edit', data, config)
        .catch(error => { console.log(error) })
    }, [appendColumnNames, appendTableName, examineeAges, reportID, tableID, token])

    const saveTable = useCallback(async () =>
    {
        const data =
        {
            columns,
            columnDataTypes,
            name,
            tableID
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/table/edit', data, config)
        .catch(error => { console.log(error) })

        await saveReport()

        endEditing()
    }, [columns, columnDataTypes, endEditing, name, saveReport, tableID, token])

    // set the entire table
    const setData = useCallback(data =>
    {
        setColumns(data)
    }, [])

    // set the entire data type
    const setDataTypes = useCallback(dataTypes =>
    {
        setColumnDataTypes(dataTypes)
    }, [])


    useEffect(() =>
    {
        getReport()
        getTable()
    }, [getReport, getTable])

    useEffect(() =>
    {
        setRenderAgain(false)
    }, [renderAgain])
    
    
    return (
        <Dashboard>
            <div className={styles['card']}>
                <div className={styles['header']}>
                    <label className={styles['name']}>
                        {name || ''}
                    </label>
                    {
                            // cancel button
                            isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={endEditing}
                                    >
                                        <RollbackOutlined />
                                        <label className={styles['header-button-label']}>
                                            cancel
                                        </label>
                                    </div>
                                ) : null
                        }
                        {
                            // save button
                            isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={saveTable}
                                    >
                                        <SaveOutlined />
                                        <label className={styles['header-button-label']}>
                                            Save
                                        </label>
                                    </div>
                                ) : null
                        }
                        {/* {
                            !isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={() => showDeleteConfirm()}
                                    >
                                        <DeleteOutlined />
                                        <label className={styles['header-button-label']}>
                                            Delete
                                </label>
                                    </div>
                                ) : null
                        } */}
                        {
                            !isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={() => setIsEditing(true)}
                                    >
                                        <EditOutlined />
                                        <label className={styles['header-button-label']}>
                                            Edit
                                            </label>
                                    </div>
                                ) : null
                        }
                </div>
                <hr className={styles['horizontal-line']}></hr>
                <div className={styles['info-container']}>
                    <div className={styles['item-container']}>
                        <label className={styles['item-name']}>Name</label>
                        <Input
                            className={styles['item-string']}
                            disabled={!isEditing}
                            name='name'
                            onChange={e => setName(e.target.value)}
                            onPressEnter={e => e.target.blur()}
                            value={name}
                        />
                        <hr className={styles['item-underline']}></hr>
                    </div>
                    <div className={styles['item-container']}>
                        <label className={styles['item-name']}>Examinee Age(s)</label>
                        <Input
                            className={styles['item-string']}
                            disabled={!isEditing}
                            name='examineeAge'
                            onChange={e => setExamineeAges(e.target.value)}
                            onPressEnter={e => e.target.blur()}
                            value={examineeAges}
                        />
                        <hr className={styles['item-underline']}></hr>
                    </div>
                    <div className={styles['item-container']}>
                        <label className={styles['item-name']}>Append Column Names</label>
                        <Switch
                            disabled={!isEditing}
                            onChange={(checked) => setAppendColumnNames(checked)}
                            readOnly={!isEditing}
                            checked={appendColumnNames}
                        />
                        <hr className={styles['item-underline']}></hr>
                    </div>
                    <div className={styles['item-container']}>
                        <label className={styles['item-name']}>Append Table Name</label>
                        <Switch
                            disabled={!isEditing}
                            onChange={(checked) => setAppendTableName(checked)}
                            readOnly={!isEditing}
                            checked={appendTableName}
                        />
                        <hr className={styles['item-underline']}></hr>
                    </div>
                    <div className={styles['break']}></div>
                    <div className={styles['item-container']} height-80='true' width-90='true'>
                        <Spreadsheet
                            addCol={addCol}
                            addRow={addRow}
                            data={columns}
                            dataTypes={columnDataTypes}
                            deleteCol={deleteCol}
                            deleteRow={deleteRow}
                            editColName={editColName}
                            editDataType={editDataType}
                            editRowName={editRowName}
                            editable={isEditing}
                            editValue={editValue}
                            setData={setData}
                            setDataTypes={setDataTypes}
                        />
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}


export default TablePage