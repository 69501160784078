import React from 'react'
import styles from '../../../style/item-content/fade-detail-toolbar.module.css'
import { Checkbox, Radio } from 'antd'
import { useSlate } from 'slate-react'
import Editor from '../item-content-editor'
import NumberInput from '../../number-input'

/*
    Fade detail toolbar
*/

const FadeDetailToolbar = () =>
{
    const editor = useSlate()

    const activeNode = Editor.getNode(editor, 'fade')
    const defaultNode = {
        type: 'fade',
        children: [],
        name: null,
        delay: 3,
        didStart: false,
        duration: 0.3,
        fillMode: 'forwards',
        opacity: null,
        manualStart: false
    }

    return  <div className={styles['container']}>
                <div className={styles['radio-container']}>
                    <Radio.Group value={activeNode ? activeNode.name : defaultNode.name} >
                        <Radio.Button value='fadeIn'>
                            <span
                                onMouseDown={event => {
                                    event.preventDefault()
                                    switch (activeNode ? activeNode.name : null)
                                    {
                                        case 'fadeIn':
                                            Editor.unWrap(editor, 'fade')
                                            break
                                        case 'fadeOut':
                                            Editor.setNodeProperties(editor, 'fade', { name: 'fadeIn', opacity: 0 })
                                            break
                                        default:
                                            Editor.wrap(editor, { ...defaultNode, name: 'fadeIn', opacity: 0 })
                                            break
                                    }
                                }}
                            >
                                Fade In
                            </span>
                        </Radio.Button>
                        <Radio.Button value='fadeOut'>
                            <span
                                onMouseDown={event => {
                                    event.preventDefault()
                                    switch (activeNode ? activeNode.name : null)
                                    {
                                        case 'fadeOut':
                                            Editor.unWrap(editor, 'fade')
                                            break
                                        case 'fadeIn':
                                            Editor.setNodeProperties(editor, 'fade', { name: 'fadeOut', opacity: 1 })
                                            break
                                        default:
                                            Editor.wrap(editor, { ...defaultNode, name: 'fadeOut', opacity: 1 })
                                            break
                                    }
                                }}
                            >
                                Fade Out
                            </span>
                        </Radio.Button>
                    </Radio.Group>
                </div>
                <label className={styles['name']}>delay:</label>
                <div className={styles['input-container']}>
                    <NumberInput
                        disabled={!activeNode}
                        min={0}
                        onChange={value => Editor.setNodeProperties(editor, 'fade', { delay: value }) }
                        preventDefault
                        title='delay'
                        value={activeNode ? activeNode.delay : defaultNode.delay}
                    />
                </div>
                <label className={styles['name']}>duration:</label>
                <div className={styles['input-container']}>
                    <NumberInput
                        disabled={!activeNode}
                        max={5}
                        min={0}
                        onChange={value => Editor.setNodeProperties(editor, 'fade', { duration: value }) }
                        preventDefault
                        step={0.1}
                        title='duration'
                        value={activeNode ? activeNode.duration : defaultNode.duration}
                    />
                </div>
                <label className={styles['name']}>wait for click:</label>
                <div
                    className={styles['check-box-container']}
                    onMouseDown={event => {
                        event.preventDefault()
                        Editor.setNodeProperties(editor, 'fade', { manualStart: activeNode ? !activeNode.manualStart : defaultNode.manualStart })
                    }}
                >
                    <Checkbox
                        disabled={!activeNode}
                        checked={activeNode ? activeNode.manualStart : defaultNode.manualStart}
                    />
                </div>
                
            </div>
}

export default FadeDetailToolbar