import React, { useState } from 'react'
import styles from '../../../style/item-content/hide-detail-toolbar.module.css'
import { Transforms } from 'slate'
import { useSlate } from 'slate-react'
import Editor from '../item-content-editor'
import Input from '../../input'

/*
    Hide detail toolbar
*/

const HideDetailToolbar = () =>
{
    const editor = useSlate()
    const [selection, setSelection] = useState(null)
    const activeNode = Editor.getNode(editor, 'hide', selection)

    const defaultHideNode =
    {
        type: 'hide',
        children: [],
        varKey: ''
    }

    return <div
                className={styles['container']}
                onBlur={() => setSelection(null)}
                onFocus={() => setSelection(editor.selection)}
            >
                <div className={styles['varkey-container']}>
                <label className={styles['varkey']}>varKey:</label>
                    <div className={styles['varkey_input']}>
                        <Input
                            onBlur={() => { if (selection) { Transforms.select(editor, selection) } }}
                            onChange={event =>
                            {
                                event.preventDefault()
                                if (!selection) return
                                Transforms.select(editor, selection)

                                if (!activeNode)
                                {
                                    // wrap
                                    Editor.wrap(editor, {...defaultHideNode}, selection)
                                    setSelection(editor.selection)
                                }

                                if (activeNode && activeNode.varKey && !event.target.value)
                                {
                                    // unwrap
                                    Editor.unWrap(editor, 'hide')
                                    setSelection(editor.selection)
                                }else
                                {
                                    // update
                                    Editor.setNodeProperties(editor, 'hide', { varKey: event.target.value })
                                } 
                            }}
                            onFocus={(event) =>
                            {
                                event.preventDefault()
                                setSelection(editor.selection)
                            }}
                            value={activeNode ? activeNode.varKey : ''}
                        />
                    </div>
                </div>
            </div>
}

export default HideDetailToolbar