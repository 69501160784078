/*
    Report info page
*/

import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from './dashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styles from '../style/info-card.module.css'
import { Input, Modal } from 'antd'
import { DeleteOutlined, EditOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons'
import NumberInput from './number-input'

const ReportInfoPage = () =>
{
    const history = useHistory()
    const { batteryID, testID, reportID } = useParams()
    const token = useSelector(state => state.token)

    const [isEditing, setIsEditing] = useState(false)
    const [price, setPrice] = useState()
    const [report, setReport] = useState({})


    const deleteReport = useCallback(() =>
    {
        return new Promise((resolve, reject) =>
        {
            axios.delete(process.env.REACT_APP_API_URL + '/report/delete',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + token
                },
                data: {
                    reportID: reportID
                }
            })
            .then(response => {
                history.replace(`/developer/batteries/${batteryID}/tests/${testID}/reports`)
                resolve()
            })
            .catch(error => { console.log(error) })
        })
    }, [batteryID, testID, history, reportID, token])

    const getPrice = useCallback(async () =>
    {
        const data =
        {
            kind: 'price_recordReport',
            reportID: reportID
        }
    
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/price/get', data, config)
        .catch(error => { console.log(error) })

        setPrice((response.data.price || {}).price)
    }, [reportID, token])

    const getReport = useCallback(async () =>
    {
        const data =
        {
            reportID: reportID
        }
    
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/report/get', data, config)
        .catch(error => { console.log(error) })

        setReport(response.data.report)
    }, [reportID, token])

    const endEditing = useCallback(() =>
    {
        setIsEditing(false)
        getPrice()
        getReport()
    }, [getPrice, getReport])

    const editPrice = useCallback(async () =>
    {
        const data =
        {
            kind: 'price_recordReport',
            price: price,
            reportID: reportID,
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/price/edit', data, config)
        .catch(error => { console.log(error) })
    }, [price, reportID, token])

    const editReport = useCallback(async () =>
    {
        const data =
        {
            reportID: reportID,
            name: report.name
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/report/edit', data, config)
        .catch(error => { console.log(error) })

        await editPrice()

        endEditing()
    }, [editPrice, endEditing, report, reportID, token])

    const handleReportInfoChange = useCallback((event) =>
    {
        const { name, value } = event.target

        setReport(report => { return {...report, [name]: value} })
    }, [])

    const showDeleteConfirm = useCallback(() =>
    {
        Modal.confirm({
            cancelText: 'Cancel',
            content: 'This can not be undone!',
            maskClosable: true,
            okButtonProps: { type: 'danger' },
            okText: 'Delete',
            onCancel() { },
            onOk() {deleteReport()},
            title: 'Are you sure you want to delete this report?'
        })
    }, [deleteReport])


    useEffect(() =>
    {
        getPrice()
        getReport()
    }, [getPrice, getReport])


    return (
        <Dashboard>
                <div className={styles['card']}>
                    <div className={styles['header']}>
                        <label className={styles['name']}>
                            {report.name ? report.name : null}
                        </label>
                        {
                            // cancel button
                            isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={endEditing}
                                    >
                                        <RollbackOutlined />
                                        <label className={styles['header-button-label']}>
                                            cancel
                                        </label>
                                    </div>
                                ) : null
                        }
                        {
                            // save button
                            isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={editReport}
                                    >
                                        <SaveOutlined />
                                        <label className={styles['header-button-label']}>
                                            Save
                                        </label>
                                    </div>
                                ) : null
                        }
                        {
                            !isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={() => showDeleteConfirm()}
                                    >
                                        <DeleteOutlined />
                                        <label className={styles['header-button-label']}>
                                            Delete
                                </label>
                                    </div>
                                ) : null
                        }
                        {
                            !isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={() => setIsEditing(true)}
                                    >
                                        <EditOutlined />
                                        <label className={styles['header-button-label']}>
                                            Edit
                                            </label>
                                    </div>
                                ) : null
                        }
                    </div>
                    <hr className={styles['horizontal-line']}></hr>
                    <div className={styles['info-container']}>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Name</label>
                            <Input
                                className={styles['item-string']}
                                name='name'
                                onChange={handleReportInfoChange}
                                onPressEnter={(e) => e.target.blur()}
                                readOnly={!isEditing}
                                value={report.name}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']} height-15='true' width-10='true'>
                            <label className={styles['item-name']}>Price</label>
                            <NumberInput
                                disabled={!isEditing}
                                min={0}
                                onChange={value => setPrice(value) }
                                step={1}
                                title='price'
                                value={price}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                    </div>
                </div>
            </Dashboard>
    )
}


export default ReportInfoPage