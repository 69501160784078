/*
    Minimal page for viewing and printing reports.
*/

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { PollingContext, useDataActions } from './item-content/polling-context'
import ItemContent from './item-content/item-content'
import ItemContentContext from './item-content/item-content-context'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import infoCardStyles from '../style/info-card.module.css'
import reportPageStyles from '../style/report-page.module.css'
import { useSelector } from 'react-redux'
import ReactDOM from 'react-dom'

let styles = Object.assign(infoCardStyles, reportPageStyles)


const ReportPageContent = () =>
{
    const { recordID, reportID } = useParams()
    const token = useSelector(state => state.token)

    const printRef = useRef(() => {})

    const [data, setData] = useState({})
    const [document, setDocument] = useState([])

    const { updateData } = useDataActions()


    const getReport = useCallback(async () =>
    {
        if (!recordID || !reportID) return
    
        const data =
        {
            recordID: recordID,
            reportID: reportID
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/report/generate', data, config)
        .catch(error => { console.log(error) })

        ReactDOM.unstable_batchedUpdates(() =>
        {
            setDocument(response.data.document)
            setData(response.data.data)
        })
    }, [token, recordID, reportID])


    useEffect(() =>
    {
        if (!recordID || !reportID || !token) return

        getReport()
    }, [getReport, recordID, reportID, token])

    useEffect(() =>
    {
        updateData(data)
    }, [data, updateData])

    return (
            <div className={styles['container']}>
                <div className={styles['info-container']}>
                    <div
                        className={styles['item-container'] + ' hide-scrollbars'}
                        height-100='true'
                        scroll-vertical='true'
                        width-100='true'
                    >
                        <ItemContentContext
                            readOnly
                            value={document}
                        >
                            <ItemContent
                                printRef={printRef}
                                printDocumentTitle={'WICI report'}  // TODO: make this ~'testName, recordLabel, examineeName' etc.
                            />
                        </ItemContentContext>
                    </div>
                </div>
                <hr className={styles['horizontal-line']}></hr>
                <div className={styles['header-container']}>
                    <Button
                        onClick={printRef.current}
                        icon={<PrinterOutlined />}
                    >
                        Print
                    </Button>
                </div>
            </div>
    )
}


// a wraper for applying data context
const ReportPage = () =>
{
    return (
        <PollingContext offline >
            <ReportPageContent />
        </PollingContext>
    )
}

export default ReportPage