import React, { Component } from 'react'
import styles from '../style/aspect-ratio-wrapper.module.css'

// aspect ratio
// aspect-fit

/*
props:
- aspect ratio

must wrap in SizeWrapper so this has parent dimensions
*/
class AspectRatioWrapper extends Component {

    constructor(props) {
        super(props)

        // initialize state
        this.state = {
            height: 1, // px
            width: 1 // px
        }
    }

    componentDidMount() {
        this.updateDimensions()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.parentHeightPx !== this.props.parentHeightPx || prevProps.parentWidthPx !== this.props.parentWidthPx) {
            this.updateDimensions()
        }
    }

    updateDimensions = () => {
        const maxHeightPx = this.props.parentHeightPx
        const maxWidthPx = this.props.parentWidthPx
        var heightPx = 1
        var widthPx = 1

        if (this.props.aspectRatio > 1) {
            widthPx = maxWidthPx
            heightPx = maxWidthPx/this.props.aspectRatio

            if (heightPx > maxHeightPx) {
                heightPx = maxHeightPx
                widthPx = heightPx * this.props.aspectRatio
            }
        } else {
            heightPx = maxHeightPx
            widthPx = maxHeightPx * this.props.aspectRatio

            if (widthPx > maxWidthPx) {
                widthPx = maxWidthPx
                heightPx = widthPx/this.props.aspectRatio
            }
        }

        // save the results
        this.setState({
            height: heightPx,
            width: widthPx
        })
    }

    render() {
        return (
            <div
                className={styles['container']}
                style={{
                    height: `${this.state.height}px`,
                    width: `${this.state.width}px`
                }}
            >
                {this.props.children}
            </div>
        )
    }
}

AspectRatioWrapper.defaultProps =
{
    aspectRatio: 16/9,
}

export default AspectRatioWrapper