import React, { useCallback, useEffect, useState } from 'react'
import styles from '../../../style/item-content/table-detail-toolbar.module.css'
import { Button, Slider } from 'antd'
import { BorderOutlined, InsertRowAboveOutlined, InsertRowBelowOutlined, InsertRowLeftOutlined, InsertRowRightOutlined, DeleteRowOutlined, DeleteColumnOutlined, TableOutlined } from '@ant-design/icons'
import { Transforms } from 'slate'
import { useSlate } from 'slate-react'
import Editor from '../item-content-editor'

/*
    Table Detail Toolbar
*/

const TableDetailToolbar = () =>
{
    const editor = useSlate()
    const [selection, setSelection] = useState(null)
    const [table, setTable] = useState(undefined)

    const getTable = useCallback(() =>
    {
        const [match] = Editor.nodes(editor,
        {
            match: node => node.type === 'table',
            mode: 'lowest'
        })

        if (match) setTable(match[0])
    }, [editor])

    useEffect(() =>
    {
        getTable()
    }, [editor.selection, getTable])


    return  <div className={styles['container']}>
                <div className={styles['button-container']}>
                    <Button
                        icon={<TableOutlined />}
                        onMouseDown={event =>
                        {
                            event.preventDefault()
                            Editor.insertTable(editor)
                        }}
                        title='insert table'
                    />
                </div>
                <div className={styles['button-container']}>
                    <Button
                        icon={<InsertRowAboveOutlined />}
                        onMouseDown={event =>
                        {
                            event.preventDefault()
                            Editor.insertRow(editor)
                        }}
                        title='insert row above'
                    />
                </div>
                <div className={styles['button-container']}>
                    <Button
                        icon={<InsertRowBelowOutlined />}
                        onMouseDown={event =>
                        {
                            event.preventDefault()
                            Editor.insertRow(editor, true)
                        }}
                        title='insert row below'
                    />
                </div>
                <div className={styles['button-container']}>
                    <Button
                        icon={<InsertRowLeftOutlined />}
                        onMouseDown={event =>
                        {
                            event.preventDefault()
                            Editor.insertColumn(editor)
                        }}
                        title='insert column left'
                    />
                </div>
                <div className={styles['button-container']}>
                    <Button
                        icon={<InsertRowRightOutlined />}
                        onMouseDown={event =>
                        {
                            event.preventDefault()
                            Editor.insertColumn(editor, true)
                        }}
                        title='insert column right'
                    />
                </div>
                <div className={styles['button-container']}>
                    <Button
                        icon={<BorderOutlined />}
                        onMouseDown={event =>
                        {
                            event.preventDefault()
                            if (!table) return

                            const [match] = Editor.nodes(editor,
                            {
                                at: editor.selection,
                                match: node => node.type === 'table',
                                mode: 'lowest'
                            })

                            if (match)
                            {
                                Transforms.setNodes(editor,
                                {border: match[0].border ? undefined : '0.1vmin solid'},
                                {
                                    at: match[1]
                                })

                                getTable()
                            }
                        }}
                        title='border'
                        type={table && table.border ? 'primary' : 'default'}
                    />
                </div>
                <div className={styles['button-container']}>
                    <Button
                        icon={<DeleteRowOutlined />}
                        onMouseDown={event =>
                        {
                            event.preventDefault()
                            Editor.deleteRow(editor)
                        }}
                        title='delete row'
                    />
                </div>
                <div className={styles['button-container']}>
                    <Button
                        icon={<DeleteColumnOutlined />}
                        onMouseDown={event =>
                        {
                            event.preventDefault()
                            Editor.deleteColumn(editor)
                        }}
                        title='delete column'
                    />
                </div>
                <div className={styles['size-container']}>
                    <Slider
                        max={100}
                        min={1}
                        onBlur={() => { if (selection) { Transforms.select(editor, selection) } }}
                        onChange={value =>
                        {
                            if (!selection || !value) return
                            Transforms.setNodes(editor, {width: value},
                            {
                                at: selection,
                                match: node => node.type === 'table',
                                mode: 'lowest'
                            })
                            Transforms.select(editor, selection)
                        }}
                        onFocus={(event) =>
                        {
                            event.preventDefault()
                            setSelection(editor.selection) // TODO: this needs to be a ref or something. cursor goes away after sliding.
                        }}
                        value={Editor.getNodeProperty(editor, 'table', 'width')}
                    />
                </div>
                <div className={styles['size-container']}>
                    <Slider
                        max={100}
                        min={1}
                        onBlur={() => { if (selection) { Transforms.select(editor, selection) } }}
                        onChange={value =>
                        {
                            if (!selection || !value) return
                            Transforms.setNodes(editor, {colWidth: value},
                            {
                                at: selection,
                                match: node => node.type === 'tableCell',
                                mode: 'lowest'
                            })
                            Transforms.select(editor, selection)
                        }}
                        onFocus={(event) =>
                        {
                            event.preventDefault()
                            setSelection(editor.selection) // TODO: this needs to be a ref or something. cursor goes away after sliding.
                        }}
                        value={Editor.getNodeProperty(editor, 'tableCell', 'colWidth')}
                    />
                </div>
            </div>
}

export default TableDetailToolbar