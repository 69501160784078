/*
    Examiner's examinee info page
*/

import React, { Component } from 'react'
import Dashboard from '../dashboard'
import styles from '../../style/info-card.module.css'
import { connect } from 'react-redux'
import axios from 'axios'
import { DatePicker, Input, Modal, Radio, Select } from 'antd'
import { DeleteOutlined, EditOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons'
import moment from 'moment'


class ExamineeInfoPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            // enum values
            educationValues: [],
            genderValues: [],
            gradeValues: [],
            raceValues: [],
            stateValues: [],

            // examinee info
            examineeID: props.match.params.examineeID,
            examineeInfo: {
                birthDate: null,
                city: '',
                disability: '',
                fatherEducation: '',
                firstName: '',
                gender: '',
                grade: '',
                label: '',
                lastName: '',
                motherEducation: '',
                race: '',
                school: '',
                state: '',
                state_other: ''
            },

            // flags
            isEditing: false
        }

        this.dateFormat = 'YYYY/MM/DD'
    }

    componentDidMount() {
        this.getExamineeInfo()
        this.getEnumValues('education')
        this.getEnumValues('gender')
        this.getEnumValues('grade')
        this.getEnumValues('race')
        this.getEnumValues('state')
    }

    endEditing = () => {
        this.setState({
            isEditing: false
        })

        this.getExamineeInfo()
    }

    deleteExaminee = () => {
        return new Promise((resolve, reject) => {
            axios.delete(process.env.REACT_APP_API_URL + '/examinee/delete',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + this.props.token
                    },
                    data: {
                        examineeID: this.state.examineeID
                    }
                })
                .then(response => {
                    this.props.history.replace('/examiner/examinees')
                    resolve()
                })
                .catch(error => { console.log(error) })
        })
        .catch(error => { console.log(error) })
    }

    getEnumValues = (name) => {
        const data = {
            sessionID: this.props.sessionID
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return axios.get(process.env.REACT_APP_API_URL + '/enum/' + name, data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            this.setState({
                [name+'Values']: response.data.values
            })
        })
        .catch(error => { console.log(error) })
    }

    getExamineeInfo = () => {
        const data = {
            examineeID: this.state.examineeID
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/examinee/get', data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            this.setState({
                examineeInfo: {
                    birthDate: response.data.examinee.birthDate ? moment(response.data.examinee.birthDate, this.dateFormat) : null,
                    city: response.data.examinee.city || '',
                    disability: response.data.examinee.disability || '',
                    fatherEducation: response.data.examinee.fatherEducation || '',
                    firstName: response.data.examinee.firstName || '',
                    gender: response.data.examinee.gender || '',
                    grade: response.data.examinee.grade || '',
                    label: response.data.examinee.label || '',
                    lastName: response.data.examinee.lastName || '',
                    motherEducation: response.data.examinee.motherEducation || '',
                    race: response.data.examinee.race || '',
                    school: response.data.examinee.school || '',
                    state: response.data.examinee.state || '',
                    state_other: response.data.examinee.state_other || ''
                }
            })
        })
        .catch(error => { console.log(error) })
    }

    handleExamineeInfoChange = (event) => {
        const { name, value } = event.target

        this.setState((state) => ({
            examineeInfo: {
                ...state.examineeInfo,
                [name]: value
            }
        }))
    }

    handleDateChange = (date, dateString, name) => {
        this.setState((state) => ({
            examineeInfo: {
                ...state.examineeInfo,
                [name]: date
            }
        }))
    }

    handleSelectChange = (value, name) => {
        this.setState((state) => ({
            examineeInfo: {
                ...state.examineeInfo,
                [name]: value
            }
        }))
    }

    // sends the current state of examineeInfo to the API
    editExamineeInfo = () => {
        const data = {
            examineeID: this.state.examineeID,
            birthDate: this.state.examineeInfo.birthDate ? this.state.examineeInfo.birthDate.format('LL') : null,
            city: this.state.examineeInfo.city,
            disability: this.state.examineeInfo.disability,
            fatherEducation: this.state.examineeInfo.fatherEducation,
            firstName: this.state.examineeInfo.firstName,
            gender: this.state.examineeInfo.gender,
            grade: this.state.examineeInfo.grade,
            label: this.state.examineeInfo.label,
            lastName: this.state.examineeInfo.lastName,
            motherEducation: this.state.examineeInfo.motherEducation,
            race: this.state.examineeInfo.race,
            school: this.state.examineeInfo.school,
            state: this.state.examineeInfo.state,
            state_other: this.state.examineeInfo.state_other
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.patch(process.env.REACT_APP_API_URL + '/examinee/edit', data, config)
        .then(response => {
            this.endEditing()
        })
        .catch(error => { console.log(error) })
    }

    showDeleteConfirm = () => {
        let deleteExaminee = this.deleteExaminee
        Modal.confirm({
            cancelText: 'Cancel',
            content: 'This can not be undone!',
            okButtonProps: { type: 'danger' },
            okText: 'Delete',
            onCancel() { },
            onOk() {deleteExaminee()},
            title: 'Are you sure you want to delete this examinee?' 
        })
    }

    render() {
        return (
            <Dashboard>
                <div className={styles['card']}>
                    <div className={styles['header']}>
                        <label className={styles['name']}>
                            {this.state.examineeInfo.firstName || this.state.examineeInfo.lastName ?
                                `${this.state.examineeInfo.firstName} ${this.state.examineeInfo.lastName}` :
                                null
                            }
                        </label>
                        {
                            // cancel button
                            this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={this.endEditing}
                                    >
                                        <RollbackOutlined />
                                        <label className={styles['header-button-label']}>
                                            cancel
                                        </label>
                                    </div>
                                ) : null
                        }
                        {
                            // save button
                            this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={this.editExamineeInfo}
                                    >
                                        <SaveOutlined />
                                        <label className={styles['header-button-label']}>
                                            Save
                                        </label>
                                    </div>
                                ) : null
                        }
                        {
                            !this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={() => this.showDeleteConfirm()}
                                    >
                                        <DeleteOutlined />
                                        <label className={styles['header-button-label']}>
                                            Delete
                                </label>
                                    </div>
                                ) : null
                        }
                        {
                            !this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={() => this.setState({
                                            isEditing: true
                                        })}
                                    >
                                        <EditOutlined />
                                        <label className={styles['header-button-label']}>
                                            Edit
                                </label>
                                    </div>
                                ) : null
                        }
                    </div>
                    <hr className={styles['horizontal-line']}></hr>
                    <div className={styles['info-container']}>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>First Name</label>
                            <Input
                                className={styles['item-string']}
                                name='firstName'
                                onChange={this.handleExamineeInfoChange}
                                onPressEnter={(e) => e.target.blur()}
                                readOnly={!this.state.isEditing}
                                value={this.state.examineeInfo.firstName}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Last Name</label>
                            <Input
                                className={styles['item-string']}
                                name='lastName'
                                onChange={this.handleExamineeInfoChange}
                                onPressEnter={(e) => e.target.blur()}
                                readOnly={!this.state.isEditing}
                                value={this.state.examineeInfo.lastName}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Label</label>
                            <Input
                                className={styles['item-string']}
                                name='label'
                                onChange={this.handleExamineeInfoChange}
                                onPressEnter={(e) => e.target.blur()}
                                readOnly={!this.state.isEditing}
                                value={this.state.examineeInfo.label}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Birthdate</label>
                            <DatePicker
                                className={styles['date-picker']}
                                disabled={!this.state.isEditing}
                                format={this.dateFormat}
                                onChange={(date, dateString) => { this.handleDateChange(date, dateString, 'birthDate') }}
                                value={this.state.examineeInfo.birthDate}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['break']} />
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Gender</label>
                            <Radio.Group
                                disabled={!this.state.isEditing}
                                name='gender'
                                onChange={this.handleExamineeInfoChange}
                                value={this.state.examineeInfo.gender}
                            >
                                {
                                    this.state.genderValues.map((gender) => {
                                        return (
                                            <Radio
                                                key={`gender-${gender}`}
                                                value={gender}
                                            >
                                                {gender}
                                            </Radio>
                                        )
                                    })
                                }
                            </Radio.Group>
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Disability</label>
                            <Input
                                className={styles['item-string']}
                                name='disability'
                                onChange={this.handleExamineeInfoChange}
                                onPressEnter={(e) => e.target.blur()}
                                readOnly={!this.state.isEditing}
                                value={this.state.examineeInfo.disability}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>School</label>
                            <Input
                                className={styles['item-string']}
                                name='school'
                                onChange={this.handleExamineeInfoChange}
                                onPressEnter={(e) => e.target.blur()}
                                readOnly={!this.state.isEditing}
                                value={this.state.examineeInfo.school}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Grade</label>
                            <Select
                                className={styles['item-select']}
                                disabled={!this.state.isEditing}
                                onChange={(value) => this.handleSelectChange(value, 'grade')}
                                placeholder='Grade'
                                value={this.state.examineeInfo.grade}
                            >
                                {
                                    this.state.gradeValues.map((grade) => {
                                        return (
                                            <Select.Option
                                                key={grade}
                                            >
                                                {grade}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['break']} />
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>City</label>
                            <Input
                                className={styles['item-string']}
                                name='city'
                                onChange={this.handleExamineeInfoChange}
                                onPressEnter={(e) => e.target.blur()}
                                readOnly={!this.state.isEditing}
                                value={this.state.examineeInfo.city}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>State</label>
                            <Select
                                className={styles['item-select']}
                                disabled={!this.state.isEditing}
                                filterOption
                                onChange={(value) => this.handleSelectChange(value, 'state')}
                                placeholder='Select State'
                                showSearch
                                value={this.state.examineeInfo.state}
                            >
                                {
                                    this.state.stateValues.map((state) => {
                                        return (
                                            <Select.Option
                                                key={state}
                                            >
                                                {state}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Race</label>
                            <Select
                                className={styles['item-select']}
                                disabled={!this.state.isEditing}
                                onChange={(value) => this.handleSelectChange(value, 'race')}
                                placeholder='Race'
                                value={this.state.examineeInfo.race}
                            >
                                {
                                    this.state.raceValues.map((race) => {
                                        return (
                                            <Select.Option
                                                key={race}
                                            >
                                                {race}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['break']} />
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Father Education</label>
                            <Select
                                className={styles['item-select']}
                                disabled={!this.state.isEditing}
                                onChange={(value) => this.handleSelectChange(value, 'fatherEducation')}
                                placeholder='Education'
                                value={this.state.examineeInfo.fatherEducation}
                            >
                                {
                                    this.state.educationValues.map((education) => {
                                        return (
                                            <Select.Option
                                                key={education}
                                            >
                                                {education}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Mother Education</label>
                            <Select
                                className={styles['item-select']}
                                disabled={!this.state.isEditing}
                                onChange={(value) => this.handleSelectChange(value, 'motherEducation')}
                                placeholder='other'
                                value={this.state.examineeInfo.motherEducation}
                            >
                                {
                                    this.state.educationValues.map((education) => {
                                        return (
                                            <Select.Option
                                                key={education}
                                            >
                                                {education}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['break']} />
                    </div>
                </div>
            </Dashboard>
        )
    }

}

const mapStateToProps = (state) => ({
    token: state.token
})

export default connect(mapStateToProps, null)(ExamineeInfoPage)