/*
    custom slate editor middleware for item content
    override existing functionality here
*/

import Editor from '../item-content/item-content-editor'
import { Element, Node, Point, Range, Text, Transforms } from 'slate'
import { isNull } from '../expression'

// TODO: implement or disable insert, editor.insertData, editor.setFragmentData, etc

const forItemContent = editor =>
{
    const { deleteBackward, deleteForward, insertBreak, isBlock, isInline, isVoid, normalizeNode } = editor

    editor.deleteBackward = unit =>
    {
        const { selection } = editor
    
        if (selection && Range.isCollapsed(selection))
        {
          const [cell] = Editor.nodes(editor,
        {
            match: n =>
              !Editor.isEditor(n) &&
              Element.isElement(n) &&
              n.type === 'tableCell',
          })
    
          if (cell)
          {
            const [, cellPath] = cell
            const start = Editor.start(editor, cellPath)
    
            if (Point.equals(selection.anchor, start)) { return }
          }
        }
    
        // default
        deleteBackward(unit)
      }
    
    editor.deleteForward = unit =>
    {
        const { selection } = editor

        if (selection && Range.isCollapsed(selection))
        {
            const [cell] = Editor.nodes(editor,
            {
                match: n =>
                    !Editor.isEditor(n) &&
                    Element.isElement(n) &&
                    n.type === 'tableCell',
            })

            if (cell)
            {
                const [, cellPath] = cell
                const end = Editor.end(editor, cellPath)

                if (Point.equals(selection.anchor, end)) { return }
            }
        }

        // default
        deleteForward(unit)
    }

    editor.insertBreak = () =>
    {
        const { selection } = editor
    
        if (selection)
        {
          const [table] = Editor.nodes(editor,
            {
                match: n =>
                !Editor.isEditor(n) &&
                Element.isElement(n) &&
                n.type === 'table'
            })
    
          if (table) { return }
        }
    
        // default
        insertBreak()
      }

    editor.isBlock = element =>
    {
        switch (element.type)
        {
            case 'hide':    return true
            case 'audio':   return true
            case 'image':   return true
            case 'video':   return true
            default:        return isBlock(element)
        }
    }

    editor.isInline = element =>
    {
        switch (element.type)
        {
            case 'audio':           return true
            case 'fade':            return true
            case 'function':        return true
            case 'input':           return true
            case 'dateInput':       return true
            case 'image':           return true
            case 'outlineCycle':    return true
            case 'table':           return true
            case 'tableRow':        return true
            case 'tableCell':       return true
            case 'video':           return true
            default:                return isInline(element)
        }
    }

    editor.isVoid = element =>
    {
        switch (element.type)
        {
            case 'audio':       return true
            case 'input':       return editor.readOnly
            case 'dateInput':   return editor.readOnly
            case 'image':       return true
            case 'video':       return true
            default:            return isVoid(element)
        }
    }

    editor.normalizeNode = entry =>
    {
        const [node, path] = entry

        // reduce children to a single text node
        if (node.type === 'input' || node.type === 'function' || (node.type === 'dateInput' && !editor.readOnly))
        {
            // unwrap children until all are text nodes
            node.children.forEach((child, i) =>
            {
                if (!Text.isText(child))
                {
                    Editor.unWrap(editor, child.type, [...path, i])
                    return
                }
            })

            // merge children until single text node remains
            if (node.children.length > 1)
            {
                Transforms.mergeNodes(editor, {at: [...path, 1]})
                return
            }
        }

        // function
        if (node.type === 'function')
        {
            // unwrap function node without expressions
            if (!editor.readOnly && ((isNull(node.expression) && isNull(node.updateExpression) && isNull(node.initExpression)) || (Editor.string(editor, path).trim() === '')))
            {
                Editor.unWrap(editor, 'function')
                return
            }

            // TODO: apply marks to surrounding emtpy text nodes. probaly need to not merge, and just clear text from all but one. then let default norms handle merging so retain marks? or just create the surrounding text nodes and manually copy over marks


            //     // TODO: key dependencies aren't being used by the data context.
            //     // disable for now because not having a return after transform breaks the normalization pattern. gotta make it fit the norm pattern if you want this.
            //     // // set data key dependencies to leaf node
            //     // let keys =
            //     // [
            //     //     ...getDataKeyDependencies(node.expression),
            //     //     ...getDataKeyDependencies(node.initExpression),
            //     //     ...getDataKeyDependencies(node.updateExpression)
            //     // ]

            //     // Transforms.setNodes(editor, {dataKeyDependencies: keys}, { at: path})
            // }

            // update child node
            if (editor.readOnly && node.varKey)
            {
                // update varKey
                if (node.children[0].varKey !== node.varKey)
                {
                    Transforms.setNodes(editor, {varKey: node.varKey}, { at: [...path, 0] })
                    return
                }

                // update text
                let newText = `${editor.data[node.varKey] === undefined || Number.isNaN(editor.data[node.varKey]) || editor.data[node.varKey] === null  ? '' : editor.data[node.varKey]}`
                if (editor.data.hasOwnProperty(node.varKey) && newText !== node.children[0].text)
                {
                    Transforms.insertText(editor, newText, { at: [...path, 0] })
                    return
                }

                // // update dataKeyDependencies // not being used. disable for now.
                // if (!node.children[0].dataKeyDependencies || node.dataKeyDependencies.length !== node.children[0].dataKeyDependencies.length || node.dataKeyDependencies.every((value, i) => value === node.children[0].dataKeyDependencies[i]))
                // {
                //     Transforms.setNodes(editor, {dataKeyDependencies: node.dataKeyDependencies}, { at: [...path, 0] })
                //     return
                // }
            }
        }

        // TODO: refactor 'key' to 'varKey'. will require updating all content with text inputs.
        // input: a text field that sets a data key onChange while the editor is readOnly
        if (!editor.readOnly && node.type === 'input')
        {
            // update key to child's string value
            const newKey = Editor.string(editor, path).trim()
            Editor.setNodeProperties(editor, 'input', {key: newKey})

            // set key as property on child text node, so it can be used when rendering the leaf (while readOnly)
            Transforms.setNodes(editor, {key: newKey}, { at: [...path, 0] })
            return
        }

        // date input
        if (!editor.readOnly && node.type === 'dateInput')
        {
            // update varKey to child's string value
            const newVarKey = Editor.string(editor, path).trim()
            Editor.setNodeProperties(editor, 'dateInput', {varKey: newVarKey})
            return
        }

        // tableRow: hide empty text nodes added by slate
        if (node.type === 'table' || node.type === 'tableRow')
        {
            // hide the empty spacing text nodes between/around table elements
            node.children.forEach((child, i) =>
            {
                if (Text.isText(child) && child.display !== 'none')
                {
                    Transforms.setNodes(editor, {display: 'none', lineHeight: '0'}, { at: [...path, i]})
                    return
                }
            })
        }

        // table: set row/col counts, border
        if (node.type === 'table')
        {
            const rows = node.children.filter(child => child.type === 'tableRow')
            let maxColCount = 1
            
            // count columns
            for (const row of rows)
            {
                const cells = row.children.filter(child => child.type === 'tableCell')

                if (cells.length > maxColCount)
                {
                    maxColCount = cells.length
                }
            }
            
            // set row count
            const [matchRows] = Editor.nodes(editor,
            {
                at: path,
                match: (node, nodePath) => node.type === 'tableRow' && nodePath.length === path.length+1 && node.rowCount !== rows.length
            })

            if (matchRows)
            {
                Transforms.setNodes(editor,
                {
                    rowCount: rows.length
                },
                {
                    at: path,
                    match: (node, nodePath) => node.type === 'tableRow' && nodePath.length === path.length+1
                })
                return
            }

            // set col count
            const [matchCells] = Editor.nodes(editor,
            {
                at: path,
                match: (node, nodePath) => node.type === 'tableCell' && nodePath.length === path.length+2 && node.colCount !== maxColCount
            })

            if (matchCells)
            {
                Transforms.setNodes(editor,
                {
                    colCount: maxColCount
                },
                {
                    at: path,
                    match: (node, nodePath) => node.type === 'tableCell' && nodePath.length === path.length+2 && node.colCount !== maxColCount
                })
                return
            }

            // set border to cells
            node.children.forEach((row, rowIndex) =>
            {
                if (row.type === 'tableRow')
                {
                    row.children.forEach((cell, cellIndex) =>
                    {
                        if (cell.type === 'tableCell' && cell.border !== node.border)
                        {
                            Transforms.setNodes(editor, {border: node.border}, {at: [...path, rowIndex, cellIndex]})
                            return
                        }
                    })
                }
            })

            // TODO: delete table if no rows or no columns
        }

        // remove keys from text nodes that are no longer patched as a function or input
        if (!editor.readOnly && node.varKey && Text.isText(node))
        {
            let parent = Node.parent(editor, path)
            if (parent.type !== 'function' && parent.type !== 'input' && parent.type !== 'dateInput')
            {
                Transforms.unsetNodes(editor, 'varKey', {at: path})
                return
            }
        }

        // default font size
        if (Text.isText(node) && !node.fontSize)
        {
            Transforms.setNodes(editor, {fontSize: 20}, {at: path})
            return
        }

        // default
        normalizeNode(entry)
    }

    return editor
}

export default forItemContent