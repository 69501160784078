/*
    Examiner test page
*/

import React, { useEffect, useRef, useState } from 'react'
import Dashboard from '../dashboard'
import { PollingContext, useCurrentItem, useData, useDataActions, useIsAdministeringTest } from '../item-content/polling-context'
import styles from '../../style/examiner/test-page.module.css'
import ItemContent from '../item-content/item-content'
import ItemContentContext from '../item-content/item-content-context'
import Timer from '../item-content/timer'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import SizeWrapper from '../size-wrapper'
import { useHistory, useParams } from 'react-router-dom'


const TestPageContent = () =>
{
    const history                                       = useHistory()

    const currentItem                                   = useCurrentItem()
    const data                                          = useData()
    const { setSessionID, updateData }                  = useDataActions()
    const isAdministeringTest                           = useIsAdministeringTest()
    
    const { sessionID }                                 = useParams()

    const [responseButtonSize, setResponseButtonSize]   = useState('25%')

    const noteRef                                       = useRef()
    const stopTimer                                     = useRef(() => {})


    // init
    useEffect(() =>
    {
        setSessionID(sessionID)
    }, [sessionID, setSessionID])

    // new current item
    useEffect(() =>
    {
        // determine response button size
        if (currentItem != null && currentItem.allowedResponses != null)
        {
            setResponseButtonSize(`${100 / Math.ceil(Math.sqrt(currentItem.allowedResponses.length))}%`)
        }

        // reset scroll positions to top.
        noteRef.current.scrollTop = 0

    }, [currentItem])

    // redirect to session list page if not administering test
    useEffect(() =>
    {
        if (isAdministeringTest === false) history.replace('/examiner/sessions')
    }, [history, isAdministeringTest])


    return <Dashboard>
        <div className={styles['card']}>
            <div className={styles['card__header']}>
                {data.examinee_firstName || data.examinee_lastName ? (
                    <label className={styles['name']}>
                        {data.examinee_firstName} {data.examinee_lastName}
                    </label>
                ) : null}
                <label className={styles['session-id-label']}>
                    Session ID
                </label>
                <div className={styles['session-id-border']}>
                    <label className={styles['session-id']}>
                        {sessionID}
                    </label>
                </div>
                <label className={styles['item-label-label']}>
                    Item
                </label>
                <div className={styles['item-label-border']}>
                    <label className={styles['item-label']}>
                        {currentItem ? currentItem.label : ''}
                    </label>
                </div>
                <button
                    className={styles['end-test'] + ' ' + styles['next-test']}
                    onClick={() =>
                    {
                        stopTimer.current()
                        updateData({'sessionControl_nextTest': true})
                    }}
                >
                    Next Test
                </button>
                <button
                    className={styles['end-test']}
                    onClick={() =>
                    {
                        stopTimer.current()
                        updateData({'sessionControl_endTest': true})
                    }}
                >
                    End Test
                </button>
            </div>
            <hr className={styles['card__line']}></hr>
            <div className={styles['card__body']}>
                <ItemContentContext
                    examiner
                    interactionEnabled
                    readOnly
                >
                    <ItemContent />
                </ItemContentContext>
            </div>
            <hr className={styles['card__line']}></hr>
            <div className={styles['card__controls-container']}>
                <div className={`${styles['navigation-container']} ${styles['left']}`}>
                    <button
                        className={styles['navigation-button']}
                        onClick={() =>
                        {
                            stopTimer.current()
                            updateData({'sessionControl_prevItem': true})
                        }}
                        disabled={data.session_isFirstItem}
                    >
                        <ArrowLeftOutlined />
                        Prev
                        </button>
                </div>
                {
                    // note
                    <div className={styles['note-container']}>
                        <label className={styles['input-label']}>
                            Note
                        </label>
                        <textarea
                            className={styles['note']}
                            onChange={e => updateData({'response_note': e.target.value})}
                            ref={noteRef}
                            value={data.response_note || ''}
                        />
                    </div>
                }
                {
                    // timer
                    currentItem && currentItem.timed &&
                    <Timer
                        stop={stopTimer}
                        time={data.response_duration}
                        onTimeChange={(sec) => updateData({'response_duration': sec})}
                    />
                }
                {
                    // button input
                    currentItem && currentItem.scored === true && currentItem.allowedResponses != null &&
                    <div className={styles['score-container--buttons']}>
                        {
                            currentItem.allowedResponses.map((allowedResponse, i) =>
                            {
                                var isSelected = false

                                if (allowedResponse.response === data.response_response && allowedResponse.score === data.response_score) isSelected = true // response and score match
                                if (allowedResponse.response === '' && allowedResponse.score === data.response_score) isSelected = true // no allowed response, score matches
                                if (allowedResponse.response === data.response_response && allowedResponse.score === null) isSelected = true // response matches, no allowed score

                                return (
                                    <div
                                        key={'buttonContainer' + i}
                                        style={{
                                            height: responseButtonSize,
                                            width: responseButtonSize
                                        }}
                                    >
                                        <SizeWrapper
                                            key={'sizeWrapper' + i}
                                        >
                                            <button
                                                className={`${styles['score-button']} ${isSelected ? styles['selected'] : ''}`}
                                                key={i}
                                                onClick={() =>
                                                {
                                                    updateData({
                                                        'response_response': isSelected ? null : allowedResponse.response,
                                                        'response_score': isSelected ? null : allowedResponse.score
                                                    })
                                                }}
                                            >
                                                {allowedResponse.response || allowedResponse.score}
                                            </button>
                                        </SizeWrapper>
                                    </div>
                                )
                            })}
                    </div>
                }
                {
                    // score input
                    currentItem && currentItem.scored === true && currentItem.allowedResponses == null &&
                        <div className={styles['score-container--text']}>
                            <label className={styles['input-label']}>
                                Score
                            </label>
                            <input
                                className={styles['score-input']}
                                type='number'
                                value={data['response_score'] === 0 ? 0 : data['response_score'] || ''}
                                onChange={(e) =>
                                {
                                    updateData({'response_score': parseFloat(e.target.value)})
                                }}
                            />
                        </div>
                }
                {
                    // response input
                    currentItem && currentItem.scored === true && currentItem.allowedResponses == null &&
                        <div className={styles['response-container']}>
                            <label className={styles['input-label']}>
                                Response
                            </label>
                        <textarea
                            className={styles['response']}
                            onChange={e => updateData({'response_response': e.target.value})}
                            value={data.response_response || ''}
                        />
                    </div>
                }
                <div
                    className={styles['examinee-preview-container']}
                    onMouseDown={(event) => {
                        event.preventDefault()
                        window.open(`${window.location.origin}/examinee/test/${sessionID}`, '_blank')
                    }}
                >
                    <ItemContentContext
                        examinee
                        interactionEnabled={false}
                        readOnly
                    >
                        <ItemContent passive />
                    </ItemContentContext>
                </div>
                <div className={`${styles['navigation-container']} ${styles['right']}`}>
                    <button
                        className={styles['navigation-button']}
                        onClick={() =>
                        {
                            stopTimer.current()
                            updateData({'sessionControl_nextItem': true})
                        }}
                        disabled={data.session_isLastItem}
                    >
                        <ArrowRightOutlined />
                        Next
                        </button>
                </div>
            </div>
        </div>
    </Dashboard>
}

// a wraper for applying data context
const TestPage = () =>
{
    return (
        <PollingContext>
            <TestPageContent />
        </PollingContext>
    )
}

export default TestPage