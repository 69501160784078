/*
    Item content editor page
*/

import React, { Component } from 'react'
import Dashboard from '../dashboard'
import styles from '../../style/info-card.module.css'
import axios from 'axios'
import { EditOutlined, ReloadOutlined } from '@ant-design/icons'
import ItemContent from '../item-content/item-content'
import ItemContentContext from '../item-content/item-content-context'
import ItemContentToolbar from '../item-content/toolbar/item-content-toolbar'
import { PollingContext, useData, useDataActions } from '../item-content/polling-context'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

class ContentEditorPageContent extends Component {

    constructor(props) {
        super(props)

        this.itemContentContext = React.createRef()

        this.state = {
            contentID: '',
            document: null,
            fields: {},
            isEditing: false,
            url: ''
        }
    }

    async componentDidMount() {
        await this.getItem()
        this.getDocument()
    }

    endEditing = () => {
        this.setState({
            isEditing: false
        })

        this.getDocument()
    }

    getDocument = () => {
        if (!this.state.contentID) return

        const data = {
            contentID: this.state.contentID
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }
        return axios.post(process.env.REACT_APP_API_URL + '/content/getDocument', data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            // setting document to null triggers the useEffect hook in itemContentContext when document comes back as undefined.
            // TODO: figure out how to get rid of this bandaid
            this.setState({
                document: null
            })

            this.setState({
                document: response.data.document
            })
        })
        .catch(error => { console.log(error) })
    }

    getItem = () => {
        const data = {
            itemID: this.props.itemID
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/item/get', data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')
            this.setState({
                contentID: response.data.item.content[this.props.contentType]
            })
        })
        .catch(error => { console.log(error) })
    }

    saveDocument = () => {
        const data = {
            contentID: this.state.contentID,
            document: this.itemContentContext.current.getValue(),
            s3FileNames: this.itemContentContext.current.getFileNames()
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/content/setDocument', data, config)
        .then(response => {
            this.endEditing()
        })
        .catch(error => { console.log(error) })
    }

    render() {
        return (
            <Dashboard>
                    <ItemContentContext
                        readOnly={!this.state.isEditing}
                        ref={this.itemContentContext}
                        value={this.state.document}
                    >
                        <div className={styles['card']}>
                            <div className={styles['header']}>
                                <ItemContentToolbar
                                    cancelEditing={this.endEditing}
                                    contentID={this.state.contentID}
                                    saveChanges={this.saveDocument}
                                />
                                {
                                    // reload button
                                    !this.state.isEditing ?
                                        (
                                            <div
                                                className={styles['button-container']}
                                                onClick={() => {
                                                    this.getItem()
                                                    this.getDocument()
                                                }}
                                            >
                                                <ReloadOutlined />
                                                <label className={styles['header-button-label']}>
                                                    Reload
                                                </label>
                                            </div>
                                        ) : null
                                }
                                {
                                    // edit button
                                    !this.state.isEditing ?
                                        (
                                            <div
                                                className={styles['button-container']}
                                                onClick={() => this.setState({
                                                    isEditing: true
                                                }, () => {
                                                    this.getItem()
                                                    this.getDocument()
                                                })}
                                            >
                                                <EditOutlined />
                                                <label className={styles['header-button-label']}>
                                                    Edit
                                                </label>
                                            </div>
                                        ) : null
                                }
                            </div>
                            <div className={styles['item-content-container']}>
                                <ItemContent />
                            </div>
                        </div>
                    </ItemContentContext>
            </Dashboard>
        )
    }

}


const ContentEditorPageDataWrapper = (props) =>
{
    const { contentType } = props
    const data = useData()
    const { updateData } = useDataActions()
    const { itemID } = useParams()
    const token = useSelector(state => state.token)

    return (
        <ContentEditorPageContent
                contentType={contentType}
                data={data}
                updateData={updateData}
                itemID={itemID}
                token={token}
            />
    )
}


const ContentEditorPage = (props) =>
{
    const { contentType } = props

    return (
        <PollingContext offline >
            <ContentEditorPageDataWrapper contentType={contentType} />
        </PollingContext>
    )
}

export default ContentEditorPage