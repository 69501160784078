import React, { useEffect, useRef } from 'react'
import styles from '../style/input.module.css'

/*
    Input: A controlled input with the option to prevent taking focus and is dynamically sized
*/

// TODO: invalid input visual feedback bool param.
// TODO: either finish autosize/fitMaxWidthToValue, or remove it.

const Input = ({
    className = '',
    disabled = false,
    fitMaxWidthToValue = false,
    name = null,
    onBlur = () => {},
    onChange = event => {},
    onFocus = value => {},
    onPaste = event => {},
    onPressEnter = event => {},
    placeholder = null,
    style = {},
    title = null,
    value
}) =>
{
    const ref = useRef()

    useEffect(() =>
    {
        if (!fitMaxWidthToValue) return
        ref.current.style.maxWidth = `${Math.max(ref.current.value.length, 3)}vmin`
    }, [fitMaxWidthToValue, value])

    return  <input
                className={styles['input'] + ' ' + className}
                disabled={disabled}
                name={name}
                onBlur={onBlur}
                onChange={event => onChange(event)}
                onFocus={onFocus}
                onKeyDown={event => { if (event.key === 'Enter') onPressEnter(event) }}
                onPaste={onPaste}
                placeholder={placeholder}
                ref={ref}
                style={style}
                title={title}
                type='text'
                value={value || ''}
            />
}

export default Input