/*
    A list of tables for report XOR item; considered in that order.
*/

import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../dashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import BigList from '../big-list'
import { useHistory, useParams } from 'react-router-dom'

const TableListPage = () =>
{
    const history = useHistory()
    const token = useSelector(state => state.token)
    const { batteryID, testID, itemID, reportID } = useParams()

    const [listItems, setListItems] = useState([])
    const [tables, setTables] = useState([])


    const editItem = useCallback(async (itemID, changes = {}) =>
    {
        const data =
        {
            itemID,
            ...changes
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/item/edit', data, config)
        .catch(error => { console.log(error) })
    }, [token])

    const getItem = useCallback(async (itemID) =>
    {
        const data =
        {
            itemID
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/item/get', data, config)
        .catch(error => { console.log(error) })

        return response.data.item
    }, [token])

    const editReport = useCallback(async (reportID, changes = {}) =>
    {
        const data =
        {
            reportID,
            ...changes
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/report/edit', data, config)
        .catch(error => { console.log(error) })
    }, [token])

    const getReport = useCallback(async (reportID) =>
    {
        const data =
        {
            reportID
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/report/get', data, config)
        .catch(error => { console.log(error) })

        return response.data.report
    }, [token])

    const createTable = useCallback(async () =>
    {
        // create a new table
        const data =
        {
            batteryID
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/table/create', data, config)
        .catch(error => { console.log(error) })

        const newTableID = response.data.tableID

        if (reportID)
        {
            // get report
            const report = await getReport(reportID)

            // make new table list
            report.tables.push(newTableID)

            // edit report
            await editReport(reportID, { tables: report.tables })

            history.push(`/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/tables/${newTableID}/info`)
        }
        else if (itemID)
        {
            // get item
            const item = await getItem(itemID)

            // make new table list
            item.tables.push(newTableID)

            // edit item
            await editItem(itemID, { tables: item.tables })

            history.push(`/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/tables/${newTableID}/info`)
        }

    }, [batteryID, editItem, editReport, getItem, getReport, history, itemID, testID, reportID, token])

    const getTables = useCallback(async () =>
    {
        let data = {}

        if (reportID) data = {reportID}
        else if (itemID) data = {itemID}

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/table/dir', data, config)
        .catch(error => { console.log(error) })

        setTables(response.data)
    }, [itemID, reportID, token])

    const makeListItems = useCallback(() =>
    {
        let items = tables.map(table =>
        {
            let destination = ``
            if (reportID) destination = `/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/tables/${table._id}/info`
            else if (itemID) destination = `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/tables/${table._id}/info`

            return {
                destination: destination,
                filterValue: `${table.name || ''}`.toLowerCase(),
                title: `${table.name || ''}`,
                uniqueKey: table._id
            }
        })

        setListItems(items)
    }, [batteryID, itemID, reportID, tables, testID])


    useEffect(() =>
    {
        getTables()
    }, [getTables])

    useEffect(() =>
    {
        if (!tables) return

        makeListItems()
    }, [makeListItems, tables])


    return (
        <Dashboard>
            <BigList
                items={listItems}
                onClickCreateNew={createTable}
                title='Tables'
            />
        </Dashboard>
    )
}


export default TableListPage