import React, { Component } from 'react'
import Avatar from '../assets/img/avatar.svg'
import { connect } from 'react-redux'
import { logout } from '../redux/action-creators'
import styles from '../style/dashboard-nav.module.css'
import { Link } from 'react-router-dom'
import history from '../history'
import { Dropdown, Menu, Modal } from 'antd'
import { BuildFilled, CaretDownFilled, ContactsFilled, ReconciliationFilled } from '@ant-design/icons'
import packageJson from '../../package.json'


class DashboardNav extends Component {

    constructor(props) {
        super(props)

        this.state = {
            didLogOut: false
        }

        this.onClickMenu = this.onClickMenu.bind(this)
    }

    componentDidUpdate() {
        if (this.state.didLogOut) {
            history.push('/')
        }
    }

    onClickMenu({ item, key, keyPath, domEvent }) {
        switch (key) {
            case 'about':
                Modal.info({
                    content: (
                        <div>
                            <p>{'v' + packageJson.version}</p>
                        </div>
                    ),
                    maskClosable: true,
                    title: 'WICI'
                })
                break

            case 'credits':
                history.push('/user/credits')
                break

            case 'logout':
                this.props.logout()
                this.setState({
                    didLogOut: true
                })
                break

            case 'settings':
                history.push('/user/settings')
                break

            default:
                break
        }
    }

    navButton = (destination, icon, label, activeLocations, disabled = false) => {
        let shouldShowUnderline = false
        for (let activeLocation of activeLocations) {
            if (history.location.pathname.match(activeLocation)) {
                shouldShowUnderline = true
                break
            }
        }

        return (
            <div className={styles['nav-button__container']}>
                <Link
                    className={styles['nav-button__link']}
                    to={destination}
                >
                    {icon}
                    <label className={styles['nav-button__label']} >
                        {label}
                </label>
                </Link>
                <div className={`${styles['nav-button-underline']} ${shouldShowUnderline ? styles['nav-button-underline-visible'] : null}`} />
            </div>
        )
    }

    userMenu = (
        <Menu
            onClick={({ item, key, keyPath, domEvent }) => this.onClickMenu({ item, key, keyPath, domEvent })}
        >
            {/* <Menu.Item key='profile' disabled>
                Profile
            </Menu.Item> */}
            <Menu.Item key='settings'>
                Settings
            </Menu.Item>
            <Menu.Item key='credits'>
                Credits
            </Menu.Item>
            {/* <Menu.Item key='reportBug' disabled>
                Report a bug
            </Menu.Item>
            <Menu.Item key='requestFeature' disabled>
                Request a feature
            </Menu.Item> */}
            <Menu.Item key='about'>
                About
            </Menu.Item>
            <Menu.Item key='logout'>
                Logout
            </Menu.Item>
        </Menu>
    )

    render() {
        return (
            <div className={styles['nav-container']}>
                {
                    // developer link: batteries
                    this.props.userInfo.kind === 'Developer' ? this.navButton('/developer/batteries', <BuildFilled className={styles['nav-button__icon']} style={{ color: 'white' }} />, 'Batteries', [/\/developer\/batteries/]) : null
                }
                {
                    // examiner link: batteries
                    this.props.userInfo.kind === 'Examiner' ? this.navButton('/examiner/batteries', <BuildFilled className={styles['nav-button__icon']} style={{ color: 'white' }} />, 'Batteries', [/\/examiner\/batteries/], true) : null
                }
                {
                    // examiner link: examinees
                    this.props.userInfo.kind === 'Examiner' ? this.navButton('/examiner/examinees', <ContactsFilled className={styles['nav-button__icon']} style={{ color: 'white' }} />, 'Examinees', [/\/examiner\/examinees/]) : null
                }
                {
                    // examiner link: sessions
                    this.props.userInfo.kind === 'Examiner' ? this.navButton('/examiner/sessions', <ReconciliationFilled className={styles['nav-button__icon']} style={{ color: 'white' }} />, 'Sessions', [/^\/examiner\/sessions$/, /^\/examiner\/test$/]) : null
                }
                <div className={styles['user-menu__container']}>
                    <Dropdown
                        overlay={this.userMenu}
                        placement='bottomRight'
                        trigger={['click']}
                    >
                        <div>
                            <img
                                alt="User Avatar"
                                className={styles['user-avatar']}
                                src={Avatar}
                            />
                            <label className={styles['dropdown__label']}>
                                {
                                    this.props.userInfo.firstName || this.props.userInfo.lastName ?
                                        `${this.props.userInfo.firstName} ${this.props.userInfo.lastName}` :
                                        'User'
                                }
                            </label>
                            <CaretDownFilled className={styles['caret-down-icon']} />
                        </div>
                    </Dropdown>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNav)
