/*
    a commonly used interface framework
*/

import React, { Component } from 'react'
import styles from '../style/dashboard.module.css'
import DashboardNav from './dashboard-nav'
import SideMenu from './side-menu'
import Breadcrumb from './breadcrumb'

class Dashboard extends Component {

    render() {
        return (
            <div className={styles['container']}>
                <DashboardNav />
                <div className={styles['subcontainer-a']}>
                    <SideMenu />
                    <div className={styles['subcontainer-b']}>
                        <Breadcrumb />
                        <div className={styles['subcontainer-c']}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard