/*
    A spreadsheet component for displaying and editing data tables.
*/

import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import styles from '../style/spreadsheet.module.css'
import NumberInput from './number-input'
import Input from './input'
import { Button, Dropdown, Menu, Radio } from 'antd'
import { MenuOutlined } from '@ant-design/icons'


const Spreadsheet =
({
    addCol = (name, type) => {},
    addRow = (name) => {},
    data = {}, // { col: { row: string|bool|number }}
    dataTypes = {}, // { col: 'string'|'bool'|'number'}
    deleteCol = () => {},
    deleteRow = () => {},
    editable = false,
    editColName = (currentName, newName) => {},
    editDataType = (colName, type) => {},
    editRowName = (currentName, newName) => {},
    editValue = (colName, rowName, value) => {},
    setData = (data) => {},
    setDataTypes = (dataTypes) => {}
}) =>
{
    const colCount = useRef(10)
    const rowCount = useRef(10)
    const rowNames = useRef([])
    const scrollHeight = useRef(100) // % of flex container inside container
    const scrollWidth = useRef(100) // % of flex container inside container

    const [colNameEdit, setColNameEdit] = useState('')
    const [rowNameEdit, setRowNameEdit] = useState('')
    const [selectedColumn, setSelectedColumn] = useState(null) // colName
    const [selectedRow, setSelectedRow] = useState(null) // rowName


    const renderMenu = useCallback(() =>
    {
        if (!editable) return null

        const menu = (
            <Menu
                onClick={({ item, key, keyPath, domEvent }) =>
                {
                    onClickMenu({ item, key, keyPath, domEvent })
                }}
            >
                <Menu.Item key='importCSV'>
                    <span>import csv</span>
                </Menu.Item>
                <Menu.ItemGroup title={<label className={styles['item-group-title']}>column</label>}>
                    <Menu.Item key='addCol'>
                        <span>add</span>
                    </Menu.Item>
                    <Menu.Item key='deleteCol' disabled={!selectedColumn}>
                        <span>delete</span>
                    </Menu.Item>
                    <Menu.SubMenu key='sub_col-name' title='name' disabled={!selectedColumn}>
                        <Input
                            disabled={!selectedColumn}
                            onBlur={() => setColNameEdit('')}
                            onChange={event => setColNameEdit(event.target.value)}
                            onFocus={() => setColNameEdit(selectedColumn)}
                            onPressEnter={event =>
                            {
                                editColName(selectedColumn, event.target.value)
                                setColNameEdit('')
                                setSelectedColumn(event.target.value)
                            }}
                            placeholder={selectedColumn}
                            value={colNameEdit}
                        />
                    </Menu.SubMenu>
                    <Menu.SubMenu key='sub_col-type' title='type' disabled={!selectedColumn}>
                        <Radio.Group
                            disabled={!selectedColumn}
                            name='type'
                            onChange={event =>
                            {
                                if (!selectedColumn) return
                                editDataType(selectedColumn, event.target.value)
                            }}
                            value={dataTypes[selectedColumn]}
                        >
                            <Radio key={`type-bool`} value={'bool'}>bool</Radio>
                            <Radio key={`type-number`} value={'number'}>number</Radio>
                            <Radio key={`type-string`} value={'string'}>string</Radio>
                        </Radio.Group>
                    </Menu.SubMenu>
                </Menu.ItemGroup>
                <Menu.ItemGroup title={<label className={styles['item-group-title']}>row</label>}>
                    <Menu.Item key='addRow'>
                        <span>add</span>
                    </Menu.Item>
                    <Menu.Item key='deleteRow' disabled={!selectedRow}>
                        <span>delete</span>
                    </Menu.Item>
                    <Menu.SubMenu key='sub_row-name' title='name' disabled={!selectedRow}>
                        <Input
                            disabled={!selectedRow}
                            onBlur={() => setRowNameEdit('')}
                            onChange={event => setRowNameEdit(event.target.value)}
                            onFocus={() => setRowNameEdit(selectedRow)}
                            onPressEnter={event =>
                            {
                                editRowName(selectedRow, event.target.value)
                                setRowNameEdit('')
                                setSelectedRow(event.target.value)
                            }}
                            placeholder={selectedRow}
                            value={rowNameEdit}
                        />
                    </Menu.SubMenu>
                </Menu.ItemGroup>
            </Menu>
        )

        const onClickMenu = ({ item, key, keyPath, domEvent }) =>
        {
            domEvent.preventDefault()

            switch (key)
            {
                case 'addCol':
                    addCol('_newCol', 'string')
                    break

                case 'addRow':
                    addRow('_newRow')
                    break

                case 'deleteCol':
                    if (selectedColumn)
                    {
                        deleteCol(selectedColumn)
                        setSelectedColumn(null)
                    }
                    break

                case 'deleteRow':
                    if (selectedRow)
                    {
                        deleteRow(selectedRow)
                        setSelectedRow(null)
                    }
                    break

                case 'importCSV':
                    document.getElementById('importCSV').click()
                    break
                default:
                    break
            }
        }

        return  (
                    <>
                        <Dropdown
                            overlay={menu}
                            placement='bottomLeft'
                            trigger={['click']}
                        >
                            <Button
                                icon={<MenuOutlined />}
                                style=
                                {{
                                    cursor: 'default',
                                    flex: '0 1 100%',
                                    fontSize: '1vmin',
                                    height: 'unset',
                                    width: 'unset'
                                }}
                                title='menu'
                            /> 
                        </Dropdown>
                        {/* import button: hidden element programatically triggered to ask user for a file */}
                        <input
                            accept='.csv'
                            id='importCSV'
                            onChange={async event =>
                            {
                                if (!event.target.files.length) return

                                // init results by copying current data
                                const newData = {}
                                if (Object.keys(data).length > 0)
                                    for (const [colName, col] of Object.entries(data))
                                        newData[colName] = {...col}

                                const newDataTypes = {...dataTypes}
                                
                                // append csv contents to data
                                const content = await event.target.files[0].text()
                                const rows = content.split(/\r?\n/)

                                // process header row. 
                                const colNames = rows.shift().split(',').filter(name => !!name)

                                // discard first col name, it's for the row name col.
                                colNames.shift()

                                // init cols as needed
                                for (const colName of colNames)
                                {
                                    if (!data[colName])
                                    {
                                        newData[colName] = {}
                                        newDataTypes[colName] = 'string'
                                    }
                                }

                                // process rows. first column in each row is the row name
                                for (const row of rows)
                                {
                                    const values = row.split(',')

                                    // row name
                                    const rowName = values.shift()

                                    // values
                                    values.forEach((value, i) =>
                                    {
                                        if (!!value)
                                        {
                                            newData[colNames[i]][rowName] = value
                                        }
                                    })
                                }

                                // save results
                                setData(newData)
                                setDataTypes(newDataTypes)
                            }}
                            style={{display: 'none'}}
                            type='file'
                        />
                    </>
                )
    }, [addCol, addRow, colNameEdit, data, dataTypes, deleteCol, deleteRow, editable, editColName, editDataType, editRowName, rowNameEdit, selectedColumn, selectedRow, setData, setDataTypes])

    const renderCell = useCallback((colName, rowName, value, columnLabel = false) =>
    {
        // render column label cell
        if (columnLabel)
        {
            return (
                <div
                    className={`${styles['cell']} ${styles['column-label']}`}
                    key={`${colName}-${rowName}`}
                    onClick={() =>
                    {
                        setSelectedColumn(current => current === colName ? null : colName)
                    }}
                >
                    <Input
                        className={`${styles['cell']} ${styles['label']}`}
                        disabled
                        value={colName}
                    />
                </div>
            )
        }

        // render regular cell
        const dataType = dataTypes[colName]
        let body = <></>

        switch (dataType)
        {
            case 'bool': 
            body = <input
                        disabled={!editable}
                        onChange={event => editValue(colName, rowName, event.target.checked)}
                        type='checkbox'
                        checked={!!value}
                    />
                break

            case 'number': body = <NumberInput
                                    className={styles['cell']}
                                    disabled={!editable}
                                    onChange={value => editValue(colName, rowName, value)}
                                    title={colName}
                                    value={value}
                                />
                break

            case 'string': body = <Input
                                    className={styles['cell']}
                                    disabled={!editable}
                                    onChange={event => editValue(colName, rowName, event.target.value)}
                                    value={value}
                                />
                break

            default: body = <>{null}</>
                break
        }

        return (
            <div
                bool={dataType === 'bool' ? 'true' : null}
                className={`${styles['cell']} ${colName === selectedColumn || rowName === selectedRow ? styles['selected'] : ''}`}
                key={`${colName}-${rowName}`}
            >
                {body}
            </div>
        )
    }, [dataTypes, editable, editValue, selectedColumn, selectedRow])

    const renderCol = useCallback((colName, col, rowLabel = false) =>
    {
        // render row label column
        if (rowLabel)
        {
            return (
                <div
                    className={`${styles['column']}
                    ${styles['row-label']}`}
                    key={`${colName}`}
                >
                    <div className={`${styles['cell']} ${styles['corner']}`}>
                        {renderMenu()}
                    </div>
                    {rowNames.current.map(rowName =>
                    {
                        return (
                            <div
                                className={styles['cell']}
                                key={`${colName}-${rowName}`}
                                onClick={() =>
                                {
                                    setSelectedRow(current => current === rowName ? null : rowName)
                                }}
                            >
                                <Input
                                    className={`${styles['cell']} ${styles['label']}`}
                                    disabled
                                    style=
                                    {{
                                        opacity: 1,
                                        fontSize: '1.5vmin',
                                        fontWeight: 'bold',
                                        textAlign: 'center'
                                    }}
                                    value={rowName}
                                />
                            </div>
                        )
                    })}
                </div>
            )
        }

        // render regular column
        return (
            <div className={styles['column']} key={`${colName}`}>
                {renderCell(colName, undefined, undefined, true)}
                {rowNames.current.map(rowName => renderCell(colName, rowName, col[rowName]))}
            </div>
        )
    }, [renderCell, renderMenu])


    useLayoutEffect(() =>
    {
        // clear old row names
        rowNames.current = []

        // extract new row names
        for (const col of Object.values(data))
            for (const rowName of Object.keys(col))
                if (!rowNames.current.includes(rowName)) rowNames.current.push(rowName)

        // update col/row counts
        colCount.current = Object.keys(data).length
        rowCount.current = rowNames.current.length

        // update scroll dimensions
        scrollHeight.current = (rowCount.current+1)*5
        scrollWidth.current = (colCount.current+1)*10
    }, [data])

    
    return (
            <div className={styles['scroll-container']}>
                <div className={styles['flex-container']} style={{ height: `${scrollHeight.current}%`, width: `${scrollWidth.current}%` }}>
                    {renderCol(undefined, undefined, true)}
                    {Object.entries(data).map(([colName, col]) => { return renderCol(colName, col) })}
                </div>
            </div>
    )
}


export default Spreadsheet