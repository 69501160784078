/*
    Examiner's battery info page
*/

import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../dashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from '../../style/info-card.module.css'
import { Input } from 'antd'


const BatteryInfoPage = () =>
{
    const { batteryID } = useParams()

    const token = useSelector(state => state.token)

    const [batteryInfo, setBatteryInfo] = useState({})
    

    const getBatteryInfo = useCallback(async () =>
    {
        const data =
        {
            batteryID: batteryID
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/battery/get', data, config)
        .catch(error => { console.log(error) })

        setBatteryInfo(
        {
            name: response.data.battery.name
        })
    }, [batteryID, token])


    useEffect(() =>
    {
        getBatteryInfo()
    }, [getBatteryInfo])


    return (
        <Dashboard>
                <div className={styles['card']}>
                    <div className={styles['header']}>
                        <label className={styles['name']}>
                            {batteryInfo.name ? batteryInfo.name : null}
                        </label>
                    </div>
                    <hr className={styles['horizontal-line']}></hr>
                    <div className={styles['info-container']}>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Name</label>
                            <Input
                                className={styles['item-string']}
                                name='name'
                                onPressEnter={(e) => e.target.blur()}
                                readOnly
                                value={batteryInfo.name}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                    </div>
                </div>
            </Dashboard>
    )
}


export default BatteryInfoPage