import React, { Component } from 'react'
import styles from '../style/size-wrapper.module.css'

// detects its' size and passes it along to it's children
class SizeWrapper extends Component {

    constructor(props) {
        super(props)

        // declare reference to div wrapper
        this.wrapper = React.createRef()

        // initialize state
        this.state = {
            height: 1, // px
            width: 1 // px
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions)
        this.updateDimensions()
    }

    componentDidUpdate() {
        //  handle client dimension change caused by something other than a browser resize.
        if (this.state.height !== this.wrapper.current.clientHeight || this.state.width !== this.wrapper.current.clientWidth) {
            this.updateDimensions()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    updateDimensions = () => {
        this.setState({
                height: this.wrapper.current.clientHeight,
                width: this.wrapper.current.clientWidth
            })
    }

    render() {
        const children = React.Children.map(
            this.props.children,
            child => {
                return React.cloneElement(
                    child,
                    { parentHeightPx: this.state.height, parentWidthPx: this.state.width }
                )
            }
        )

        return (
            <div
                className={styles['wrapper']}
                ref={this.wrapper}
            >
                {children}
            </div>
        )
    }
}

export default SizeWrapper