import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Editor from './item-content/item-content-editor'
import Input from './input'
import styles from '../style/expression.module.css'
import { CloseOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'


/*
    A visual logic component
*/

// item content context owns value
// TOOD: visual feedback when literals can't be parsed from input
// TOOD: rename public param 'node' to 'value'
// TODO: add a stack trace to eval so error messages can be useful
// TODO: make a 'validInput' object ie: { add: { number: true } } for use when constructing an expression
// TODO: make a toString function so expressions can be logged better
// TODO: change undefined to null. why did i choose undefined over null in the first place?

const Node = ({node, onChange, placeholder}) =>
{
    const ref = useRef()

    const [defaultType, setDefaultType] = useState('')
    const [hovered, setHovered] = useState(false)

    let defaultNodes = useMemo(() =>
    {
        let functions =
        {
            add:
            {
                type: 'add',
                lhs: {},
                rhs: {}
            },
            all:
            {
                type: 'all',
                nodes: []
            },
            and:
            {
                type: 'and',
                nodes: []
            },
            compare:
            {
                type: 'compare',
                lhs: {},
                operator:
                {
                    type: 'string',
                    value: ''
                },
                rhs: {}
            },
            concat:
            {
                type: 'concat',
                lhs: {},
                rhs: {}
            },
            divide:
            {
                type: 'divide',
                lhs: {},
                rhs: {}
            },
            equals:
            {
                type: 'equals',
                lhs: {},
                rhs: {}
            },
            exists:
            {
                type: 'exists',
                key:
                {
                    type: 'string',
                    value: ''
                }
            },
            fixed:
            {
                type: 'fixed',
                value: {},
                digits:
                {
                    type: 'number',
                    value: 2
                }
            },
            get:
            {
                type: 'get',
                key:
                {
                    type: 'string',
                    value: ''
                }
            },
            if:
            {
                type: 'if',
                condition: {},
                then: {},
                else: {}
            },
            multiply:
            {
                type: 'multiply',
                lhs: {},
                rhs: {}
            },
            not:
            {
                type: 'not',
                value: {}
            },
            now:
            {
                type: 'now'
            },
            once:
            {
                type: 'once',
                node: {},
                uuid: ''
            },
            or:
            {
                type: 'or',
                nodes: []
            },
            round:
            {
                type: 'round',
                value: {}
            },
            set:
            {
                type: 'set',
                key:
                {
                    type: 'string',
                    value: ''
                },
                value: {}
            },
            subtract:
            {
                type: 'subtract',
                lhs: {},
                rhs: {}
            },
            varkey:
            {
                type: 'varkey',
                value:
                {
                    type: 'string',
                    value: ''
                }
            },
        }

        let literals =
        {
            bool:
            {
                type: 'bool',
                value: false
            },
            number:
            {
                type: 'number',
                value: 0
            },
            string:
            {
                type: 'string',
                value: ''
            },
            undefined:
            {
                type: 'undefined',
                value: ''
            }
        }

        return {...functions, ...literals}
    }, [])


    const onMouseOver = useCallback((e) =>
    {
        e.stopPropagation()
        setHovered(true)
    }, [])

    const onMouseOut = useCallback((e) =>
    {
        setHovered(false)
    }, [])


    useEffect(() =>
    {
        const node = ref.current

        node.addEventListener('mouseover', onMouseOver)
        node.addEventListener('mouseout', onMouseOut)

        return () =>
        {
            node.removeEventListener('mouseover', onMouseOver)
            node.removeEventListener('mouseout', onMouseOut)
        }
    }, [onMouseOver, onMouseOut])

    
    // literal node
    if (node.type && isLiteral(node))
    {
        return   <div
                    className={styles['container']}
                    hovered={hovered ? 'true' : null}
                    literal={1}
                    onCopy={event =>
                    {
                        event.preventDefault()
                        event.stopPropagation()
                        event.clipboardData.setData('text/plain', JSON.stringify(node))
                    }}
                    ref={ref}
                    type={node.type}
                >
                    <div className={styles['header-container']}>
                        <div
                            className={styles['delete']}
                            onMouseUp={event => { onChange({}) }}
                        >
                            <CloseOutlined />
                        </div>
                        <label className={styles['label']} bold={'true'}>{node.type.toUpperCase()}</label>
                    </div>
                    <div className={styles['body-container']}>
                        {
                            node.type !== 'undefined' &&
                            <div
                                className={styles['input-container']}
                                onCopy={event =>
                                {
                                    // prevent copy event from reaching the parent node.
                                    // this allows the user to copy the litteral content of the input, instead of the parent node's stringified JSON.
                                    event.stopPropagation()
                                }}
                            >
                                <Input
                                    className={styles['input']}
                                    onChange={event => // TODO: change this to onBlur and onPressEnter
                                    {
                                        // TODO: validate value
                                        let newValue = event.target.value

                                        // parse the new value
                                        switch (node.type)
                                        {
                                            case 'bool':
                                                // convert to lower case on match, else leave as is
                                                newValue = newValue.toLowerCase()
                                                if (newValue === 'true') { newValue = true }
                                                else if (newValue === 'false') { newValue = false }
                                                else { newValue = event.target.value }
                                                break

                                            case 'number':
                                                let parsedFloat = Number.parseFloat(newValue)
                                                newValue = parsedFloat || parsedFloat === 0 ? parsedFloat : newValue
                                                break

                                            default:
                                                break
                                        }

                                        let newNode = {...node, value: newValue}
                                        onChange(newNode)
                                    }}
                                    value={node.value.toString()}
                                />
                            </div>
                        }
                    </div>
                </div>
    }

    // function node
    if (node.type && isFunction(node))
    {
        return   <div
                    className={styles['container']}
                    hovered={hovered ? 'true' : null}
                    onCopy={event =>
                    {
                        event.preventDefault()
                        event.stopPropagation()
                        event.clipboardData.setData('text/plain', JSON.stringify(node))
                    }}
                    ref={ref}
                    type={node.type}
                >
                    <div className={styles['header-container']}>
                        <div
                            className={styles['delete']}
                            onMouseUp={event => { onChange({}) }}
                        >
                            <CloseOutlined />
                        </div>
                        <label className={styles['label']} bold={'true'}>{node.type.toUpperCase()}</label>
                    </div>
                    <div className={styles['body-container']}>
                    {
                        (node.type === 'all' || node.type === 'and' || node.type === 'or') ?
                            <>
                                {
                                    node.nodes.map((n, i) =>
                                    <Node
                                        key={i}
                                        node={n}
                                        onChange={newChildNode =>
                                        {
                                            let newNodes = [...node.nodes]
                                            if (isNull(newChildNode))
                                            {
                                                // delete new null node
                                                newNodes.splice(i, 1)
                                            }else
                                            {
                                                // replace existing with new node
                                                newNodes.splice(i, 1, newChildNode)
                                            }

                                            onChange({...node, nodes: newNodes})
                                        }}
                                    />)
                                }
                                <Node
                                    node={{}}
                                    onChange={newChildNode =>
                                    {
                                        let newNodes = [...node.nodes, newChildNode]
                                        onChange({...node, nodes: newNodes})
                                    }}
                                />
                            </>
                        :
                            functionKeys[node.type].map((key, i) =>
                                <React.Fragment key={`fragment${i}`}>
                                    <Node
                                        node={node[key]}
                                        onChange={newChildNode =>
                                        {
                                            let newNode = {...node, [key]: newChildNode}
                                            onChange(newNode)
                                        }}
                                        placeholder={key}
                                    />
                                </React.Fragment>
                            )
                    }
                    </div>
                </div>
    }

    // default / blank node
    return  <div
                className={styles['container']}
                hovered={hovered ? 'true' : null}
                onPaste={event =>
                {
                    event.preventDefault()
                    let nodeString = event.clipboardData.getData('text/plain')
                    let node = JSON.parse(nodeString)
                    onChange(node)
                }}
                ref={ref}
            >
                <div className={styles['input-container']}>
                    <Input
                        className={styles['input']}
                        onChange={event =>
                        {                            
                            let type = event.target.value.toLowerCase()

                            if (isLiteral(type) || isFunction(type))
                            {
                                // valid type
                                const newNode = defaultNodes[type]

                                if (type === 'once')
                                {
                                    // assign unique id
                                    newNode.uuid = `${uuidv4().replaceAll('-', '')}`
                                }

                                setDefaultType('')
                                onChange(newNode)
                                setHovered(false)
                            }else
                            {
                                // invalid type
                                setDefaultType(event.target.value)
                            }
                        }}
                        style={{ maxWidth: '10vmin' }}
                        placeholder={placeholder}
                        value={defaultType}
                    />
                </div>
            </div>
}


// mutates editor data and children
// passive === true: evaluates, but doesn't mutate data. may only set varkey.
// throws
// returns node || null
const evaluate = (params, dataBuffer = {}, recursionLevel = 1) =>
{
    const { node, editor, path, passive } = params

    let result = null

    if (isNull(node)) throw new Error('eval: invalid node: ' + JSON.stringify(node, null, '\t'))

    else if (isLiteral(node))
    {
        result = node
    }

    else if (node.type === 'add')
    {
        let lhs = evaluate({...params, node: node.lhs}, dataBuffer, recursionLevel + 1)
        if (!lhs || lhs.type !== 'number') throw new Error('add: invalid lhs: ' + JSON.stringify(lhs, null, '\t') + JSON.stringify(node, null, '\t'))
        let rhs = evaluate({...params, node: node.rhs}, dataBuffer, recursionLevel + 1)
        if (!rhs || rhs.type !== 'number') throw new Error('add: invalid rhs: ' + JSON.stringify(node, null, '\t'))
        result =
        {
            type: 'number',
            value: lhs.value + rhs.value
        }
    }

    else if (node.type === 'all')
    {
        if (!Array.isArray(node.nodes)) throw new Error('all: invalid nodes: ' + JSON.stringify(node, null, '\t'))
        for (const n of node.nodes)
        {
            evaluate({...params, node: n}, dataBuffer, recursionLevel + 1)
        }
    }

    else if (node.type === 'and')
    {
        if (!Array.isArray(node.nodes)) throw new Error('and: invalid nodes: ' + JSON.stringify(node, null, '\t'))

        result =
        {
            type: 'bool',
            value: true
        }

        // lazily check for a false
        for (const n of node.nodes)
        {
            const andResult = evaluate({...params, node: n}, dataBuffer, recursionLevel + 1)
            if (isNull(andResult) || (andResult.type !== 'bool' && andResult.type !== 'undefined')) throw new Error('and: invalid node: ', JSON.stringify(node, null, '\t'))
            if (andResult.value === false || andResult.type === 'undefined')
            {
                result =
                {
                    type: 'bool',
                    value: false
                }
                break
            } 
        }
    }

    else if (node.type === 'compare')
    {
        let lhs = evaluate({...params, node: node.lhs}, dataBuffer, recursionLevel + 1)
        if (!lhs || lhs.type !== 'number') throw new Error('compare: lhs must provide a number: ' + JSON.stringify(lhs, null, '\t') + JSON.stringify(node, null, '\t'))
        let operator = evaluate({...params, node: node.operator}, dataBuffer, recursionLevel + 1)
        if (!operator || operator.type !== 'string') throw new Error('compare: operator must provide a string: ' + JSON.stringify(lhs, null, '\t') + JSON.stringify(node, null, '\t'))
        let rhs = evaluate({...params, node: node.rhs}, dataBuffer, recursionLevel + 1)
        if (!rhs || rhs.type !== 'number') throw new Error('compare: rhs must provide a number: ' + JSON.stringify(lhs, null, '\t') + JSON.stringify(node, null, '\t'))

        result =
        {
            type: 'bool',
            value: null
        }

        switch (operator.value)
        {
            case '<':
                result.value = lhs.value < rhs.value
                break

            case '<=':
                result.value = lhs.value <= rhs.value
                break

            case '>':
                result.value = lhs.value > rhs.value
                break

            case '>=':
                result.value = lhs.value >= rhs.value
                break

            default:
                throw new Error(`compare: invalid operator (${JSON.stringify(operator, null, '\t')}). must be one of the following operators: < <= > >=`)
        }
    }

    else if (node.type === 'concat')
    {
        let lhs = evaluate({...params, node: node.lhs}, dataBuffer, recursionLevel + 1)
        if (!lhs || !isLiteral(lhs)) throw new Error('concat: lhs must provide a literal: ' + JSON.stringify(lhs, null, '\t') + JSON.stringify(node, null, '\t'))
        let rhs = evaluate({...params, node: node.rhs}, dataBuffer, recursionLevel + 1)
        if (!rhs || !isLiteral(rhs)) throw new Error('concat: rhs must provide a literal: ' + JSON.stringify(lhs, null, '\t') + JSON.stringify(node, null, '\t'))
        result =
        {
            type: 'string',
            value: `${lhs.value}${rhs.value}`
        }
    }

    else if (node.type === 'divide')
    {
        let lhs = evaluate({...params, node: node.lhs}, dataBuffer, recursionLevel + 1)
        if (!lhs || lhs.type !== 'number') throw new Error('divide: invalid lhs: ' + JSON.stringify(lhs, null, '\t') + JSON.stringify(node, null, '\t'))
        let rhs = evaluate({...params, node: node.rhs}, dataBuffer, recursionLevel + 1)
        if (!rhs || rhs.type !== 'number') throw new Error('divide: invalid rhs: ' + JSON.stringify(node, null, '\t'))
        result =
        {
            type: 'number',
            value: lhs.value / rhs.value
        }
    }

    else if (node.type === 'equals')
    {
        let lhs = evaluate({...params, node: node.lhs}, dataBuffer, recursionLevel + 1)
        let rhs = evaluate({...params, node: node.rhs}, dataBuffer, recursionLevel + 1)
        result =
        {
            type: 'bool',
            value: lhs.type === rhs.type && lhs.value === rhs.value
        }
    }

    else if (node.type === 'exists')
    {
        let key = evaluate({...params, node: node.key}, dataBuffer, recursionLevel + 1)

        if (!key || key.type !== 'string') throw new Error('get: invalid key: ' + JSON.stringify(node, null, '\t'))

        result =
        {
            type: 'bool',
            value: {...editor.data, ...dataBuffer}.hasOwnProperty(key.value)
        }
    }

    else if (node.type === 'fixed')
    {
        let value = evaluate({...params, node: node.value}, dataBuffer, recursionLevel + 1)
        if (!value || value.type !== 'number') throw new Error('fixed: invalid value: ' + JSON.stringify(node, null, '\t'))
        let digits = evaluate({...params, node: node.digits}, dataBuffer, recursionLevel + 1)
        if (!digits || digits.type !== 'number') throw new Error('fixed: invalid digits: ' + JSON.stringify(node, null, '\t'))

        result =
        {
            type: 'number',
            value: Number.parseFloat(Number.parseFloat(value.value).toFixed(digits.value))
        }
    }

    else if (node.type === 'get')
    {
        let key = evaluate({...params, node: node.key}, dataBuffer, recursionLevel + 1)

        if (!key) throw new Error('get: invalid key: ' + JSON.stringify(node, null, '\t'))
        if (key.type !== 'string' && key.type !== 'undefined') throw new Error('get: invalid key: ' + JSON.stringify(node, null, '\t'))

        let dataValue = {...editor.data, ...dataBuffer}[key.value]
        let type = typeof dataValue

        switch (type)
        {
            case 'boolean':
                type = 'bool'
                break

            case 'undefined':
                dataValue = ''
                break

            default:
                break
        }
        
        result =
        {
            type,
            value: dataValue
        }
    }

    else if (node.type === 'if')
    {
        let condition = evaluate({...params, node: node.condition}, dataBuffer, recursionLevel + 1)
        if (condition.type !== 'bool' && condition.type !== 'undefined') throw new Error('if: condition did not evaluate to a bool: ' + JSON.stringify(node, null, '\t'))
        if (condition.value === true) result = evaluate({...params, node: node.then}, dataBuffer, recursionLevel + 1)
        else result = evaluate({...params, node: node.else}, dataBuffer, recursionLevel + 1)
    }

    else if (node.type === 'multiply')
    {
        let lhs = evaluate({...params, node: node.lhs}, dataBuffer, recursionLevel + 1)
        if (!lhs || lhs.type !== 'number') throw new Error('multiply: invalid lhs: ' + JSON.stringify(lhs, null, '\t') + JSON.stringify(node, null, '\t'))
        let rhs = evaluate({...params, node: node.rhs}, dataBuffer, recursionLevel + 1)
        if (!rhs || rhs.type !== 'number') throw new Error('multiply: invalid rhs: ' + JSON.stringify(node, null, '\t'))
        result =
        {
            type: 'number',
            value: lhs.value * rhs.value
        }
    }

    else if (node.type === 'not')
    {
        let value = evaluate({...params, node: node.value}, dataBuffer, recursionLevel + 1)
        if (!isLiteral(value)) throw new Error('not: value must evaluate to literal: ' + JSON.stringify(node, null, '\t'))
        result =
        {
            type: 'bool',
            value: !value.value
        }
    }

    else if (node.type === 'now')
    {
        result =
        {
            type: 'number',
            value: Date.now()
        }
    }

    else if (node.type === 'once')
    {
        // only eval this node the first time it is encountered
        // use current item id + uuid to flag data
        const data = {...editor.data, ...dataBuffer}
        const flag = `once_${data['item_id']}_${node.uuid}`

        if (data[flag] !== true)
        {
            // set the flag so this node isn't evaluated again
            dataBuffer[flag] = true

            result = evaluate({...params, node: node.node}, dataBuffer, recursionLevel + 1)
        }
    }

    else if (node.type === 'or')
    {
        if (!Array.isArray(node.nodes)) throw new Error('and: invalid nodes: ' + JSON.stringify(node, null, '\t'))

        result =
        {
            type: 'bool',
            value: false
        }

        // lazily check for a true
        for (const n of node.nodes)
        {
            const orResult = evaluate({...params, node: n}, dataBuffer, recursionLevel + 1)
            if (isNull(orResult) || (orResult.type !== 'bool' && orResult.type !== 'undefined')) throw new Error('and: invalid node: ', JSON.stringify(node, null, '\t'))
            if (orResult.value === true)
            {
                result =
                {
                    type: 'bool',
                    value: true
                }
                break
            } 
        }
    }

    else if (node.type === 'round')
    {
        let value = evaluate({...params, node: node.value}, dataBuffer, recursionLevel + 1)
        if (!value || value.type !== 'number') throw new Error('round: invalid value: ' + JSON.stringify(value, null, '\t') + JSON.stringify(node, null, '\t'))
        result =
        {
            type: 'number',
            value: Math.round(value.value)
        }
    }

    else if (node.type === 'set')
    {
        let key = evaluate({...params, node: node.key}, dataBuffer, recursionLevel + 1)   
        if (!key || key.type !== 'string') throw new Error('set: invalid key: ' + JSON.stringify(node, null, '\t'))
        let value = evaluate({...params, node: node.value}, dataBuffer, recursionLevel + 1)
        if (!value || !isLiteral(value)) throw new Error('set: invalid value: ' + JSON.stringify(node, null, '\t'))
        dataBuffer[key.value] = value.value
    }

    else if (node.type === 'subtract')
    {
        let lhs = evaluate({...params, node: node.lhs}, dataBuffer, recursionLevel + 1)
        if (!lhs || lhs.type !== 'number') throw new Error('subtract: invalid lhs: ' + JSON.stringify(lhs, null, '\t') + JSON.stringify(node, null, '\t'))
        let rhs = evaluate({...params, node: node.rhs}, dataBuffer, recursionLevel + 1)
        if (!rhs || rhs.type !== 'number') throw new Error('subtract: invalid rhs: ' + JSON.stringify(node, null, '\t'))
        result =
        {
            type: 'number',
            value: lhs.value - rhs.value
        }
    }

    else if (node.type === 'varkey')
    {
        let newVarKey = evaluate({...params, node: node.value}, dataBuffer, recursionLevel + 1)
        if (
            !newVarKey ||
            !isLiteral(newVarKey) ||
            newVarKey.type !== 'string'
        ) throw new Error('varKey: invalid value: ' + JSON.stringify(node, null, '\t'))

        Editor.setNodeProperties(editor, 'function', { varKey: newVarKey.value }, path)
    }

    // set data buffer to poll context at end of eval
    if (recursionLevel === 1 && !passive) editor.sendData(dataBuffer)

    return result
}

const functionKeys =
{
    add:        ['lhs', 'rhs'],
    all:        ['nodes'],
    and:        ['nodes'],
    compare:    ['lhs', 'operator', 'rhs'],
    concat:     ['lhs', 'rhs'],
    divide:     ['lhs', 'rhs'],
    equals:     ['lhs', 'rhs'],
    exists:     ['key'],
    fixed:      ['value', 'digits'],
    get:        ['key'],
    if:         ['condition', 'then', 'else'],
    multiply:   ['lhs', 'rhs'],
    not:        ['value'],
    now:        [],
    once:       ['node'],
    or:         ['nodes'],
    round:      ['value'],
    set:        ['key', 'value'],
    subtract:   ['lhs', 'rhs'],
    varkey:     ['value']
}

// returns an array of strings used as GET keys or as a VARKEY
const getDataKeyDependencies = node =>
{
    if (!node || !isFunction(node)) return []

    if (node.type === 'get' && node.key && node.key.type === 'string') return [node.key.value]
    else if (node.type === 'varkey' && node.value && node.value.type === 'string') return [node.value.value]
    else
    {
        // recurse on children nodes
        let childrenNodes = []
        if (node.type === 'all') childrenNodes = node.nodes
        else
        {
            for (let childNodePropertyName of functionKeys[node.type]) childrenNodes.push(node[childNodePropertyName])
        }

        let result = []
        for (let childNode of childrenNodes) result = [...result, ...getDataKeyDependencies(childNode)]
        return result
    }
}

// the default slate function wrapper node
const DefaultFunctionNode =
{
    type: 'function',
    children: [],
    dataKeyDependencies: [],
    expression: {}, // evaluated on click
    initExpression: {}, // evaluated init once then deleted
    updateExpression: {} // evaluated at render when dep data changes
}

const isFunction = nodeOrType =>
{
    let type = nodeOrType
    if (typeof type === 'object') type = nodeOrType.type

    switch (type)
    {
        case 'add':         return true
        case 'all':         return true
        case 'and':         return true
        case 'compare':     return true
        case 'concat':      return true
        case 'divide':      return true
        case 'equals':      return true
        case 'exists':      return true
        case 'fixed':       return true
        case 'get':         return true
        case 'if':          return true
        case 'multiply':    return true
        case 'not':         return true
        case 'now':         return true
        case 'once':        return true
        case 'or':          return true
        case 'round':       return true
        case 'set':         return true
        case 'subtract':    return true
        case 'varkey':      return true
        default:            return false
    }
}

const isLiteral = nodeOrType =>
{
    let type = nodeOrType
    if (typeof nodeOrType === 'object') type = nodeOrType.type

    switch (type)
    {
        case 'bool':        return true
        case 'number':      return true
        case 'string':      return true
        case 'undefined':   return true
        default:            return false
    }
}

const isNull = node =>
{
    return !node || !node.type
}


export { DefaultFunctionNode, evaluate, getDataKeyDependencies, Node as Expression, isNull }