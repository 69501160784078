/*
    Examiner's examinee list page
*/

import React, { Component } from 'react'
import Dashboard from '../dashboard'
import axios from 'axios'
import { connect } from 'react-redux'
import BigList from '../big-list'

class ExamineeListPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            examinees: [],
            listItems: []
        }
    }

    async componentDidMount() {
        await this.getDirectory()
        this.createExamineeListItems()
    }

    createExamineeListItems = () => {
        let items = this.state.examinees.map((examinee) => {
            return {
                destination: `/examiner/examinees/${examinee._id}/info`,
                filterValue: `${examinee.firstName || ''} ${examinee.lastName || ''} ${examinee.label || ''}`.toLowerCase(),
                subtitle: examinee.label,
                title: `${examinee.firstName || ''} ${examinee.lastName || ''}`,
                uniqueKey: examinee._id
            }
        })

        this.setState({
            listItems: items
        })
    }

    createNewExaminee = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/examinee/create', {}, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            // return destination to BigList
            return `/examiner/examinees/${response.data.examineeID}/info`
        })
        .catch(error => { console.log(error) })
    }

    getDirectory() {
        return axios.get(process.env.REACT_APP_API_URL + '/examinee/directory',
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        })
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            this.setState({
                examinees: response.data.examinees
            })
        })
        .catch(error => { console.log(error) })
    }

    render() {
        return (
            <Dashboard>
                <BigList
                    items={this.state.listItems}
                    onClickCreateNew={this.createNewExaminee}
                    title='Examinees'
                />
            </Dashboard>
        )
    }

}

const mapStateToProps = (state) => ({
    token: state.token
})

export default connect(mapStateToProps, null)(ExamineeListPage)