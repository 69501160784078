import React, { Component } from 'react'
import styles from '../style/table.module.css'
import { Input, InputNumber } from 'antd'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'


/*
    props: {
        canAdd: Bool // toggle if the 'add' row is displayed while isEditing
        canDelete: Bool // toggle if the delete column is displayed while isEditing
        canModify: Bool // toggle if the table's content can be changed while isEditing
        columns: [{
            dataKey: String, // the key of the row's data object associated with this column
            title: String, // display name of the column
            type: String, //type of data: input, node, number, textarea
        }],
        createNewRow: () => {} // delegate: request that a new row of data be created
        data: [{
            // one object per row
        }],
        dataDidChange: (newData) => {} // callback: send updated data object to parent
        deleteRow: (rowIndex) => {} // delegate (optional): request that a row be deleted
        isEditiing: Bool
    }
*/
class Table extends Component
{
    renderCellContent = (rowIndex, columnIndex) =>
    {
        let column = this.props.columns[columnIndex]
        switch (column.type)
        {
            case 'input':
                return <Input
                    className={styles['cell-string']}
                    onChange={(event) =>
                        {
                        const { value } = event.target
                        let newData = this.props.data
                        newData[rowIndex][column.dataKey] = value
                        this.props.dataDidChange(newData)
                    }}
                    onPressEnter={(e) => e.target.blur()}
                    readOnly={!this.props.isEditing || !this.props.canModify}
                    value={this.props.data[rowIndex][column.dataKey]}
                />

            case 'node':
                    return this.props.data[rowIndex][column.dataKey]

            case 'number':
                return <InputNumber
                    disabled={!this.props.isEditing || !this.props.canModify}
                    onChange={(value) =>
                    {
                        let newData = this.props.data
                        newData[rowIndex][column.dataKey] = value
                        this.props.dataDidChange(newData)
                    }}
                    value={this.props.data[rowIndex][column.dataKey]}
                    style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        color: '#354052',
                        fontSize: '2vmin'
                    }}
                />

            case 'textarea':
                return <textarea
                            className={styles['cell']}
                            defaultValue={this.props.data[rowIndex][column.dataKey]}
                            readOnly={!this.props.isEditing || !this.props.canModify}
                        />

            default:
                return null
        }
    }

    render()
    {
        if (!this.props.columns || this.props.columns.length === 0) return null

        let columnCount = this.props.columns.length
        if (this.props.isEditing) columnCount++

        let cellStyle = {flex: `0 1 ${95 / columnCount}%`}
        let deleteCellStyle = {flex: `0 1 5%`}

        return (
            <div className={styles['container']}>
                <div className={styles['header-container']}>
                    <div className={styles['row']}>
                        {
                            // column headers
                            this.props.columns.map((column, i) =>
                            {
                                return (
                                    <div
                                        className={styles['cell']}
                                        key={'headerKey='+i}
                                        style={cellStyle}
                                    >
                                        <label
                                            className={styles['column-title']}
                                            key={'headerLabelKey='+i}
                                        >
                                            {column.title}
                                        </label>
                                    </div> 
                                )
                            })
                        }
                        {
                            // delete column header
                            this.props.isEditing && this.props.canDelete ?
                                <div
                                    className={styles['cell']}
                                    style={deleteCellStyle}
                                >
                                    <label className={styles['column-title']} >
                                        Delete
                                    </label>
                                </div> :
                                null
                        }
                    </div>
                </div>
                <div className={styles['body-container'] + ' hide-scrollbars'}>
                    {
                        this.props.data ?
                        // rows
                        this.props.data.map((data, rowIndex) =>
                        {
                            return (
                                <div
                                    className={styles['row'] + ' ' + (rowIndex % 2 === 0 ? styles['colored'] : null)}
                                    key={rowIndex}
                                >
                                    {
                                        // cells
                                        this.props.columns.map((column, columnIndex) =>
                                        {
                                            return (
                                                <div
                                                    className={styles['cell']}
                                                    key={rowIndex + '' + columnIndex}
                                                    style={cellStyle}
                                                >
                                                    { this.renderCellContent(rowIndex, columnIndex) }
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        // delete cell
                                        this.props.isEditing && this.props.canDelete ?
                                            <div
                                                className={styles['cell']}
                                                key={'deleteKey-'+rowIndex}
                                                onClick={() =>
                                                {
                                                    this.props.deleteRow(rowIndex)
                                                }}
                                                style={deleteCellStyle}
                                            >
                                                <DeleteOutlined />
                                            </div>
                                            : null
                                    }
                                </div>
                            )
                        }) : null
                    }
                    {
                        // add row
                        this.props.isEditing && this.props.canAdd ?
                            <div
                                className={styles['row']}
                                key='addRowRowKey'
                                onClick={() =>
                                {
                                    this.props.createNewRow()
                                }}
                            >
                                <PlusCircleOutlined style={{ color: '#1890ff' }} />
                            </div>
                            : null
                    }
                </div>
            </div>
        )
    }
}

Table.defaultProps =
{
    canAdd: true,
    canDelete: true,
    canModify: true,
    createNewRow: () => {},
    dataDidChange: (newData) => { throw new Error('Table::dataDidChange must be provided for an editable table.') },
    deleteRow: (rowIndex) => { throw new Error('Table::deleteRow must be provided for a table that can delete rows.')},
    isEditiing: false
}

export default Table