import React, { Component } from 'react'
import styles from '../style/big-list.module.css'
import { SearchOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

/*
    props:
    {
        allowCreateNew: bool
        createNew_customLabel: string // optional replacement text for 'create new' button
        didReorder: (fromIndex, toIndex) => {} // callback for when an item is reordered
        items:
        [
            {
                body: ReactElement
                destination: string
                filterValue: string // a string containing all the desired filterable values
                subtitle: string
                title: string
                uniqueKey: string
            }
        ]
        onClickCreateNew: () => Promise // return the destination string
        reorderable: Bool // default false
        title: string
    }
*/
class BigList extends Component
{

    constructor(props)
    {
        super(props) // TODO: deal with this

        this.state =
        {
            filteredItems: [],
            filter: ''
        }
    }

    componentDidUpdate(prevProps, prevState)
    {
        if (this.props.items !== prevProps.items)
        {
            this.applyFilter()
        }

        if (this.state.filter !== prevState.filter)
        {
            this.applyFilter()
        }
    }

    applyFilter = () =>
    {
        let filters = this.state.filter.split(' ').filter(filter => filter.length > 0)
        let result = []

        if (filters.length === 0)
        {
            // filter bar is empty, result is all items
            result = this.props.items
        } else {
            for (let item of this.props.items)
            {
                let shouldIncludeItem = true
                let filterValueLower = item.filterValue.toLowerCase()
                for(let filter of filters)
                {
                    if (filterValueLower.indexOf(filter) < 0)
                    {
                        shouldIncludeItem = false
                        break
                    }
                }
                if (shouldIncludeItem)
                {
                    result.push(item)
                }
            }
        }

        return this.setState(
        {
            filteredItems: result
        })
    }

    filterDidChange = (e) =>
    {
        this.setState(
        {
            filter: e.target.value.toLowerCase()
        })
    }

    onClickCreateNew = async () =>
    {
        if (!this.props.onClickCreateNew) return
        let destination = await this.props.onClickCreateNew()
        this.props.history.push(destination)
    }

    render()
    {
        return (
            <DragDropContext onDragEnd={result => this.props.didReorder(result.source.index, result.destination.index)}>
                <div className={styles['container']}>
                    <div className={styles['header']}>
                        <label className={styles['title']}>
                            {this.props.title}
                        </label>
                        <div className={styles['divider']}>
                        </div>
                        <SearchOutlined className={styles['search-icon']} />
                        <input
                            className={styles['search-bar']}
                            onChange={this.filterDidChange}
                        />
                        {
                            this.props.allowCreateNew ?
                            <button
                                className={styles['create-new']}
                                onClick={this.onClickCreateNew}
                            >
                                {this.props.createNew_customLabel || 'Create New +'}
                            </button> : null
                        }
                    </div>
                    <Droppable droppableId={'bigListDroppableID-0'}>
                        {provided => (
                            <div
                                className={styles['list'] + ' hide-scrollbars'}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {
                                    this.state.filteredItems.map((item, i) => {
                                        return (
                                            <Draggable
                                                draggableId={item.uniqueKey}
                                                index={i}
                                                isDragDisabled={!this.props.reorderable || this.state.filter !== ''}
                                                key={item.uniqueKey}
                                            >
                                                {provided => (
                                                    <div
                                                        className={styles['item']}
                                                        onClick={() => { if (item.destination) this.props.history.push(item.destination) }}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className={styles['vertical-bar']} />
                                                        <div className={styles['item_header-container']}>
                                                            <label className={styles['item_title-label']}>
                                                                {item.title || ''}
                                                            </label>
                                                            <label className={styles['item_subtitle-label']}>
                                                                {item.subtitle || ''}
                                                            </label>
                                                        </div>
                                                        <div
                                                            className={styles['item_body-container']}
                                                        >
                                                            {item.body || null}
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })
                                }
                                {provided.placeholder}
                            </div>
                        )
                        }
                    </Droppable>
                </div>
            </DragDropContext>
        )
    }
}

BigList.defaultProps =
{
    allowCreateNew: true,
    didReorder: (fromIndex, toIndex) => {},
    reorderable: false
}

export default withRouter(BigList)