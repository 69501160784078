/*
    User credit pool managment page
*/

import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../dashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import styles from '../../style/info-card.module.css'
import Table from '../table'
import { Button } from 'antd'


/* TODO
    - edit pool
    - set default
    - get default
*/


const CreditPage = () =>
{
    const token = useSelector(state => state.token)
    const [defaultPoolID, setDefaultPoolID] = useState(undefined)
    const [poolDir, setPoolDir] = useState([])
    const [columns, ] = useState([
        {
            dataKey: 'id',
            title: 'ID',
            type: 'input'
        },
        {
            dataKey: 'credits',
            title: 'Credits',
            type: 'number'
        },
        {
            dataKey: 'default',
            title: '',
            type: 'node'
        }
    ])
    const [data, setData] = useState([])


    const createPool = useCallback(async () =>
    { 
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/credit-pool/create', {}, config)
        .catch(error => { console.log(error) })
    }, [token])

    const getDefaultPoolID = useCallback(async () =>
    {
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/user/info', {}, config)
        .catch(error => { console.log(error) })

        setDefaultPoolID(response.data.defaultCreditPool)
    }, [token])

    const setDefaultPool = useCallback(async (poolID) =>
    {
        const data =
        {
            creditPoolID: poolID || null
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/credit-pool/set-default', data, config)
        .catch(error => { console.log(error) })
    }, [token])

    const getPoolDir = useCallback(async () =>
    { 
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/credit-pool/dir', {}, config)
        .catch(error => { console.log(error) })

        setPoolDir(response.data.creditPools)
    }, [token])

    const makeDefaultPoolNode = useCallback((poolID) =>
    {
        const isDefault = poolID === defaultPoolID

        return (
            <Button
                disabled={isDefault}
                onClick={async () =>
                {
                    await setDefaultPool(poolID)
                    await getDefaultPoolID()
                    await getPoolDir()
                }}
            >
                {isDefault ? 'Default' : 'Make Default'}
            </Button>
        )
    }, [defaultPoolID, getDefaultPoolID, getPoolDir, setDefaultPool])

    const makeData = useCallback(() =>
    {
        let result = []

        poolDir.forEach(pool =>
        {
            let row = {}
            row.credits = pool.credits
            row.id = pool._id
            row.default = makeDefaultPoolNode(pool._id)
            result.push(row)
        })

        setData(result)
    }, [makeDefaultPoolNode, poolDir])

    
    useEffect(() =>
    {
        getDefaultPoolID()
        getPoolDir()
    }, [getDefaultPoolID, getPoolDir])

    useEffect(() =>
    {
        makeData()
    }, [makeData, poolDir])

    return (
        <Dashboard>
                <div className={styles['card']}>
                    <div className={styles['header']}>
                        <label className={styles['name']}>
                            {'Credit Pools'}
                        </label>
                    </div>
                    <hr className={styles['horizontal-line']}></hr>
                    <div className={styles['info-container']}>
                        <div className={styles['item-container']} height-90='true' width-90='true'>
                            <label className={styles['item-name']}>Credit Pools</label>
                            <Table
                                canDelete={false}
                                columns={columns}
                                createNewRow={async () =>
                                {
                                    await createPool()
                                    await getDefaultPoolID()
                                    await getPoolDir()
                                }}
                                data={data}
                                dataDidChange={() => {}}
                                isEditing={true}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                    </div>
                </div>
            </Dashboard>
    )

}


export default CreditPage