/*
    Examiner Item content editor page wrapper

    This wrapper exists so that redirecting between examinee and examiner content editors ensures a re-init of the component with the new contentType.
*/

import React, { Component } from 'react'
import ContentEditorPage from './content-editor-page'

class ExaminerContentEditorPage extends Component {
    render() {
        return (
            <ContentEditorPage contentType='examiner' />
        )
    }
}

export default ExaminerContentEditorPage
