import { notification } from 'antd'
import { store } from '../store'
import { logout } from '../redux/action-creators'
import history from '../history'


class ErrorMiddleware {
    onResponseError(error) {
        // logout and redirect to login page if user needs a new JWT
        if (error.response.data === '"No authorization token was found"' ||
            error.response.data === 'jwt expired') {
            store.dispatch(logout())
            history.push('/')
        }

        // notify the user of the error
        notification['error']({
            message: 'Error (' + error.response.status + '):',
            description: error.response.data,
          })
          throw error
    }
}

export default ErrorMiddleware