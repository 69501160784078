import * as ActionType from './action-types'
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/json'

export const createUser = (email, kind, password) => (dispatch) => {
    dispatch(logout())

    const data = {
        email: email,
        kind: kind,
        password: password
    }

    axios.post(process.env.REACT_APP_API_URL + '/user/create', data, null)
    .then(response => {
        // check to make sure a data object was returned
        if (response.data == null) throw new Error('no response data')

        dispatch(setToken(response.data.user.token))
        dispatch(getUserInfo(response.data.user.token))
    })
    .catch(error => { console.log(error) })
}

export const getUserInfo = (token) => (dispatch) => {
    const data = {}
  
    const config = {
        headers: {
            'Authorization': 'Token ' + token
        }
    }

    axios.post(process.env.REACT_APP_API_URL + '/user/info', data, config)
    .then(response => {
        dispatch({
            type: ActionType.getUserInfo,
            userInfo: response.data
        })
    })
    .catch(error => { console.log(error) })
}

export const login = (email, password) => (dispatch) => {
    dispatch(logout())

    const data = {
        email: email,
        password: password
    }

    axios.post(process.env.REACT_APP_API_URL + '/user/login', data, null)
    .then(response => {
        // check to make sure a data object was returned
        if (response.data == null) throw new Error('no response data')

        dispatch(setToken(response.data.token))
        dispatch(getUserInfo(response.data.token))
    })
    .catch(error => { console.log(error) })
}

export const logout = () => (dispatch) => {
    dispatch({
        type: ActionType.logout
    })
}

export const setSessionID = (sessionID) => (dispatch) => {
    dispatch({
        type: ActionType.setSessionID,
        sessionID: sessionID
    })
}

export const setToken = (token) => (dispatch) => {
    dispatch({
        type: ActionType.setToken,
        token: token
    })
}