/*
    Developer's battery users page
*/

import React, { Component } from 'react'
import Dashboard from '../dashboard'
import styles from '../../style/info-card.module.css'
import { connect } from 'react-redux'
import axios from 'axios'
import Table from '../table'
import { Button, Input } from 'antd'
import { PlusSquareFilled } from '@ant-design/icons'


class BatteryUsersPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            addUserEmail: '',
            batteryID: props.match.params.batteryID,
            developerColumns: [
                {
                    dataKey: 'email',
                    title: 'Email',
                    type: 'input'
                },
                {
                    dataKey: 'firstName',
                    title: 'First Name',
                    type: 'input'
                },
                {
                    dataKey: 'lastName',
                    title: 'Last Name',
                    type: 'input'
                }
            ],
            developers: [],
            examinerColumns: [
                {
                    dataKey: 'email',
                    title: 'Email',
                    type: 'input'
                },
                {
                    dataKey: 'firstName',
                    title: 'First Name',
                    type: 'input'
                },
                {
                    dataKey: 'lastName',
                    title: 'Last Name',
                    type: 'input'
                }
            ],
            examiners: []
        }
    }

    componentDidMount() {
        this.getBatteryUsers()
    }

    addUser = (email) => {
        const data = {
            batteryID: this.state.batteryID,
            email: email
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/battery/addUser', data, config)
        .then(response => {
            this.setState({
                addUserEmail: ''
            })
            this.getBatteryUsers()
        })
        .catch(error => { console.log(error) })
    }

    developerDataDidChange = (newData) => {
        this.setState({
            developers: newData
        })
    }

    examinerDataDidChange = (newData) => {
        this.setState({
            examiners: newData
        })
    }

    getBatteryUsers = () => {
        const data = {
            batteryID: this.state.batteryID
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/battery/getUsers', data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            this.setState({
                developers: response.data.developers,
                examiners: response.data.examiners
            })
        })
        .catch(error => { console.log(error) })
    }

    removeUser = (email) => {
        const data = {
            batteryID: this.state.batteryID,
            email: email
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/battery/removeUser', data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            this.getBatteryUsers()
        })
        .catch(error => { console.log(error) })
    }

    render() {
        return (
            <Dashboard>
                <div className={styles['card']}>
                    <div className={styles['header']}>
                        <label className={styles['name']}>
                            Users
                        </label>
                    </div>
                    <hr className={styles['horizontal-line']}></hr>
                    <div className={styles['info-container']}>
                        <div className={styles['item-container']} height-40='true' width-100='true'>
                            <label className={styles['item-name']}>Developers</label>
                            <Table
                                canAdd={false}
                                canModify={false}
                                columns={this.state.developerColumns}
                                data={this.state.developers}
                                dataDidChange={this.developerDataDidChange}
                                deleteRow={(rowIndex) => { this.removeUser(this.state.developers[rowIndex].email) }}
                                isEditing
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']} height-40='true' width-100='true'>
                            <label className={styles['item-name']}>Examiners</label>
                            <Table
                                canAdd={false}
                                canModify={false}
                                columns={this.state.examinerColumns}
                                data={this.state.examiners}
                                dataDidChange={this.examinerDataDidChange}
                                deleteRow={(rowIndex) => { this.removeUser(this.state.examiners[rowIndex].email) }}
                                isEditing
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']} height-20='true' width-30='true'>
                            <label className={styles['item-name']}>Add User</label>
                            <div className={styles['item-subcontainer-row']}>
                                <Input
                                    onChange={(event) => { this.setState({ addUserEmail: event.target.value }) }}
                                    onPressEnter={(e) => { this.addUser(this.state.addUserEmail) }}
                                    placeholder='Email'
                                    value={this.state.addUserEmail}
                                />
                                <Button
                                    icon={<PlusSquareFilled />}
                                    onClick={() => { this.addUser(this.state.addUserEmail) }}
                                    type='primary'
                                />
                            </div>
                            <hr className={styles['item-underline']}></hr>
                        </div>
                    </div>
                </div>
            </Dashboard>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.token
})

export default connect(mapStateToProps, null)(BatteryUsersPage)