/*
    Report editor page
*/

import React, { useCallback, useEffect, useRef, useState } from 'react'
import Dashboard from '../dashboard'
import axios from 'axios'
import ItemContent from '../item-content/item-content'
import ItemContentContext from '../item-content/item-content-context'
import ItemContentToolbar from '../item-content/toolbar/item-content-toolbar'
import { PollingContext, useDataActions } from '../item-content/polling-context'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from '../../style/info-card.module.css'
import { EditOutlined, ReloadOutlined } from '@ant-design/icons'


const ReportEditorContent = () =>
{
    const { reportID } = useParams()
    const token = useSelector(state => state.token)

    const itemContentContextRef = useRef()

    const { resetData } = useDataActions()

    const [document, setDocument] = useState([])
    const [isEditing, setIsEditing] = useState(false)


    const getReport = useCallback(async () =>
    {
        if (!reportID) return
    
        const data =
        {
            reportID: reportID
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/report/getDocument', data, config)
        .catch(error => { console.log(error) })

        // setting document to null triggers the useEffect hook in itemContentContext when document comes back as undefined.
        // TODO: figure out how to get rid of this bandaid
        setDocument(null)
        setDocument(response.data.document)
    }, [token, reportID])

    const endEditing = useCallback(() =>
    {
        setIsEditing(false)
        getReport()
    }, [getReport])

    const saveDocument = useCallback(async () =>
    {
        const data =
        {
            reportID: reportID,
            document: itemContentContextRef.current.getValue()
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/report/edit', data, config)
        .catch(error => { console.log(error) })

        endEditing()
    }, [endEditing, reportID, token])


    useEffect(() =>
    {
        getReport()
    }, [getReport, token])


    return (
        <Dashboard>
                <ItemContentContext
                    readOnly={!isEditing}
                    ref={itemContentContextRef}
                    value={document}
                >
                    <div className={styles['card']}>
                        <div className={styles['header']}>
                            <ItemContentToolbar
                                cancelEditing={endEditing}
                                // contentID={this.state.contentID} // TODO: add file support in api.
                                saveChanges={saveDocument}
                            />
                            {
                                // reload button
                                !isEditing ?
                                    (
                                        <div
                                            className={styles['button-container']}
                                            onClick={() =>
                                            {
                                                resetData()
                                                getReport()
                                            }}
                                        >
                                            <ReloadOutlined />
                                            <label className={styles['header-button-label']}>
                                                Reload
                                            </label>
                                        </div>
                                    ) : null
                            }
                            {
                                // edit button
                                !isEditing ?
                                    (
                                        <div
                                            className={styles['button-container']}
                                            onClick={() =>
                                            {
                                                setIsEditing(true)
                                                getReport()
                                            }}
                                        >
                                            <EditOutlined />
                                            <label className={styles['header-button-label']}>
                                                Edit
                                            </label>
                                        </div>
                                    ) : null
                            }
                        </div>
                        <div className={styles['item-content-container']}>
                            <ItemContent />
                        </div>
                    </div>
                </ItemContentContext>
        </Dashboard>
    )
}


// a wraper for applying data context
const ReportEditorPage = () =>
{
    return (
        <PollingContext offline >
            <ReportEditorContent />
        </PollingContext>
    )
}

export default ReportEditorPage