/*
    Examinee test page
*/

import React, { useEffect, useMemo } from 'react'
import { PollingContext, useDataActions, useIsAdministeringTest } from '../item-content/polling-context'
import styles from '../../style/examinee/test-page.module.css'
import ItemContent from '../item-content/item-content'
import ItemContentContext from '../item-content/item-content-context'
import { useParams } from 'react-router-dom'

const TestPageContent = () =>
{
    const { sessionID }                 = useParams()
    const { setSessionID }              = useDataActions()   
    const isAdministeringTest           = useIsAdministeringTest() 
    const placeholder                   = useMemo(() =>
    {
        return [{
            "type": "paragraph",
            "children": [{
                "text": "",
                "fontFamily": "Roboto",
                "fontSize": "30"
            }],
            "textAlign": "center"
        }, {
            "type": "paragraph",
            "children": [{
                "text": "",
                "fontFamily": "Roboto",
                "fontSize": "30"
            }],
            "textAlign": "center"
        }, {
            "type": "paragraph",
            "children": [{
                "text": "",
                "fontFamily": "Roboto",
                "fontSize": "30"
            }],
            "textAlign": "center"
        }, {
            "type": "paragraph",
            "children": [{
                "text": "",
                "fontFamily": "Roboto",
                "fontSize": "30"
            }],
            "textAlign": "center"
        }, {
            "type": "paragraph",
            "children": [{
                "text": "",
                "fontFamily": "Roboto",
                "fontSize": "30"
            }],
            "textAlign": "center"
        }, {
            "type": "paragraph",
            "children": [{
                "text": "",
                "fontFamily": "Roboto",
                "fontSize": "30"
            }],
            "textAlign": "center"
        }, {
            "type": "paragraph",
            "children": [{
                "text": "Please wait for a test to begin.",
                "fontFamily": "Roboto",
                "fontSize": "40"
            }],
            "textAlign": "center"
        }]
    }, [])

    // apply sessionID
    useEffect(() =>
    {
        setSessionID(sessionID)
    }, [sessionID, setSessionID])

    return (
        <div className={styles['container']}>
            <ItemContentContext
                examinee
                interactionEnabled
                placeholder={isAdministeringTest === false ? placeholder : undefined}
                readOnly
            >
                <ItemContent />
            </ItemContentContext>
        </div>
    )
}

// a wraper for applying data context
const TestPage = () =>
{
    return (
        <PollingContext>
            <TestPageContent />
        </PollingContext>
    )
}

export default TestPage