/*
    Timer component for slate void node and/or test page widget bar.
    Must be used within PollingContext
    Checks data for timer_initialValue
    Timestamps owned by this component are ms, timestamps in data are s

    data:
    {
        response_duration: seconds
        timer_initialValue: seconds
        timer_reset: true // TODO
        timer_start: ms
        timer_stop: ms
    }
*/


import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from '../../style/item-content/timer.module.css'
import { useCurrentItem, useData, useDataActions } from './polling-context'


const Timer = () =>
{
    const currentItem                       = useCurrentItem()
    const data                              = useData()
    const { updateData }                    = useDataActions()

    const [displayValue, setDisplayValue]   = useState(null) // ms

    const isRunning                         = useMemo(() => data.timer_start && !data.timer_stop, [data])
    const increment                         = useMemo(() => currentItem.timer_countdown ? -1 : 1, [currentItem]) // the direction of the timer  1 || -1
    const response_duration                 = useMemo(() => data.response_duration, [data])
    const timer_initialValue                = useMemo(() => data.timer_initialValue, [data])
    const timer_start                       = useMemo(() => data.timer_start, [data])
    const timer_stop                        = useMemo(() => data.timer_stop, [data])


    const reset = useCallback(() =>
    {
        setDisplayValue(() => data.hasOwnProperty('timer_initialValue') ? data.timer_initialValue*1000 : 0)
        updateData({timer_reset: null, timer_start: null, timer_stop: null, response_duration: null})
    }, [data, updateData])

    // calculates and records result of start/stop timestamps
    const saveResult = useCallback(() =>
    {
        if (!data.timer_start || !data.timer_stop) return

        // caculate elapsed time and add to total. convert ms to s
        const currentDuration = data.response_duration*1000 || data.timer_initialValue*1000 || 0
        const elapsed = Math.max(0, (data.timer_stop - data.timer_start)) * increment
        let newDuration = Math.max(0, currentDuration + elapsed)

        updateData({response_duration: newDuration/1000, timer_start: null, timer_stop: null})
    }, [data, increment, updateData])

    const start = useCallback(() =>
    {
        // don't override existing start time
        if (timer_start) return

        // don't start if countdown is already at 0
        if (increment < 0 && typeof response_duration === 'number' && response_duration <= 0) return

        updateData({timer_start: Date.now()})
    }, [increment, response_duration, timer_start, updateData])

    const stop = useCallback(() =>
    {
        // don't override existing stop time
        if (timer_stop) return

        updateData({timer_stop: Date.now()})
    }, [timer_stop, updateData])

    const tick = useCallback(() =>
    {
        setDisplayValue(() =>
        {
            const baseDuration = response_duration != null ? response_duration*1000 : timer_initialValue*1000 || 0
            const elapsed = Date.now() - timer_start
            return Math.max(0, baseDuration + (elapsed * increment))
        })
    }, [increment, response_duration, timer_initialValue, timer_start])


    // update display value when switching items
    useEffect(() =>
    {
        setDisplayValue(() =>
        {
            if (response_duration != null) return response_duration*1000
            else if (timer_initialValue != null) return timer_initialValue*1000
            else return 0
        })
    }, [currentItem, response_duration, timer_initialValue])

    // tick
    useEffect(() =>
    {
        if (!isRunning) return

        // start the interval
        const intervalID = setInterval(() => tick(), 125)

        // clean up interval
        return () => clearInterval(intervalID)
    }, [isRunning, tick])

    // stop timer when countdown reaches 0
    useEffect(() =>
    {
        // bandaid: in the case of a auto starting countdown, the display value is not updated quick enough. A race condition occurs that stops the timer in ~0.1 sec.
        // the last condition ads a 0.5 sec buffer where the timer will not be automatically stopped. this is not ideal, and sholud be considered temporary; though a proper fix would probably require a significant refactor...
        if (isRunning && increment < 0 && displayValue === 0 && (Date.now() - timer_start) > 500) stop()
        
    }, [displayValue, increment, isRunning, stop, timer_start])

    // stop timer when moving away from current item
    useEffect(() =>
    {
        if (isRunning && (data.sessionControl_nextItem || data.sessionControl_prevItem || data.sessionControl_nextTest || data.sessionControl_endTest)) stop()
    }, [data, isRunning, stop])

    // save timer result when both timestamps are present
    useEffect(() =>
    {
        if (timer_start != null && timer_stop != null) saveResult()
    }, [saveResult, stop, timer_start, timer_stop])

    
    return(
        <div className={styles['container']}>
            <div className={styles['time-container']}>
                <label className={styles['time-label']}>
                    {displayValue/1000}
                </label>
            </div>
            <div className={styles['button-container']}>

                <button
                    className={styles['reset-button']}
                    onClick={() => reset()}
                >
                    reset
                </button>
                <button
                    className={styles['start-stop-button'] + ' ' + (isRunning ? styles['running'] : null)}
                    onClick={() => isRunning ? stop() : start()}
                >
                    {isRunning ? 'stop' : 'start'}
                </button>
            </div>
        </div>
    )
}


export default Timer