/*
    Developer's item info page
*/

import React, { Component } from 'react'
import Dashboard from '../dashboard'
import styles from '../../style/info-card.module.css'
import { connect } from 'react-redux'
import axios from 'axios'
import { Modal, Switch } from 'antd'
import { DeleteOutlined, EditOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons'
import Table from '../table'
import Input from '../input'


class ItemInfoPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            allowedResponsesColumns: [
                {
                    dataKey: 'score',
                    title: 'Score',
                    type: 'number'
                },
                {
                    dataKey: 'response',
                    title: 'Response',
                    type: 'input'
                }
            ],
            batteryID: props.match.params.batteryID,
            itemID: props.match.params.itemID,
            testID: props.match.params.testID,
            itemInfo: {
                label: ''
            },

            // flags
            isEditing: false
        }
    }

    componentDidMount() {
        this.getItemInfo()
    }

    endEditing = () => {
        this.setState({
            isEditing: false
        })

        this.getItemInfo()
    }

    deleteItem = () => {
        return new Promise((resolve, reject) => {
            axios.delete(process.env.REACT_APP_API_URL + '/item/delete',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + this.props.token
                    },
                    data: {
                        itemID: this.state.itemID
                    }
                })
                .then(response => {
                    this.props.history.replace(`/developer/batteries/${this.state.batteryID}/tests/${this.state.testID}/items`)
                    resolve()
                })
                .catch(error => { console.log(error) })
        })
        .catch(error => { console.log(error) })
    }

    // sends the current state of itemInfo to the API
    editItemInfo = () => {
        const data = {
            itemID: this.state.itemID,
            allowedResponses: this.state.itemInfo.allowedResponses,
            disabled: this.state.itemInfo.disabled,
            label: this.state.itemInfo.label,
            requiresDuration: this.state.itemInfo.requiresDuration,
            requiresResponse: this.state.itemInfo.requiresResponse,
            requiresScore: this.state.itemInfo.requiresScore,
            scored: this.state.itemInfo.scored,
            timed: this.state.itemInfo.timed,
            timer_countdown: this.state.itemInfo.timer_countdown
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/item/edit', data, config)
        .then(response => {
            this.endEditing()
        })
        .catch(error => { console.log(error) })
    }

    getItemInfo = () => {
        const data = {
            itemID: this.state.itemID
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/item/get', data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            this.setState({
                itemInfo: {
                    allowedResponses: response.data.item.allowedResponses,
                    disabled: response.data.item.disabled,
                    label: response.data.item.label,
                    requiresDuration: response.data.item.requiresDuration,
                    requiresResponse: response.data.item.requiresResponse,
                    requiresScore: response.data.item.requiresScore,
                    scored: response.data.item.scored,
                    timed:  response.data.item.timed,
                    timer_countdown: response.data.item.timer_countdown
                }
            })
        })
        .catch(error => { console.log(error) })
    }

    handleItemInfoChange = (event) => {
        const { name, value } = event.target

        this.setState((state) => ({
            itemInfo: {
                ...state.itemInfo,
                [name]: value
            }
        }))
    }

    handleItemSwitchChange = (checked, name) => {
        this.setState((state) => ({
            itemInfo: {
                ...state.itemInfo,
                [name]: checked
            }
        }))
    }

    renderSwitch = (label, name, itemContainerParams = {}) =>
    {
        return  <div className={styles['item-container']} {...itemContainerParams}>
                    <label className={styles['item-name']}>{label}</label>
                    <Switch
                        disabled={!this.state.isEditing}
                        onChange={(checked) => this.handleItemSwitchChange(checked, name)}
                        readOnly={!this.state.isEditing}
                        checked={this.state.itemInfo[name]}
                    />
                    <hr className={styles['item-underline']}></hr>
                </div>
    }

    showDeleteConfirm = () => {
        let deleteItem = this.deleteItem
        Modal.confirm({
            cancelText: 'Cancel',
            content: 'This can not be undone!',
            okButtonProps: { type: 'danger' },
            okText: 'Delete',
            onCancel() { },
            onOk() {deleteItem()},
            title: 'Are you sure you want to delete this item?'
        })
    }

    allowedResponseDataCreateNewRow = () => {
        let newAllowedResponses = this.state.itemInfo.allowedResponses || []
        newAllowedResponses.push({
            response: '',
            score: 0
        })
        this.setState({
            itemInfo: {
                ...this.state.itemInfo,
                allowedResponses: newAllowedResponses
            }
        })
    }

    allowedResponseDataDidChange = (newData) => {
        this.setState({
            itemInfo: {
                ...this.state.itemInfo,
                allowedResponses: newData
            }
        })
    }

    allowedResponseDataDeleteRow = (rowIndex) => {
        let newData = this.state.itemInfo.allowedResponses
        newData.splice(rowIndex, 1)
        this.setState({
            itemInfo: {
                ...this.state.itemInfo,
                allowedResponses: newData
            }
        })

    }

    render() {
        return (
            <Dashboard>
                <div className={styles['card']}>
                    <div className={styles['header']}>
                        <label className={styles['name']}>
                            {this.state.itemInfo.label ? this.state.itemInfo.label : null}
                        </label>
                        {
                            // cancel button
                            this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={this.endEditing}
                                    >
                                        <RollbackOutlined />
                                        <label className={styles['header-button-label']}>
                                            cancel
                                        </label>
                                    </div>
                                ) : null
                        }
                        {
                            // save button
                            this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={this.editItemInfo}
                                    >
                                        <SaveOutlined />
                                        <label className={styles['header-button-label']}>
                                            Save
                                        </label>
                                    </div>
                                ) : null
                        }
                        {
                            // delete button
                            !this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={() => this.showDeleteConfirm()}
                                    >
                                        <DeleteOutlined />
                                        <label className={styles['header-button-label']}>
                                            Delete
                                </label>
                                    </div>
                                ) : null
                        }
                        {
                            // edit button
                            !this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={() => this.setState({
                                            isEditing: true
                                        })}
                                    >
                                        <EditOutlined />
                                        <label className={styles['header-button-label']}>
                                            Edit
                                            </label>
                                    </div>
                                ) : null
                        }
                    </div>
                    <hr className={styles['horizontal-line']}></hr>
                    <div className={styles['info-container']}>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Label</label>
                            <Input
                                className={styles['item-string']}
                                disabled={!this.state.isEditing}
                                name='label'
                                onChange={this.handleItemInfoChange}
                                onPressEnter={e => e.target.blur()}
                                value={this.state.itemInfo.label}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['break']}></div>
                        {this.renderSwitch('Disabled', 'disabled', {'height-10': 'true', 'width-5': 'true'})}
                        {this.renderSwitch('Scored', 'scored', {'height-10': 'true', 'width-5': 'true'})}
                        {this.renderSwitch('Timed', 'timed', {'height-10': 'true', 'width-5': 'true'})}
                        {this.renderSwitch('Reverse timer', 'timer_countdown', {'height-10': 'true', 'width-15': 'true'})}
                        <div className={styles['break']}></div>
                        {this.renderSwitch('Requires Duration', 'requiresDuration', {'height-10': 'true', 'width-15': 'true'})}
                        {this.renderSwitch('Requires Response', 'requiresResponse', {'height-10': 'true', 'width-15': 'true'})}
                        {this.renderSwitch('Requires Score', 'requiresScore', {'height-10': 'true', 'width-15': 'true'})}
                        <div className={styles['break']}></div>
                        <div className={styles['item-container']} height-40='true' width-50='true'>
                            <label className={styles['item-name']}>Allowed Responses</label>
                            <Table
                                columns={this.state.allowedResponsesColumns}
                                createNewRow={this.allowedResponseDataCreateNewRow}
                                data={this.state.itemInfo.allowedResponses}
                                dataDidChange={this.allowedResponseDataDidChange}
                                deleteRow={this.allowedResponseDataDeleteRow}
                                isEditing={this.state.isEditing}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                    </div>
                </div>
            </Dashboard>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.token
})

export default connect(mapStateToProps, null)(ItemInfoPage)