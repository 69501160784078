/*
    Admin credit managment page
*/


import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../dashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import styles from '../../style/info-card.module.css'
import Table from '../table'
import { Button } from 'antd'


const CreditPage = () =>
{
    const token = useSelector(state => state.token)
    const [email, setEmail] = useState('')
    const [user, setUser] = useState(undefined)
    const [pools, setPools] = useState([])
    const [columns, ] = useState(
    [
        {
            dataKey: 'id',
            title: 'ID',
            type: 'input'
        },
        {
            dataKey: 'name',
            title: 'Name',
            type: 'input'
        },
        {
            dataKey: 'credits',
            title: 'Credits',
            type: 'number'
        },
        {
            dataKey: 'edit',
            title: 'Edit Credits',
            type: 'node'
        }
    ])
    const [data, setData] = useState([])
    const [newAmounts, setNewAmounts] = useState({}) // { poolID: newAmount }


    const findUser = useCallback(async () =>
    {
        const data =
        {
            email
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const result = await axios.post(process.env.REACT_APP_API_URL + '/user/find-by-email', data, config)
        .catch(error => { console.log(error) })

        if (result && result.data) setUser(result.data.user)
        else setUser(undefined)
    }, [email, token])

    const getCreditPools = useCallback(async () =>
    {
        setNewAmounts({})
        if (!user)
        {
            setPools([])
            return
        }

        const data =
        {
            userID: user._id
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const result = await axios.post(process.env.REACT_APP_API_URL + '/credit-pool/admin-dir', data, config)
        .catch(error => { console.log(error) })

        if (result && result.data) setPools(result.data.creditPools)
    }, [token, user])

    const editCredit = useCallback(async (amount, creditPoolID) =>
    {
        const data =
        {
            amount,
            creditPoolID
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/transaction/edit-credit', data, config)
        .catch(error => { console.log(error) })

        setNewAmounts(prevValue => { return {...prevValue, [creditPoolID]: undefined } })
    }, [token])

    const handleInputChange = useCallback((e) =>
    {
        const poolID = e.target.getAttribute('poolid')
        const newAmount = e.target.value

        setNewAmounts(prevState => { return {...prevState, [poolID]: newAmount} })
    }, [])

    const makeEditCreditNode = useCallback((poolID) =>
    {
        return (
            <>
                <input
                    type='number'
                    name={`newAmount-${poolID}`}
                    poolid={poolID}
                    onChange={handleInputChange}
                    value={newAmounts[poolID] || ''}
                />
                <Button
                    onClick={async () =>
                    {
                        await editCredit(newAmounts[poolID], poolID)
                        await getCreditPools()
                    }}
                >
                    Apply
                </Button>
            </>
        )
    }, [editCredit, getCreditPools, handleInputChange, newAmounts])

    const makeData = useCallback(() =>
    {
        let result = []

        pools.forEach(pool =>
        {
            let row = {}
            row.credits = pool.credits
            row.edit = makeEditCreditNode(pool._id)
            row.id = pool._id
            row.name = pool.name
            result.push(row)
        })

        setData(result)
    }, [makeEditCreditNode, pools])


    useEffect(() =>
    {
        getCreditPools()
    }, [getCreditPools])

    useEffect(() =>
    {
        makeData()
    }, [makeData])


    return (
        <Dashboard>
            <div className={styles['card']}>
                <div className={styles['header']}>
                    <label className={styles['name']}>
                        {'Edit Credit'}
                    </label>
                </div>
                <hr className={styles['horizontal-line']}></hr>
                <div className={styles['info-container']}>
                    <div className={styles['item-container']} height-20='true' width-20='true'>
                        <label className={styles['item-name']}>User Email</label>
                        <>
                            <input
                                type='string'
                                onChange={(e) => { setEmail(e.target.value) }}
                            />
                            <Button
                                onClick={async () => { await findUser() }}
                            >
                                Find
                            </Button>
                        </>
                        <hr className={styles['item-underline']}></hr>
                    </div>
                    <div className={styles['break']} />
                    <div className={styles['item-container']} height-70='true' width-90='true'>
                        <label className={styles['item-name']}>Credit Pools</label>
                        <Table
                            columns={columns}
                            data={data}
                            dataDidChange={() => {}}
                        />
                        <hr className={styles['item-underline']}></hr>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}


export default CreditPage