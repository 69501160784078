import React, { Component } from 'react'
import styles from '../style/side-menu.module.css'
import { withRouter } from 'react-router-dom'

/*
props:
- items: an ordered array of items to display in the side menu
[{
    title: string that is shown to the user
    destination: string that is pushed to history on click
    selected: bool
}, ... ]
*/
class SideMenu extends Component
{

    constructor(props)
    {
        super(props)

        this.state =
        {
            isVisible: true,
            items: []
        }
    }

    componentDidMount()
    {
        this.createItems()
    }

    createItems = () =>
    {
        let items = []
        let location = this.props.history.location.pathname
        let batteryID = this.props.match.params.batteryID
        let examineeID = this.props.match.params.examineeID
        let reportID = this.props.match.params.reportID
        let tableID = this.props.match.params.tableID
        let testID = this.props.match.params.testID
        let itemID = this.props.match.params.itemID


        // --- developer ---

        // battery info
        if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/info$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/info`,
                    selected: true
                },
                {
                    title: 'Tests',
                    destination: `/developer/batteries/${batteryID}/tests`,
                    selected: false
                },
                {
                    title: 'Export',
                    destination: `/developer/batteries/${batteryID}/export`,
                    selected: false
                },
                {
                    title: 'Users',
                    destination: `/developer/batteries/${batteryID}/users`,
                    selected: false
                }
            ]
        }

        // battery export
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/export$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/info`,
                    selected: false
                },
                {
                    title: 'Tests',
                    destination: `/developer/batteries/${batteryID}/tests`,
                    selected: false
                },
                {
                    title: 'Export',
                    destination: `/developer/batteries/${batteryID}/export`,
                    selected: true
                },
                {
                    title: 'Users',
                    destination: `/developer/batteries/${batteryID}/users`,
                    selected: false
                }
            ]
        }

        // battery test list
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/info`,
                    selected: false
                },
                {
                    title: 'Tests',
                    destination: `/developer/batteries/${batteryID}/tests`,
                    selected: true
                },
                {
                    title: 'Export',
                    destination: `/developer/batteries/${batteryID}/export`,
                    selected: false
                },
                {
                    title: 'Users',
                    destination: `/developer/batteries/${batteryID}/users`,
                    selected: false
                }
            ]
        }

        // battery users
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/users$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/info`,
                    selected: false
                },
                {
                    title: 'Tests',
                    destination: `/developer/batteries/${batteryID}/tests`,
                    selected: false
                },
                {
                    title: 'Export',
                    destination: `/developer/batteries/${batteryID}/export`,
                    selected: false
                },
                {
                    title: 'Users',
                    destination: `/developer/batteries/${batteryID}/users`,
                    selected: true
                }
            ]
        }

        // item examinee content
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/items\/[0-9a-z]*\/examineeContent$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/info`,
                    selected: false
                },
                {
                    title: 'Examinee Content',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/examineeContent`,
                    selected: true
                },
                {
                    title: 'Examiner Content',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/examinerContent`,
                    selected: false
                },
                {
                    title: 'Tables',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/tables`,
                    selected: false
                }
            ]
        }

        // item examiner content
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/items\/[0-9a-z]*\/examinerContent$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/info`,
                    selected: false
                },
                {
                    title: 'Examinee Content',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/examineeContent`,
                    selected: false
                },
                {
                    title: 'Examiner Content',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/examinerContent`,
                    selected: true
                },
                {
                    title: 'Tables',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/tables`,
                    selected: false
                }
            ]
        }

        // item info
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/items\/[0-9a-z]*\/info$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/info`,
                    selected: true
                },
                {
                    title: 'Examinee Content',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/examineeContent`,
                    selected: false
                },
                {
                    title: 'Examiner Content',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/examinerContent`,
                    selected: false
                },
                {
                    title: 'Tables',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/tables`,
                    selected: false
                }
            ]
        }

        // item tables
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/items\/[0-9a-z]*\/tables$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/info`,
                    selected: false
                },
                {
                    title: 'Examinee Content',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/examineeContent`,
                    selected: false
                },
                {
                    title: 'Examiner Content',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/examinerContent`,
                    selected: false
                },
                {
                    title: 'Tables',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/tables`,
                    selected: true
                }
            ]
        }

        // item table info
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/items\/[0-9a-z]*\/tables\/[0-9a-z]*\/info$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items/${itemID}/tables/${tableID}/info`,
                    selected: true
                }
            ]
        }

        // test info
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/info$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/info`,
                    selected: true
                },
                {
                    title: 'Items',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items`,
                    selected: false
                },
                {
                    title: 'Reports',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports`,
                    selected: false
                }
            ]
        }

        // test item list
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/items$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/info`,
                    selected: false
                },
                {
                    title: 'Items',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items`,
                    selected: true
                },
                {
                    title: 'Reports',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports`,
                    selected: false
                }
            ]
        }

        // test item list
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/reports$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/info`,
                    selected: false
                },
                {
                    title: 'Items',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/items`,
                    selected: false
                },
                {
                    title: 'Reports',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports`,
                    selected: true
                }
            ]
        }

        // test report info
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/reports\/[0-9a-z]*\/info$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/info`,
                    selected: true
                },
                {
                    title: 'Content',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/content`,
                    selected: false
                },
                {
                    title: 'Tables',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/tables`,
                    selected: false
                }
            ]
        }

        // test report content
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/reports\/[0-9a-z]*\/content$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/info`,
                    selected: false
                },
                {
                    title: 'Content',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/content`,
                    selected: true
                },
                {
                    title: 'Tables',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/tables`,
                    selected: false
                }
            ]
        }

        // test report tables
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/reports\/[0-9a-z]*\/tables$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/info`,
                    selected: false
                },
                {
                    title: 'Content',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/content`,
                    selected: false
                },
                {
                    title: 'Tables',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/tables`,
                    selected: true
                }
            ]
        }

        // test report table info
        else if (location.match(/^\/developer\/batteries\/[0-9a-z]*\/tests\/[0-9a-z]*\/reports\/[0-9a-z]*\/tables\/[0-9a-z]*\/info$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/developer/batteries/${batteryID}/tests/${testID}/reports/${reportID}/tables/${tableID}/info`,
                    selected: true
                }
            ]
        }

        


        // --- examiner ---

        // battery info
        else if (location.match(/^\/examiner\/batteries\/[0-9a-z]*\/info$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/examiner/batteries/${batteryID}/info`,
                    selected: true
                },
                // {
                //     title: 'Tests',
                //     destination: `/examiner/batteries/${batteryID}/tests`,
                //     selected: false
                // },
                {
                    title: 'Export',
                    destination: `/examiner/batteries/${batteryID}/export`,
                    selected: false
                },
                {
                    title: 'Session Invite',
                    destination: `/examiner/batteries/${batteryID}/sessionInvite`,
                    selected: false
                }
            ]
        }

        // // battery test list
        // else if (location.match(/^\/examiner\/batteries\/[0-9a-z]*\/tests$/)) {
        //     items = [
        //         {
        //             title: 'Info',
        //             destination: `/examiner/batteries/${batteryID}/info`,
        //             selected: false
        //         },
        //         {
        //             title: 'Tests',
        //             destination: `/examiner/batteries/${batteryID}/tests`,
        //             selected: true
        //         },
        //         {
        //             title: 'Export',
        //             destination: `/examiner/batteries/${batteryID}/export`,
        //             selected: false
        //         },
        //         {
        //             title: 'Session Invite',
        //             destination: `/examiner/batteries/${batteryID}/sessionInvite`,
        //             selected: false
        //         }
        //     ]
        // }

        // battery export
        else if (location.match(/^\/examiner\/batteries\/[0-9a-z]*\/export$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/examiner/batteries/${batteryID}/info`,
                    selected: false
                },
                // {
                //     title: 'Tests',
                //     destination: `/examiner/batteries/${batteryID}/tests`,
                //     selected: false
                // },
                {
                    title: 'Export',
                    destination: `/examiner/batteries/${batteryID}/export`,
                    selected: true
                },
                {
                    title: 'Session Invite',
                    destination: `/examiner/batteries/${batteryID}/sessionInvite`,
                    selected: false
                }
            ]
        }

        // battery session invite
        else if (location.match(/^\/examiner\/batteries\/[0-9a-z]*\/sessionInvite$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/examiner/batteries/${batteryID}/info`,
                    selected: false
                },
                // {
                //     title: 'Tests',
                //     destination: `/examiner/batteries/${batteryID}/tests`,
                //     selected: false
                // },
                {
                    title: 'Export',
                    destination: `/examiner/batteries/${batteryID}/export`,
                    selected: false
                },
                {
                    title: 'Session Invite',
                    destination: `/examiner/batteries/${batteryID}/sessionInvite`,
                    selected: true
                }
            ]
        }

        // examinee info
        else if (location.match(/^\/examiner\/examinees\/[0-9a-z]*\/info$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/examiner/examinees/${examineeID}/info`,
                    selected: true
                },
                {
                    title: 'Test History',
                    destination: `/examiner/examinees/${examineeID}/testHistory`,
                    selected: false
                }
            ]
        }

        // examinee test history
        else if (location.match(/^\/examiner\/examinees\/[0-9a-z]*\/testHistory$/)) {
            items = [
                {
                    title: 'Info',
                    destination: `/examiner/examinees/${examineeID}/info`,
                    selected: false
                },
                {
                    title: 'Test History',
                    destination: `/examiner/examinees/${examineeID}/testHistory`,
                    selected: true
                }
            ]
        }


        // no items
        else
        {
            this.setState(
            {
                isVisible: false
            })
        }

        this.setState(
        {
            items: items
        })
    }

    render()
    {
        if (!this.state.isVisible) return null

        return (
            <div className={styles['container']}>
                {
                    this.state.items.map((item) =>
                    {
                        return (
                            <div
                                className={`${styles['item']} ${item.selected ? styles['selected'] : null}`}
                                key={`item-${item.title}`}
                                onClick={() => { this.props.history.push(item.destination) }}>
                                <div className={`${styles['vertical-bar']} ${item.selected ? styles['selected'] : null}`} />
                                <label className={`${styles['title']} ${item.selected ? styles['selected'] : null}`} >
                                    {item.title}
                                </label>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default withRouter(SideMenu)