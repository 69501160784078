/*
    Developer's battery list page
*/

import React, { Component } from 'react'
import Dashboard from '../dashboard'
import BigList from '../big-list'
import axios from 'axios'
import { connect } from 'react-redux'

class BatteryListPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            batteries: [],
            listItems: []
        }
    }

    async componentDidMount() {
        await this.getDirectory()
        this.createBatteryListItems()
    }

    createBatteryListItems = () => {
        let items = this.state.batteries.map((battery) => {
            return {
                destination: `/developer/batteries/${battery._id}/info`,
                filterValue: `${battery.name || ''}`.toLowerCase(),
                title: `${battery.name || ''}`,
                uniqueKey: battery._id
            }
        })

        this.setState({
            listItems: items
        })
    }

    createNewBattery = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/battery/create', {}, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            // return destination to BigList
            return `/developer/batteries/${response.data.batteryID}/info`
        })
        .catch(error => { console.log(error) })
    }

    getDirectory() {
        return axios.get(process.env.REACT_APP_API_URL + '/battery/directory',
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        })
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            this.setState({
                batteries: response.data.batteries
            })
        })
        .catch(error => { console.log(error) })
    }

    render() {
        return (
            <Dashboard>
                <BigList
                    items={this.state.listItems}
                    onClickCreateNew={this.createNewBattery}
                    title='Batteries'
                />
            </Dashboard>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.token
})

export default connect(mapStateToProps, null)(BatteryListPage)