/*
    Examinee test page
*/

import { useCallback, useEffect } from 'react'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'

const StartTestPage = () =>
{
    const { examineeID, examinerID, recordLabel, testID } = useParams()
    const history = useHistory()

    const generateSession = useCallback(async () =>
    {
        // TODO: validate input
        
        const data =
        {
            examineeID,
            examinerID,
            recordLabel,
            testID
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json'
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/session/generate', data, config)
        .then(response =>
        {
            if (!response) throw new Error(`Could not start the test: ${examineeID} ${examinerID} ${recordLabel} ${testID}`)

            let sessionID = response.data.sessionID
            history.push(`/examinee/test/${sessionID}`)
        })
    }, [history, examineeID, examinerID, recordLabel, testID])

    useEffect(() =>
    {
        generateSession()
    }, [generateSession])

    return 'One moment please...'
}


export default StartTestPage