import { Editor, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

/*
    Custom Editor for item content
    add new functionality here
*/

// TODO: make a single 'toggleElementProperty' instead of the mark/block/inline variants? since i have type on all elements.
// TODO: options for 'at' and 'void'  etc
// TODO: can i override functions  here instead of having the forItemContent() plugin?

const ItemContentEditor =
{
    ...Editor,
    ...ReactEditor,

    deleteColumn (editor)
    {
        const [matchCell] = ItemContentEditor.nodes(editor,
        {
            match: node => node.type === 'tableCell',
            mode: 'lowest'
        })
        if (!matchCell) return

        const [matchTable] = ItemContentEditor.nodes(editor,
        {
            match: node => node.type === 'table',
            mode: 'lowest'
        })

        const colIndex = matchCell[1][matchCell[1].length-1]
        
        matchTable[0].children.forEach((row, rowIndex) =>
        {
            if (row.type === 'tableRow')
            {
                const cellPath = [...matchTable[1], rowIndex, colIndex]
                Transforms.removeNodes(editor, {at: cellPath})
            }
        })
    },

    deleteRow (editor)
    {
        const [matchRow] = ItemContentEditor.nodes(editor,
        {
            match: node => node.type === 'tableRow',
            mode: 'lowest'
        })
        if (!matchRow) return

        Transforms.removeNodes(editor, {at: matchRow[1]})
    },

    // TODO: this (and below) is a bandaid becuase Editor.node() was not working in the past. It looks like it may have been fixed. Check it out when convenient.
    getNode (editor, type, at = undefined)
    {
        const [match] = ItemContentEditor.nodes(editor, {
            at: at || undefined,
            match: node => node.type === type
        })
        return match ? match[0] : null
    },

    getNodePath (editor, type)
    {
        const [match] = ItemContentEditor.nodes(editor, {
            match: node => node.type === type
        })
        return match ? match[1] : null
    },

    getNodeProperty (editor, type, property, at = undefined, mode = 'lowest')
    {
        const [match] = ItemContentEditor.nodes(editor, {
            at: at || undefined,
            match: node => node.type === type,
            mode
        })

        return match ? match[0][property] : null
    },

    insertAudio (editor, url)
    {
        const audio =
        {
            children: [{text: ''}],
            type: 'audio',
            url,
            width: 20
        }
        Transforms.insertNodes(editor, audio)
    },

    insertImage (editor, url)
    {
        const image =
        {
            children: [{text: ''}],
            type: 'image',
            url,
            width: 20
        }
        Transforms.insertNodes(editor, image)
    },

    insertColumn (editor, right = false)
    {
        const [matchCell] = ItemContentEditor.nodes(editor,
        {
            match: node => node.type === 'tableCell',
            mode: 'lowest'
        })
        if (!matchCell) return

        let colIndex = matchCell[1][matchCell[1].length-1]
        if (right) colIndex = colIndex+1

        const [matchTable] = ItemContentEditor.nodes(editor,
        {
            match: node => node.type === 'table',
            mode: 'lowest'
        })
        
        const table = matchTable[0]
        const tablePath = [...matchTable[1]]
        table.children.forEach((row, rowIndex) =>
        {
            if (row.type === 'tableRow')
            {
                const newCellPath = [...tablePath, rowIndex, colIndex]
                const newCell =
                {
                    type: 'tableCell',
                    children: [{text: ''}]
                }

                Transforms.insertNodes(editor, newCell, {at: newCellPath})
            }
        })
    },

    insertRow (editor, below = false)
    {
        const [match] = ItemContentEditor.nodes(editor,
        {
            match: node => node.type === 'tableRow',
            mode: 'lowest'
        })
        if (!match) return
        const colCount = match[0].children[1].colCount
        const newRow =
        {
            type: 'tableRow',
            children: []
        }

        for (let i=0; i<colCount; i++)
        {
            newRow.children.push(
            {
                type: 'tableCell',
                children: [{text: ''}]
            })
        }
        let position = [...match[1]]
        if (below)
        {
            position[position.length-1] = position[position.length-1]+1
        }
        Transforms.insertNodes(editor, newRow, {at: position})
    },

    insertTable (editor)
    {
        const table =
        {
            type: 'table',
            border: '0.1vmin solid',
            width: 30,
            children:
            [
                {
                    type: 'tableRow',
                    children:
                    [
                        {
                            type: 'tableCell',
                            children: [{text: ''}]
                        },
                        {
                            type: 'tableCell',
                            children: [{text: ''}]
                        }
                    ]
                },
                {
                    type: 'tableRow',
                    children:
                    [
                        {
                            type: 'tableCell',
                            children: [{text: ''}]
                        },
                        {
                            type: 'tableCell',
                            children: [{text: ''}]
                        }
                    ]
                }
            ]
        }
        Transforms.insertNodes(editor, table)
    },

    insertVideo (editor, url)
    {
        const video =
        {
            children: [{text: ''}],
            type: 'video',
            url,
            width: 20
        }
        Transforms.insertNodes(editor, video)
    },

    isBlockActive (editor, property, value, at = undefined)
    {
        const [match] = ItemContentEditor.nodes(editor, {
            at: at || undefined,
            match: node => node[property] === value && ItemContentEditor.isBlock(editor, node)
          })

        return !!match
    },

    isInlineActive (editor, property, value, at = undefined)
    {
        const [match] = ItemContentEditor.nodes(editor, {
            at: at || undefined,
            match: node => node[property] === value && ItemContentEditor.isInline(editor, node)
          })

        return !!match
    },

    isMarkActive (editor, type, value = true)
    {
        const marks = ItemContentEditor.marks(editor)
        return marks ? marks[type] === value : false
    },

    isPropertyActive (editor, property, value, mode = 'lowest')
    {
        if (!editor.selection) return false

        const [match] = ItemContentEditor.nodes(editor,
        {
            match: node => node[property] && node[property] === value,
            mode: mode
        })

        return !!match
    },

    normalizeAll (editor, type)
    {
        const [...match] = ItemContentEditor.nodes(editor, {
            at: [],
            match: node => node.type === type
        })

        for (const entry of match)
        {
            editor.normalizeNode(entry)
        }
    },

    scrollToTop (editor)
    {
        if (editor.children.length > 0)
        {
            const firstDomNode = ItemContentEditor.toDOMNode(editor, editor.children[0])
            firstDomNode.scrollIntoView()
        }
    },

    setNodeProperties (editor, type, properties, at = undefined)
    {
        Transforms.setNodes
        (
            editor,
            properties,
            {
                at: at || undefined,
                match: node => node.type === type
            }
        )
    },

    toggleBlock (editor, property, value)
    {
        const isActive = ItemContentEditor.isBlockActive(editor, property, value)
        Transforms.setNodes
        (
            editor,
            { [property]: isActive ? null : value },
            { match: node => ItemContentEditor.isBlock(editor, node) }
        )
    },

    toggleInline (editor, property, value = true)
    {
        const isActive = ItemContentEditor.isInlineActive(editor, property, value)
        Transforms.setNodes
        (
            editor,
            { [property]: isActive ? null : value },
            { match: node => ItemContentEditor.isInline(editor, node) }
        )
    },

    toggleMark (editor, type, value = true)
    {
        const isActive = ItemContentEditor.isMarkActive(editor, type, value)

        if (isActive)
        {
            ItemContentEditor.removeMark(editor, type)
        }else
        {
            ItemContentEditor.addMark(editor, type, value)
        }
    },

    unsetNodeProperty (editor, key, at = undefined)
    {
        Transforms.unsetNodes
        (
            editor,
            key,
            { at: at || undefined }
        )
    },

    unWrap (editor, type, at = undefined)
    {
        Transforms.unwrapNodes(editor, {
            at: at || undefined,
            match: node => node.type === type,
            split: true,
            voids: true
        })
    },

    wrap (editor, element, at = undefined)
    {
        Transforms.wrapNodes(editor, element, {
            at: at || undefined,
            split: true,
            voids: true
        })
    },
}

export default ItemContentEditor