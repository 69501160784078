import React, { Component }               from 'react'
import { Router, Switch, Route }          from 'react-router-dom'
import history                            from './history'
import LandingPage                        from './components/landing-page'
import AdminCreditPage                    from './components/admin/credit-page'
import DeveloperBatteryListPage           from './components/developer/battery-list-page'
import DeveloperBatteryInfoPage           from './components/developer/battery-info-page'
import DeveloperBatteryExportPage         from './components/developer/battery-export-page'
import DeveloperExamineeContentEditorPage from './components/developer/examinee-content-editor-page'
import DeveloperExaminerContentEditorPage from './components/developer/examiner-content-editor-page'
import DeveloperItemListPage              from './components/developer/item-list-page'
import DeveloperItemInfoPage              from './components/developer/item-info-page'
import DeveloperTestListPage              from './components/developer/test-list-page'
import DeveloperTestInfoPage              from './components/developer/test-info-page'
import DeveloperUsersPage                 from './components/developer/users-page'
import ExamineeTestHistoryPage            from './components/examiner/examinee-test-history-page'
import ExamineeListPage                   from './components/examiner/examinee-list-page'
import ExamineeStartTestPage              from './components/examinee/start-test-page'
import ExamineeTestPage                   from './components/examinee/test-page'
import ExaminerBatteryExportPage          from './components/examiner/battery-export-page'
import ExaminerExamineeInfoPage           from './components/examiner/examinee-info-page'
import ExaminerSessionListPage            from './components/examiner/session-list-page'
import ExaminerSessionInvitePage          from './components/examiner/session-invite-page'
import ExaminerTestPage                   from './components/examiner/test-page'
import ExaminerBatteryListPage            from './components/examiner/battery-list-page'
import ReportEditorPage                   from './components/developer/report-editor-page'
import ReportInfoPage                     from './components/report-info-page'
import ReportListPage                     from './components/report-list-page'
import ReportPage                         from './components/report-page'
import TableInfoPage                      from './components/developer/table-info-page'
import TableListPage                      from './components/developer/table-list-page'
import ExaminerBatteryInfoPage            from './components/examiner/battery-info-page'
import UserCreditPage                     from './components/user/credit-page'
import UserSettingsPage                   from './components/user/settings-page'
import GoogleFontLoader                   from 'react-google-font-loader'
import Fonts                              from './config/fonts'
import 'antd/dist/antd.css'
import './style/global.css'
import './config/register'


class App extends Component {
  render() {
    return (
      <>
      <Router history={history}>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/admin/credit' component={AdminCreditPage} />
          <Route exact path='/developer/batteries' component={DeveloperBatteryListPage} />
          <Route exact path='/developer/batteries/:batteryID/info' component={DeveloperBatteryInfoPage} />
          <Route exact path='/developer/batteries/:batteryID/export' component={DeveloperBatteryExportPage} />
          <Route exact path='/developer/batteries/:batteryID/tests' component={DeveloperTestListPage} />
          <Route exact path='/developer/batteries/:batteryID/users' component={DeveloperUsersPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/info' component={DeveloperTestInfoPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/items' component={DeveloperItemListPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/items/:itemID/info' component={DeveloperItemInfoPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/items/:itemID/examineeContent' component={DeveloperExamineeContentEditorPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/items/:itemID/examinerContent' component={DeveloperExaminerContentEditorPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/items/:itemID/tables' component={TableListPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/items/:itemID/tables/:tableID/info' component={TableInfoPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/reports' component={ReportListPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/reports/:reportID/info' component={ReportInfoPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/reports/:reportID/tables' component={TableListPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/reports/:reportID/content' component={ReportEditorPage} />
          <Route exact path='/developer/batteries/:batteryID/tests/:testID/reports/:reportID/tables/:tableID/info' component={TableInfoPage} />
          <Route exact path='/examinee/startTest/:examinerID/:recordLabel/:testID/:examineeID?' component={ExamineeStartTestPage} />
          <Route exact path='/examinee/test/:sessionID' component={ExamineeTestPage} />
          <Route exact path='/examiner/batteries' component={ExaminerBatteryListPage} />
          <Route exact path='/examiner/batteries/:batteryID/info' component={ExaminerBatteryInfoPage} />
          <Route exact path='/examiner/batteries/:batteryID/export' component={ExaminerBatteryExportPage} />
          <Route exact path='/examiner/examinees' component={ExamineeListPage} />
          <Route exact path='/examiner/examinees/:examineeID/info' component={ExaminerExamineeInfoPage} />
          <Route exact path='/examiner/examinees/:examineeID/testHistory' component={ExamineeTestHistoryPage} />
          <Route exact path='/examiner/sessions' component={ExaminerSessionListPage} />
          <Route exact path='/examiner/batteries/:batteryID/sessionInvite' component={ExaminerSessionInvitePage} />
          <Route exact path='/examiner/test/:sessionID' component={ExaminerTestPage} />
          <Route exact path='/user/credits' component={UserCreditPage} />
          <Route exact path='/user/settings' component={UserSettingsPage} />
          <Route exact path='/report/:recordID/:reportID' component={ReportPage} />
        </Switch>
      </Router>
        <GoogleFontLoader
          fonts={Fonts}
        />
      </>
    )
  }
}

export default App
