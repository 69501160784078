/*
    Developer's test info page
*/

import React, { Component } from 'react'
import Dashboard from '../dashboard'
import styles from '../../style/info-card.module.css'
import { connect } from 'react-redux'
import axios from 'axios'
import { Input, Modal } from 'antd'
import { DeleteOutlined, EditOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons'

class TestInfoPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            batteryID: props.match.params.batteryID,
            testID: props.match.params.testID,
            testInfo: {
                name: ''
            },

            // flags
            isEditing: false
        }
    }

    componentDidMount() {
        this.getTestInfo()
    }

    endEditing = () => {
        this.setState({
            isEditing: false
        })

        this.getTestInfo()
    }

    deleteTest = () => {
        return new Promise((resolve, reject) => {
            axios.delete(process.env.REACT_APP_API_URL + '/test/delete',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Token ' + this.props.token
                    },
                    data: {
                        testID: this.state.testID
                    }
                })
                .then(response => {
                    this.props.history.replace(`/developer/batteries/${this.state.batteryID}/tests`)
                    resolve()
                })
                .catch(error => { console.log(error) })
        })
        .catch(error => { console.log(error) })
    }

    getTestInfo = () => {
        const data = {
            testID: this.state.testID
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/test/get', data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            this.setState({
                testInfo: {
                    name: response.data.test.name
                }
            })
        })
        .catch(error => { console.log(error) })
    }

    handleTestInfoChange = (event) => {
        const { name, value } = event.target

        this.setState((state) => ({
            testInfo: {
                ...state.testInfo,
                [name]: value
            }
        }))
    }

    // sends the current state of testInfo to the API
    editTestInfo = () => {
        const data = {
            testID: this.state.testID,
            name: this.state.testInfo.name
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/test/edit', data, config)
        .then(response => {
            this.endEditing()
        })
        .catch(error => { console.log(error) })
    }

    showDeleteConfirm = () => {
        let deleteTest = this.deleteTest
        Modal.confirm({
            cancelText: 'Cancel',
            content: 'This can not be undone!',
            okButtonProps: { type: 'danger' },
            okText: 'Delete',
            onCancel() { },
            onOk() {deleteTest()},
            title: 'Are you sure you want to delete this test?'
        })
    }

    render() {
        return (
            <Dashboard>
                <div className={styles['card']}>
                    <div className={styles['header']}>
                        <label className={styles['name']}>
                            {this.state.testInfo.name ? this.state.testInfo.name : null}
                        </label>
                        {
                            // cancel button
                            this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={this.endEditing}
                                    >
                                        <RollbackOutlined />
                                        <label className={styles['header-button-label']}>
                                            cancel
                                        </label>
                                    </div>
                                ) : null
                        }
                        {
                            // save button
                            this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={this.editTestInfo}
                                    >
                                        <SaveOutlined />
                                        <label className={styles['header-button-label']}>
                                            Save
                                        </label>
                                    </div>
                                ) : null
                        }
                        {
                            !this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={() => this.showDeleteConfirm()}
                                    >
                                        <DeleteOutlined />
                                        <label className={styles['header-button-label']}>
                                            Delete
                                </label>
                                    </div>
                                ) : null
                        }
                        {
                            !this.state.isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={() => this.setState({
                                            isEditing: true
                                        })}
                                    >
                                        <EditOutlined />
                                        <label className={styles['header-button-label']}>
                                            Edit
                                            </label>
                                    </div>
                                ) : null
                        }
                    </div>
                    <hr className={styles['horizontal-line']}></hr>
                    <div className={styles['info-container']}>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Name</label>
                            <Input
                                className={styles['item-string']}
                                name='name'
                                onChange={this.handleTestInfoChange}
                                onPressEnter={(e) => e.target.blur()}
                                readOnly={!this.state.isEditing}
                                value={this.state.testInfo.name}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                    </div>
                </div>
            </Dashboard>
        )
    }

}

const mapStateToProps = (state) => ({
    token: state.token
})

export default connect(mapStateToProps, null)(TestInfoPage)