/*
    a breadcrumb navigation component for the dashboard
*/

import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import styles from '../style/breadcrumb.module.css'
import { CaretRightOutlined } from '@ant-design/icons'

class Breadcrumb extends Component {

    constructor(props) {
        super(props)

        this.state = {
            linkData: [] // { destination: String, label: String }
        }
    }

    componentDidMount() {
        this.makeLinkData()
    }

    makeLinkData = () => {
        let pathname = this.props.history.location.pathname
        let crumbs = pathname.split('/')
        
        // remove empty crumb to left of first /
        crumbs.shift()

        if (crumbs[0] !== 'developer' && crumbs[0] !== 'examiner') {
            // do not make a breadcrumb trail if not in the developer or examiner portal
            return
        }

        let linkData = []
        let previousCrumb = null
        let currentDestination = ''
        crumbs.forEach(crumb => {

            // update current destination
            currentDestination += '/' + crumb

            switch(crumb) {
                case 'batteries':
                    linkData.push(
                        {
                            destination: currentDestination,
                            label: 'Battery List'
                        }
                    )
                    break
                case 'examinees':
                    linkData.push(
                        {
                            destination: currentDestination,
                            label: 'Examinee List'
                        }
                    )
                    break
                case 'items':
                    linkData.push(
                        {
                            destination: currentDestination,
                            label: 'Item List'
                        }
                    )
                    break
                case 'reports':
                    linkData.push(
                        {
                            destination: currentDestination,
                            label: 'Report List'
                        }
                    )
                    break
                case 'tables':
                    linkData.push(
                        {
                            destination: currentDestination,
                            label: 'Table List'
                        }
                    )
                    break
                case 'tests':
                    linkData.push(
                        {
                            destination: currentDestination,
                            label: 'Test List'
                        }
                    )
                    break
                default:
                    break
            }

            switch(previousCrumb) {
                case 'batteries':
                    linkData.push(
                        {
                            destination: currentDestination + '/info',
                            label: 'Battery'
                        }
                    )
                    break
                case 'examinees':
                    linkData.push(
                        {
                            destination: currentDestination + '/info',
                            label: 'Examinee'
                        }
                    )
                    break
                case 'items':
                    linkData.push(
                        {
                            destination: currentDestination + '/info',
                            label: 'Item'
                        }
                    )
                    break
                case 'reports':
                linkData.push(
                    {
                        destination: currentDestination + '/info',
                        label: 'Report'
                    }
                )
                break
                case 'tables':
                    linkData.push(
                        {
                            destination: currentDestination + '/info',
                            label: 'Table'
                        }
                    )
                    break
                case 'tests':
                    linkData.push(
                        {
                            destination: currentDestination + '/info',
                            label: 'Test'
                        }
                    )
                    break
                default:
                    break
            }

            previousCrumb = crumb
        })

        // remove the last link data
        linkData.pop()


        this.setState({
            linkData: linkData
        })
    }

    render() {
        return (
            <div className={styles['container']}>
                {
                   this.state.linkData.map((linkData, i) => {
                       return (
                           <div
                                className={styles['crumb__container']}
                                key={'crumb container ' + i}
                            >
                               <Link
                                   className={styles['crumb__link']}
                                   key={'crumb link ' + i}
                                   to={linkData.destination}
                               >
                                   <label className={styles['crumb__label']}>
                                       {linkData.label}
                                   </label>
                               </Link>
                               <CaretRightOutlined
                                    className={styles['crumb__icon']}
                                    key={'crumb icon ' + i}
                                    style={{ color: '#35405280', fontSize: '1.25vmin' }}
                               />
                           </div>
                       )
                   })
                }
            </div>
        )
    }
}

export default withRouter(Breadcrumb)