/*
    Developer battery export page
*/

import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../dashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from '../../style/info-card.module.css'
import fileDownload from 'js-file-download'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

const BatteryExportPage = () =>
{
    const { batteryID } = useParams()
    const token = useSelector(state => state.token)
    const [batteryInfo, setBatteryInfo] = useState({})
    const [loading_batteryRecords, setLoading_batteryRecords] = useState(false)
    

    const getBatteryInfo = useCallback(async () =>
    {
        if (!token) return

        const data =
        {
            batteryID: batteryID
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/battery/get', data, config)
        .catch(error => { console.log(error) })

        setBatteryInfo(
        {
            name: response.data.battery.name
        })
    }, [batteryID, token])

    const download_batteryRecords = useCallback(async () =>
    {
        setLoading_batteryRecords(true)

        const data =
        {
            batteryID
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        // TODO: this is a temp hardcode to use a custom alt endpoint for just RDRA. remove this when a generic system is implemented.
        const endpoint = batteryID === '5f91ec381138890004f8f2dd' ? 'rdra_records' : 'battery_records'
        
        const response = await axios.post(process.env.REACT_APP_API_URL + `/export/${endpoint}`, data, config)
        .catch(error =>
        {
            setLoading_batteryRecords(false)
            console.log(error)
        })

        if (!response) throw new Error(`Failed to load '/export/battery_records'.`)

        fileDownload(response.data, `${batteryInfo.name}-records.csv`)

        setLoading_batteryRecords(false)
    }, [batteryID, batteryInfo, token])


    useEffect(() =>
    {
        getBatteryInfo()
    }, [getBatteryInfo])


    return (
        <Dashboard>
                <div className={styles['card']}>
                    <div className={styles['header']}>
                        <label className={styles['name']}>
                            {batteryInfo.name ? batteryInfo.name : null}
                        </label>
                    </div>
                    <hr className={styles['horizontal-line']}></hr>
                    <div className={styles['info-container']}>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Records</label>
                            <Button
                                loading={loading_batteryRecords}
                                onClick={download_batteryRecords}
                                icon={<DownloadOutlined />}
                            >Download
                            </Button>
                            <hr className={styles['item-underline']}></hr>
                        </div>
                    </div>
                </div>
            </Dashboard>
    )
}

export default BatteryExportPage