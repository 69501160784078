/*
    Developer's item list page
*/

import React, { Component } from 'react'
import Dashboard from '../dashboard'
import { connect } from 'react-redux'
import BigList from '../big-list'
import axios from 'axios'

class ItemListPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            batteryID: props.match.params.batteryID,
            testID: props.match.params.testID,
            listItems: [],
            items: []
        }
    }

    async componentDidMount() {
        await this.getItemDirectory()
        this.createItemListItems()
    }

    createNewItem = () => {
        const data = {
            testID: this.state.testID
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/item/create', data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            // return destination to BigList
            return `/developer/batteries/${this.state.batteryID}/tests/${this.state.testID}/items/${response.data.itemID}/info`
        })
        .catch(error => { console.log(error) })
    }

    createItemListItems = () => {
        let items = this.state.items.map((item) => {
            return {
                destination: `/developer/batteries/${this.state.batteryID}/tests/${this.state.testID}/items/${item._id}/info`,
                filterValue: `${item.label || ''}`.toLowerCase(),
                subtitle: `${item.disabled ? 'disabled  ' : ''}${item.scored ? 'scored  ' : ''}${item.timed ? 'timed' : ''}`,
                title: `${item.label || ''}`,
                uniqueKey: item._id
            }
        })

        this.setState({
            listItems: items
        })
    }

    didReorderItem = (fromIndex, toIndex) => {
        // reorder local copy of item list
        let newItems = this.state.items
        let movedItem = newItems.splice(fromIndex, 1).pop()
        newItems.splice(toIndex, 0, movedItem)
        this.setState({
            items: newItems
        }, () => this.createItemListItems())

        // send changes to api
        const data = {
            testID: this.state.testID,
            fromIndex: fromIndex,
            toIndex: toIndex
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/test/moveItem', data, config)
        .then(response => {
            this.getItemDirectory()
            .then(() => {
                this.createItemListItems()
            })
        })
        .catch(error => { console.log(error) })
    }

    getItemDirectory() {
        const data = {
            testID: this.state.testID
        }
      
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + this.props.token
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/test/itemDirectory', data, config)
        .then(response => {
            // check to make sure a data object was returned
            if (response.data == null) throw new Error('no response data')

            this.setState({
                items: response.data.items
            })
        })
        .catch(error => { console.log(error) })
    }

    render() {
        return (
            <Dashboard>
                <BigList
                    didReorder={this.didReorderItem}
                    items={this.state.listItems}
                    onClickCreateNew={this.createNewItem}
                    reorderable
                    title='Items'
                />
            </Dashboard>
        )
    }
}

const mapStateToProps = (state) => ({
    token: state.token
})

export default connect(mapStateToProps, null)(ItemListPage)