/*
    Examiner's session invite page
*/

import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../dashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from '../../style/info-card.module.css'
import { Input, Select } from 'antd'


const SessionInvitePage = () =>
{
    const { batteryID } = useParams()
    const token = useSelector(state => state.token)

    const [examinees, setExaminees] = useState([])
    // const [isSendingEmail, setIsSendingEmail] = useState(false)
    const [selectedExamineeID, setSelectedExamineeID] = useState(null)
    const [selectedTestID, setSelectedTestID] = useState(null)
    const [selectedLabel, setSelectedLabel] = useState('')
    // const [sessionInviteEmails, setSessionInviteEmails] = useState('')
    const [sessionInviteURL, setSessionInviteURL] = useState('')
    const [tests, setTests] = useState([])
    const [userInfo, setUserInfo] = useState({})

    const createSessionInviteURL = useCallback(() =>
    {
        let url = `${window.location.origin}/examinee/startTest/${userInfo._id}/${selectedLabel}/${selectedTestID}${selectedExamineeID ? `/${selectedExamineeID}` : ''}`
        if (!selectedLabel || !selectedTestID) url = ''
        setSessionInviteURL(url)
    }, [selectedExamineeID, selectedLabel, selectedTestID, userInfo])

    const getExaminees = useCallback(async () =>
    {
        let response = await axios.get(process.env.REACT_APP_API_URL + '/examinee/directory', 
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        })
        .catch(error => { console.log(error) })

        if (response.data == null) throw new Error('Could not get examinee directory.')
        setExaminees(response.data.examinees)
    }, [token])

    const getTests = useCallback(async () =>
    {
        const data =
        {
            batteryID
        }
                
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/battery/testDirectory', data, config)
        .catch(error => { console.log(error) })

        // check to make sure a data object was returned
        if (response.data == null) throw new Error(`could not get test directory for battery ${batteryID}`)

        setTests(response.data.tests)
    }, [batteryID, token])

    const getUserInfo = useCallback(async () =>
    {    
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/user/info', {}, config)
        .catch(error => { console.log(error) })

        setUserInfo(response.data)
    }, [token])

    const renderExamineeSelect = useCallback(() =>
    {
        return <Select
                    allowClear
                    className={styles['item-select']}
                    filterOption
                    onChange={setSelectedExamineeID}
                    optionFilterProp='filtervalue'
                    placeholder='Examinee'
                    showSearch
                >
                    {
                        Object.values(examinees).map(examinee =>
                        {
                            return (
                                <Select.Option
                                    filtervalue={`${examinee.firstName} ${examinee.lastName} ${examinee.label}`}
                                    key={examinee._id}
                                >
                                    {`${examinee.firstName} ${examinee.lastName}`}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
    }, [examinees])

    const renderTestSelect = useCallback(() =>
    {
        return <Select
                    allowClear
                    className={styles['item-select']}
                    filterOption
                    onChange={setSelectedTestID}
                    optionFilterProp='filtervalue'
                    placeholder='Test'
                    showSearch
                >
                    {
                        Object.values(tests).map(test =>
                        {
                            return (
                                <Select.Option
                                    filtervalue={`${test.name}`}
                                    key={test._id}
                                >
                                    {`${test.name}`}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
    }, [tests])

    // const sendSessionInvites = useCallback(async () =>
    // {
    //     setIsSendingEmail(true)

    //     const data =
    //     {
    //         text: 'You are being asked to complete the Reynold’s Dyslexia Risk Assessment on one or more children. Click on the URL link below to access the form. Please be sure that the child’s age matches the age shown on the form that opens. You can use the link below to complete multiple forms. ' + sessionInviteURL,
    //         html: `<p>You are being asked to complete the Reynold&rsquo;s Dyslexia Risk Assessment on one or more children.</p>
    //         <p>Click on the URL link below to access the form.</p>
    //         <p>Please be sure that the child&rsquo;s age matches the age shown on the form that opens.</p>
    //         <p>You can use the link below to complete multiple forms.</p>
    //         <p>${sessionInviteURL}</p>`,
    //         bcc: sessionInviteEmails,
    //         subject: 'WICI Session Invite'
    //     }

    //     const config = 
    //     {
    //         headers:
    //         {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Token ' + token
    //         }
    //     }

    //     await axios.post(process.env.REACT_APP_API_URL + '/mail/send', data, config)
    //     .catch(error => { console.log(error) })

    //     setSessionInviteEmails('')
    //     setIsSendingEmail(false)
    // }, [sessionInviteEmails, sessionInviteURL, token])


    useEffect(() =>
    {
        getExaminees()
        getTests()
        getUserInfo()
    }, [getExaminees, getTests, getUserInfo])

    useEffect(() =>
    {
        createSessionInviteURL()
    }, [createSessionInviteURL, selectedExamineeID, selectedLabel, selectedTestID])


    return (
        <Dashboard>
                <div className={styles['card']}>
                    <div className={styles['header']}>
                        <label className={styles['name']}>
                            Session Invite
                        </label>
                    </div>
                    <hr className={styles['horizontal-line']}></hr>
                    <div className={styles['info-container']}>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Examinee (optional)</label>
                                {renderExamineeSelect()}
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Test</label>
                                {renderTestSelect()}
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Record Label</label>
                                <Input
                                    allowClear
                                    onChange={event => setSelectedLabel(event.target.value)}
                                    onPressEnter={(e) => e.target.blur()}
                                    placeholder='Label'
                                    value={selectedLabel}
                                />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['break']} />
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Invite URL</label>
                                <Input
                                    readOnly={true}
                                    value={sessionInviteURL}
                                />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        {/* <div className={styles['item-container']} >
                            <label className={styles['item-name']}>Send Invite</label>
                                <Input
                                    onChange={event => setSessionInviteEmails(event.target.value)}
                                    placeholder='Email(s), comma separated.'
                                    value={sessionInviteEmails}
                                />
                                <Button
                                    disabled={!sessionInviteEmails}
                                    loading={isSendingEmail}
                                    onClick={sendSessionInvites}
                                    type='primary'
                                >
                                    Send
                                </Button>
                            <hr className={styles['item-underline']}></hr>
                        </div> */}
                    </div>
                </div>
            </Dashboard>
    )
}


export default SessionInvitePage