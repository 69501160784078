import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from '../style/landing-page.module.css'
import { createUser, login, setSessionID } from '../redux/action-creators'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { notification, Radio } from 'antd'
import axios from 'axios'


const LandingPage = () =>
{
    const [activeCard, setActiveCard]   = useState('signIn')
    const [email, setEmail]             = useState('')
    const [kind, setKind]               = useState('Examiner')
    const [password, setPassword]       = useState('')
    const [resetCode, setResetCode]     = useState('')

    const sessionID = useSelector(state => state.sessionID)
    const userInfo  = useSelector(state => state.userInfo)
    const dispatch  = useDispatch()
    const history   = useHistory()


    const resetPassword = useCallback(async () =>
    {
        const data =
        {
            code: resetCode,
            newPassword: password
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json'
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/user/resetPassword', data, config)
        .then(response =>
        {
            setActiveCard('signIn')
        })
        .catch(error => { console.log(error) })
    }, [password, resetCode])

    const sendPasswordResetRequest = useCallback(async () =>
    {
        const data =
        {
            email: email
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json'
            }
        }

        return axios.post(process.env.REACT_APP_API_URL + '/user/resetPasswordRequest', data, config)
        .then(response =>
        {
            setActiveCard('resetPassword_b')
            notification.success({description: 'The password reset code has been sent to your email.'})
        })
        .catch(error => { console.log(error) })
    }, [email])
    

    const createAccountCard = useMemo(() =>
    {
        return (
            <div className={styles['card']}>
                <div className={styles['input-container']}>
                    <input
                        className={styles['input']}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {if (e.key === 'Enter') dispatch(createUser(email, kind, password))}}
                        placeholder='Email'
                        type='email'
                    />
                    <input
                        className={styles['input']}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => {if (e.key === 'Enter') dispatch(createUser(email, kind, password))}}
                        placeholder='Password'
                        type='password'
                    />
                    <Radio.Group
                        buttonStyle='solid'
                        name='kind'
                        onChange={event => setKind(event.target.value)}
                        options={
                        [
                            {label: 'Developer', value: 'Developer'},
                            {label: 'Examiner', value: 'Examiner'}
                        ]}
                        optionType='button'
                        value={kind}
                    >
                        <Radio key={`kind-Developer`} value={'Developer'}>Developer</Radio>
                        <Radio key={`kind-Examiner`} value={'Examiner'}>Examiner</Radio>
                    </Radio.Group>
                </div>
                <div className={styles['button-container']}>
                    <button
                        className={styles['button']}
                        onClick={() => dispatch(createUser(email, kind, password))}
                    >
                        <label className={styles['button-label']}>
                            Create Account
                        </label>
                    </button>
                </div>
                <div className={styles['action-container']}>
                    <div
                        onClick={() => setActiveCard('signIn')}
                    >
                        <label className={styles['action']}>
                            Sign In
                        </label>
                    </div>
                </div>
            </div>
        )
    }, [dispatch, email, kind, password])

    const resetPassword_a = useMemo(() =>
    {
        return (
            <div className={styles['card']}>
                <div className={styles['input-container']}>
                    <input
                        className={styles['input']}
                        key='resetPassword_a_email'
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {if (e.key === 'Enter') sendPasswordResetRequest()}}
                        placeholder='Email'
                        type='email'
                    />
                </div>
                <div className={styles['button-container']}>
                    <button
                        className={styles['button']}
                        onClick={() => sendPasswordResetRequest()}
                    >
                        <label className={styles['button-label']}>
                            Send Code
                        </label>
                    </button>
                </div>
                <div className={styles['action-container']}>
                    <div
                        onClick={() => setActiveCard('signIn')}
                    >
                        <label className={styles['action']}>
                            Sign In
                        </label>
                    </div>
                </div>
            </div>
        )
    }, [sendPasswordResetRequest])

    const resetPassword_b = useMemo(() =>
    {
        return (
            <div className={styles['card']}>
                <div className={styles['input-container']}>
                    <input
                        className={styles['input']}
                        key='resetPassword_b_password'
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => {if (e.key === 'Enter') resetPassword()}}
                        placeholder='New Password'
                        type='password'
                    />
                    <input
                        className={styles['input']}
                        key='resetPassword_b_code'
                        onChange={(e) => setResetCode(e.target.value)}
                        onKeyDown={(e) => {if (e.key === 'Enter') resetPassword()}}
                        placeholder='Reset Code'
                        type='resetCode'
                    />
                </div>
                <div className={styles['button-container']}>
                    <button
                        className={styles['button']}
                        onClick={() => resetPassword()}
                    >
                        <label className={styles['button-label']}>
                            Reset
                        </label>
                    </button>
                </div>
                <div className={styles['action-container']}>
                    <div
                        onClick={() => setActiveCard('signIn')}
                    >
                        <label className={styles['action']}>
                            Sign In
                        </label>
                    </div>
                </div>
            </div>
        )
    }, [resetPassword])

    const signInCard = useMemo(() =>
    {
        return (
            <div className={styles['card']}>
                <div className={styles['input-container']}>
                    <input
                        className={styles['input']}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {if (e.key === 'Enter') dispatch(login(email, password))}}
                        placeholder='Email'
                        type='email'
                    />
                    <input
                        className={styles['input']}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => {if (e.key === 'Enter') dispatch(login(email, password))}}
                        placeholder='Password'
                        type='password'
                    />
                </div>
                <div className={styles['button-container']}>
                    <button
                        className={styles['button']}
                        onClick={() => dispatch(login(email, password))}
                    >
                        <label className={styles['button-label']}>
                            Sign In
                        </label>
                    </button>
                </div>
                <div className={styles['action-container']}>
                    <div
                        onClick={() => setActiveCard('resetPassword_a')}
                    >
                        <label className={styles['action']}>
                            Forgot Password?
                        </label>
                    </div>
                    <div
                        onClick={() => setActiveCard('createAccount')}
                    >
                        <label className={styles['action']}>
                            Create Account
                        </label>
                    </div>
                </div>
            </div>
        )
    }, [dispatch, email, password])


    const getActiveCard = useCallback(() =>
    {
        switch (activeCard)
        {
            case 'createAccount': return createAccountCard
            case 'resetPassword_a': return resetPassword_a
            case 'resetPassword_b': return resetPassword_b
            case 'signIn': return signInCard
            default: return signInCard
        }
    }, [activeCard, createAccountCard, resetPassword_a, resetPassword_b, signInCard])


    useEffect(() =>
    {
        // forward to appropriate page when token is present
        if (userInfo.kind === 'Developer') history.push('/developer/batteries')
        else if (userInfo.kind === 'Examiner') history.push('/examiner/sessions')
        else if (userInfo.kind === 'Administrator') history.push('/admin/credit')
    }, [history, userInfo])


    return (
        <div className={styles['container-a']}>
            <div className={styles['container-b']}>
                <div className={styles['container-c']}>
                    <label className={styles['logo']}>
                        WICI
                    </label>
                </div>
                <div className={styles['container-c']}>
                    {getActiveCard()}
                </div>
                <div className={styles['container-c']}>
                    <div className={styles['card']}>
                        <div className={styles['input-container']}>
                            <input
                                className={styles['input']}
                                onChange={(e) => dispatch(setSessionID(e.target.value))}
                                onKeyDown={(e) => {if (e.key === 'Enter') history.push(`/examinee/test/${sessionID}`)}}
                                placeholder='Session ID'
                                type='sessionID'
                            />
                        </div>
                        <div className={styles['button-container']}>
                            <button
                                className={styles['button']}
                                onClick={() => history.push(`/examinee/test/${sessionID}`)}
                            >
                                <label className={styles['button-label']}>
                                    Join Session
                                </label>
                            </button>
                        </div>
                        <div className={styles['action-container']} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage