/*
    User settings page
*/

import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../dashboard'
import axios from 'axios'
import { useSelector } from 'react-redux'
import styles from '../../style/info-card.module.css'
import { Input } from 'antd'
import {EditOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons'

const SettingsPage = () =>
{
    const token = useSelector(state => state.token)
    const [isEditing, setIsEditing] = useState(false)
    const [userInfo, setUserInfo] = useState({})


    const editUserInfo = useCallback(async () =>
    {
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/user/edit', userInfo, config)
        .catch(error => { console.log(error) })
    }, [token, userInfo])

    const getUserInfo = useCallback(async () =>
    {
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/user/info', {}, config)
        .catch(error => { console.log(error) })

        setUserInfo(response.data)
    }, [token])

    const handleUserInfoChange = (event) =>
    { 
        const { name, value } = event.target

        setUserInfo({
            ...userInfo,
            [name]: value
        })
    }


    useEffect(() =>
    {
        getUserInfo()
    }, [getUserInfo])

    
    return (
        <Dashboard>
                <div className={styles['card']}>
                    <div className={styles['header']}>
                        <label className={styles['name']}>
                            {'User Settings'}
                        </label>
                        {
                            // cancel button
                            isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={async () =>
                                            {
                                                setIsEditing(false)
                                                await getUserInfo()
                                            }}
                                    >
                                        <RollbackOutlined />
                                        <label className={styles['header-button-label']}>
                                            cancel
                                        </label>
                                    </div>
                                ) : null
                        }
                        {
                            // save button
                            isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={async () =>
                                        {
                                            await editUserInfo()
                                            setIsEditing(false)
                                            await getUserInfo()
                                        }}
                                    >
                                        <SaveOutlined />
                                        <label className={styles['header-button-label']}>
                                            Save
                                        </label>
                                    </div>
                                ) : null
                        }
                        {
                            !isEditing ?
                                (
                                    <div
                                        className={styles['button-container']}
                                        onClick={() => setIsEditing(true)}
                                    >
                                        <EditOutlined />
                                        <label className={styles['header-button-label']}>
                                            Edit
                                </label>
                                    </div>
                                ) : null
                        }
                    </div>
                    <hr className={styles['horizontal-line']}></hr>
                    <div className={styles['info-container']}>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>First Name</label>
                            <Input
                                className={styles['item-string']}
                                name='firstName'
                                onChange={handleUserInfoChange}
                                onPressEnter={(e) => e.target.blur()}
                                readOnly={!isEditing}
                                value={userInfo.firstName}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                        <div className={styles['item-container']}>
                            <label className={styles['item-name']}>Last Name</label>
                            <Input
                                className={styles['item-string']}
                                name='lastName'
                                onChange={handleUserInfoChange}
                                onPressEnter={(e) => e.target.blur()}
                                readOnly={!isEditing}
                                value={userInfo.lastName}
                            />
                            <hr className={styles['item-underline']}></hr>
                        </div>
                    </div>
                </div>
            </Dashboard>
    )
}

export default SettingsPage