import React, { useState } from 'react'
import styles from '../../../style/item-content/image-detail-toolbar.module.css'
import { Button, Slider } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { Transforms } from 'slate'
import { useSlate } from 'slate-react'
import Editor from '../item-content-editor'
import Input from '../../input'

/*
    Image detail toolbar
*/

const ImageDetailToolbar = () =>
{
    const editor = useSlate()
    const [selection, setSelection] = useState(null)
    const activeNode = Editor.getNode(editor, 'image', selection)


    return <div
                className={styles['container']}
                onBlur={() => setSelection(null)}
                onFocus={() => setSelection(editor.selection)}
            >
                <div className={styles['upload-container']}>
                    <Button
                        icon={<UploadOutlined />}
                        onMouseDown={event => {
                            event.preventDefault()
                            if (!editor.selection)
                            {
                                Transforms.collapse(editor, { edge: 'end' })
                                setSelection(null)
                            }
                            document.getElementById('upload-image').click()
                        }}
                        title='upload image'
                    />
                </div>
                <div className={styles['size-container']}>
                    <Slider
                        max={100}
                        min={1}
                        onBlur={() => { if (selection) { Transforms.select(editor, selection) } }}
                        onChange={value => {
                            if (!selection || !value) return
                            Transforms.select(editor, selection) // TODO: send as 'at' to toggle
                            Editor.toggleInline(editor, 'width', value, true)
                        }}
                        onFocus={(event) => {
                            event.preventDefault()
                            setSelection(editor.selection)
                        }}
                        value={Editor.getNodeProperty(editor, 'image', 'width')}
                    />
                </div>
                <div className={styles['varkey-container']}>
                    <label className={styles['varkey']}>varKey:</label>
                    <div className={styles['varkey_input']}>
                        <Input
                            onBlur={() => { if (selection) { Transforms.select(editor, selection) } }}
                            onChange={event =>
                            {
                                event.preventDefault()
                                if (!selection) return
                                Editor.setNodeProperties(editor, 'image', { varKey: event.target.value }, selection)
                            }}
                            onFocus={(event) =>
                            {
                                event.preventDefault()
                                setSelection(editor.selection)
                            }}
                            value={activeNode ? activeNode.varKey : null}
                        />
                    </div>
                </div>
            </div>
}

export default ImageDetailToolbar