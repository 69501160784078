import React, { useRef } from 'react'
import styles from '../style/number-input.module.css'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

/*
    NumberInput: A controlled number input with the option to prevent taking focus and is dynamically sized
*/

const NumberInput = ({
    className = '',
    disabled = false,       // boool
    max = null,             // number
    min = null,             // number
    onChange = null,        // (value) => {}
    placeholder = null,     // number
    preventDefault=false,   // bool
    step=1,                 // number
    title,                  // string
    value                   // number
}) =>
{
    const input = useRef(null)

    return <div
                className={styles['container']}
                disabled={disabled}
                title={title}
            >
                <div className={styles['input-container']}>
                   <input
                        className={styles['number'] + ' ' + className}
                        disabled={disabled}
                        id=''
                        max={max}
                        min={min}
                        onChange={(event) =>
                        {
                            if (onChange) onChange(Number.parseFloat(event.target.value))
                        }}
                        onMouseDown={(event) => preventDefault ? event.preventDefault() : null}
                        placeholder={placeholder}
                        ref={input}
                        step={step}
                        type='number'
                        value={!value && value !== 0 ? '' : value}
                    /> 
                </div>
                <div className={styles['controls-container']}>
                    <div
                        className={styles['arrow'] + ' ' + styles['up']}
                        onMouseDown={(event) =>
                        {
                            if (preventDefault) event.preventDefault()
                            if (disabled) return
                            input.current.stepUp()
                            onChange(Number.parseFloat(input.current.value))
                        }}
                    >
                        <UpOutlined />
                    </div>
                    <div
                        className={styles['arrow'] + ' ' + styles['down']}
                        onMouseDown={(event) =>
                        {
                            if (preventDefault) event.preventDefault()
                            if (disabled) return
                            input.current.stepDown()
                            onChange(Number.parseFloat(input.current.value))
                        }}
                    >
                        <DownOutlined />
                    </div>
                </div>
            </div>
}

export default NumberInput