/*
    List of fonts to be imported by react-google-font-loader
    The list includes all fonts that contain italic versions of both 400 and 700 weight
*/


const fonts = [
    { "font": "Alegreya", "weights": [400, "400i", 700, "700i"] },
    { "font": "Alegreya SC", "weights": [400, "400i", 700, "700i"] },
    { "font": "Alegreya Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "Alegreya Sans SC", "weights": [400, "400i", 700, "700i"] },
    { "font": "Aleo", "weights": [400, "400i", 700, "700i"] },
    { "font": "Almendra", "weights": [400, "400i", 700, "700i"] },
    { "font": "Amaranth", "weights": [400, "400i", 700, "700i"] },
    { "font": "Amiri", "weights": [400, "400i", 700, "700i"] },
    { "font": "Anonymous Pro", "weights": [400, "400i", 700, "700i"] },
    { "font": "Archivo", "weights": [400, "400i", 700, "700i"] },
    { "font": "Archivo Narrow", "weights": [400, "400i", 700, "700i"] },
    { "font": "Arimo", "weights": [400, "400i", 700, "700i"] },
    { "font": "Arsenal", "weights": [400, "400i", 700, "700i"] },
    { "font": "Arvo", "weights": [400, "400i", 700, "700i"] },
    { "font": "Asap", "weights": [400, "400i", 700, "700i"] },
    { "font": "Asap Condensed", "weights": [400, "400i", 700, "700i"] },
    { "font": "Averia Libre", "weights": [400, "400i", 700, "700i"] },
    { "font": "Averia Sans Libre", "weights": [400, "400i", 700, "700i"] },
    { "font": "Averia Serif Libre", "weights": [400, "400i", 700, "700i"] },
    { "font": "B612", "weights": [400, "400i", 700, "700i"] },
    { "font": "B612 Mono", "weights": [400, "400i", 700, "700i"] },
    { "font": "Bai Jamjuree", "weights": [400, "400i", 700, "700i"] },
    { "font": "Barlow", "weights": [400, "400i", 700, "700i"] },
    { "font": "Barlow Condensed", "weights": [400, "400i", 700, "700i"] },
    { "font": "Barlow Semi Condensed", "weights": [400, "400i", 700, "700i"] },
    { "font": "Be Vietnam", "weights": [400, "400i", 700, "700i"] },
    { "font": "Cabin", "weights": [400, "400i", 700, "700i"] },
    { "font": "Cambay", "weights": [400, "400i", 700, "700i"] },
    { "font": "Cantarell", "weights": [400, "400i", 700, "700i"] },
    { "font": "Caudex", "weights": [400, "400i", 700, "700i"] },
    { "font": "Chakra Petch", "weights": [400, "400i", 700, "700i"] },
    { "font": "Chivo", "weights": [400, "400i", 700, "700i"] },
    { "font": "Cormorant", "weights": [400, "400i", 700, "700i"] },
    { "font": "Cormorant Garamond", "weights": [400, "400i", 700, "700i"] },
    { "font": "Cormorant Infant", "weights": [400, "400i", 700, "700i"] },
    { "font": "Cousine", "weights": [400, "400i", 700, "700i"] },
    { "font": "Crimson Pro", "weights": [400, "400i", 700, "700i"] },
    { "font": "Crimson Text", "weights": [400, "400i", 700, "700i"] },
    { "font": "Cuprum", "weights": [400, "400i", 700, "700i"] },
    { "font": "DM Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "EB Garamond", "weights": [400, "400i", 700, "700i"] },
    { "font": "Economica", "weights": [400, "400i", 700, "700i"] },
    { "font": "Exo", "weights": [400, "400i", 700, "700i"] },
    { "font": "Exo 2", "weights": [400, "400i", 700, "700i"] },
    { "font": "Expletus Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "Fahkwang", "weights": [400, "400i", 700, "700i"] },
    { "font": "Faustina", "weights": [400, "400i", 700, "700i"] },
    { "font": "Fira Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "Fira Sans Condensed", "weights": [400, "400i", 700, "700i"] },
    { "font": "Fira Sans Extra Condensed", "weights": [400, "400i", 700, "700i"] },
    { "font": "GFS Neohellenic", "weights": [400, "400i", 700, "700i"] },
    { "font": "Gentium Basic", "weights": [400, "400i", 700, "700i"] },
    { "font": "Gentium Book Basic", "weights": [400, "400i", 700, "700i"] },
    { "font": "Grenze", "weights": [400, "400i", 700, "700i"] },
    { "font": "IBM Plex Mono", "weights": [400, "400i", 700, "700i"] },
    { "font": "IBM Plex Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "IBM Plex Sans Condensed", "weights": [400, "400i", 700, "700i"] },
    { "font": "IBM Plex Serif", "weights": [400, "400i", 700, "700i"] },
    { "font": "Istok Web", "weights": [400, "400i", 700, "700i"] },
    { "font": "Josefin Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "Josefin Slab", "weights": [400, "400i", 700, "700i"] },
    { "font": "K2D", "weights": [400, "400i", 700, "700i"] },
    { "font": "Kanit", "weights": [400, "400i", 700, "700i"] },
    { "font": "Karla", "weights": [400, "400i", 700, "700i"] },
    { "font": "KoHo", "weights": [400, "400i", 700, "700i"] },
    { "font": "Kodchasan", "weights": [400, "400i", 700, "700i"] },
    { "font": "Krub", "weights": [400, "400i", 700, "700i"] },
    { "font": "Lato", "weights": [400, "400i", 700, "700i"] },
    { "font": "Libre Franklin", "weights": [400, "400i", 700, "700i"] },
    { "font": "Literata", "weights": [400, "400i", 700, "700i"] },
    { "font": "Livvic", "weights": [400, "400i", 700, "700i"] },
    { "font": "Lobster Two", "weights": [400, "400i", 700, "700i"] },
    { "font": "Lora", "weights": [400, "400i", 700, "700i"] },
    { "font": "Mali", "weights": [400, "400i", 700, "700i"] },
    { "font": "Manuale", "weights": [400, "400i", 700, "700i"] },
    { "font": "Marvel", "weights": [400, "400i", 700, "700i"] },
    { "font": "Merriweather", "weights": [400, "400i", 700, "700i"] },
    { "font": "Merriweather Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "Montserrat", "weights": [400, "400i", 700, "700i"] }
    , { "font": "Montserrat Alternates", "weights": [400, "400i", 700, "700i"] },
    { "font": "Muli", "weights": [400, "400i", 700, "700i"] },
    { "font": "Niramit", "weights": [400, "400i", 700, "700i"] },
    { "font": "Nobile", "weights": [400, "400i", 700, "700i"] },
    { "font": "Noticia Text", "weights": [400, "400i", 700, "700i"] },
    { "font": "Noto Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "Noto Serif", "weights": [400, "400i", 700, "700i"] },
    { "font": "Nunito", "weights": [400, "400i", 700, "700i"] },
    { "font": "Nunito Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "Open Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "Overlock", "weights": [400, "400i", 700, "700i"] },
    { "font": "Overpass", "weights": [400, "400i", 700, "700i"] },
    { "font": "PT Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "PT Serif", "weights": [400, "400i", 700, "700i"] },
    { "font": "Philosopher", "weights": [400, "400i", 700, "700i"] },
    { "font": "Playfair Display", "weights": [400, "400i", 700, "700i"] },
    { "font": "Playfair Display SC", "weights": [400, "400i", 700, "700i"] },
    { "font": "Poppins", "weights": [400, "400i", 700, "700i"] },
    { "font": "Prompt", "weights": [400, "400i", 700, "700i"] },
    { "font": "Proza Libre", "weights": [400, "400i", 700, "700i"] },
    { "font": "Puritan", "weights": [400, "400i", 700, "700i"] },
    { "font": "Quantico", "weights": [400, "400i", 700, "700i"] },
    { "font": "Quattrocento Sans", "weights": [400, "400i", 700, "700i"] },
    { "font": "Raleway", "weights": [400, "400i", 700, "700i"] },
    { "font": "Rambla", "weights": [400, "400i", 700, "700i"] },
    { "font": "Red Hat Display", "weights": [400, "400i", 700, "700i"] },
    { "font": "Red Hat Text", "weights": [400, "400i", 700, "700i"] },
    { "font": "Roboto", "weights": [400, "400i", 700, "700i"] },
    { "font": "Roboto Condensed", "weights": [400, "400i", 700, "700i"] },
    { "font": "Roboto Mono", "weights": [400, "400i", 700, "700i"] },
    { "font": "Rosario", "weights": [400, "400i", 700, "700i"] },
    { "font": "Rubik", "weights": [400, "400i", 700, "700i"] },
    { "font": "Sansita", "weights": [400, "400i", 700, "700i"] },
    { "font": "Sarabun", "weights": [400, "400i", 700, "700i"] },
    { "font": "Scada", "weights": [400, "400i", 700, "700i"] },
    { "font": "Share", "weights": [400, "400i", 700, "700i"] },
    { "font": "Source Code Pro", "weights": [400, "400i", 700, "700i"] },
    { "font": "Source Sans Pro", "weights": [400, "400i", 700, "700i"] },
    { "font": "Space Mono", "weights": [400, "400i", 700, "700i"] },
    { "font": "Spectral", "weights": [400, "400i", 700, "700i"] },
    { "font": "Spectral SC", "weights": [400, "400i", 700, "700i"] },
    { "font": "Taviraj", "weights": [400, "400i", 700, "700i"] },
    { "font": "Thasadith", "weights": [400, "400i", 700, "700i"] },
    { "font": "Tinos", "weights": [400, "400i", 700, "700i"] },
    { "font": "Titillium Web", "weights": [400, "400i", 700, "700i"] },
    { "font": "Trirong", "weights": [400, "400i", 700, "700i"] },
    { "font": "Ubuntu", "weights": [400, "400i", 700, "700i"] },
    { "font": "Ubuntu Mono", "weights": [400, "400i", 700, "700i"] },
    { "font": "Unna", "weights": [400, "400i", 700, "700i"] },
    { "font": "Volkhov", "weights": [400, "400i", 700, "700i"] },
    { "font": "Vollkorn", "weights": [400, "400i", 700, "700i"] },
    { "font": "Zilla Slab", "weights": [400, "400i", 700, "700i"] }
]

export default fonts