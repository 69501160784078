/*
    Examiner's examinee test history page
*/


import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../dashboard'
import infoCardStyles from '../../style/info-card.module.css'
import testHistoryStyles from '../../style/examiner/test-history-page.module.css'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Button, Collapse, Modal, notification } from 'antd'
import { DeleteOutlined, EditOutlined, LoadingOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons'
import Table from '../table'

const styles = Object.assign(infoCardStyles, testHistoryStyles)


const ExamineeTestHistoryPage = () =>
{
    const { examineeID } = useParams()
    const token = useSelector(state => state.token)
    const [activePanels, setActivePanels] = useState([]) // [recordID]
    const [creditPoolID, setCreditPoolID] = useState()
    const [editing, setEditing] = useState({}) // { recordID: bool }
    const [examinee, setExaminee] = useState({})
    const [purchased, setPurchased] = useState({}) // {recordID: {reportID: bool}}
    const [records, setRecords] = useState({}) // {recordID: {}}
    const [recordDir, setRecordDir] = useState([])
    const [reports, setReports] = useState({}) // {testID: [{_id, name}]}


    const getDefaultCreditPoolID = useCallback(async () =>
    {
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        let response = await axios.post(process.env.REACT_APP_API_URL + '/user/info', {}, config)
        .catch(error => { console.log(error) })

        // notify user if default credit pool has not been set
        if (!response || !response.data.defaultCreditPool)
        {
            notification['warning'](
            {
                message: 'No default credit pool selected.',
                description: 'Navigate to \'Credits\' from the user dropdown, then create and/or set a credit pool as your default.',
            })
        }

        setCreditPoolID(response.data.defaultCreditPool)
    }, [token])

    const getExamineeInfo = useCallback(async () =>
    {
        const data =
        {
            examineeID: examineeID
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/examinee/get', data, config)
        .catch(error => { console.log(error) })

        setExaminee(response.data.examinee)
    }, [examineeID, token])

    const getPurchaseStatuses = useCallback(async (recordID, reports) =>
    {
        let requestData = []

        reports.forEach(report =>
        {
            requestData.push(
            {
                kind: 'transaction_recordReport',
                recordID: recordID,
                reportID: report._id.toString()
            })
        })

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        // send the requests
        let promises = requestData.map(async data =>
        {
            let response = await axios.post(process.env.REACT_APP_API_URL + '/transaction/purchased', data, config)
            .catch(error => { console.log(error) })

            return [data.recordID, data.reportID, response.data.purchased]
        })

        const responses = await Promise.all(promises)
        const result = {}
        responses.forEach(([recordID, reportID, purchased]) =>
        {
            if (!result[recordID]) result[recordID] = {}
            result[recordID][reportID] = purchased
        })

        setPurchased(prev => {return {...prev, ...result}})
    }, [token])

    const getTest = useCallback(async (testID) =>
    {
        const data =
        {
            testID: testID.toString(),
            populateItems: true
        }
  
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/test/get', data, config)
        .catch(error => { console.log(error) })

        return response.data.test
    }, [token])

    const getReports = useCallback(async testID =>
    {
        // don't get reports twice for the same test
        if (reports.hasOwnProperty(testID)) return reports[testID]

        const data =
        {
            testID: testID
        }

        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/report/forTest', data, config)
        .catch(error => { console.log(error) })

        setReports(prev => {return {...prev, [testID]: response.data.reports}})

        return response.data.reports
    }, [reports, token])

    const getRecord = useCallback(async (recordID) =>
    {
        // get record
        const data =
        {
            recordID: recordID.toString()
        }
  
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const recordResponse = await axios.post(process.env.REACT_APP_API_URL + '/record/get', data, config)
        .catch(error => { console.log(error) })

        const record = recordResponse.data.record

        // populate test
        record.test = await getTest(record.test)

        // setRecord(record)
        setRecords(prev => { return {...prev, [recordID]: record} })

        return record
    }, [getTest, token])

    const getRecordDir = useCallback(async () =>
    {
        const data =
        {
            examineeID: examineeID
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        const response = await axios.post(process.env.REACT_APP_API_URL + '/record/dir', data, config)
        .catch(error => { console.log(error) })

        setRecordDir(response.data.records)
    }, [examineeID, token])

    const deleteRecord = useCallback(async recordID =>
    {
        const data =
        {
            recordID
        }
        
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/record/delete', data, config)
        .catch(error => { console.log(error) })

        getRecordDir()
    }, [getRecordDir, token])

    const showDeleteConfirm = useCallback(recordID =>
    {
        Modal.confirm({
            cancelText: 'Cancel',
            content: 'This can not be undone!',
            okButtonProps: { type: 'danger' },
            okText: 'Delete',
            onCancel() { },
            onOk() {deleteRecord(recordID)},
            title: 'Are you sure you want to delete this record?'
        })
    }, [deleteRecord])

    const editRecord = useCallback(async recordID =>
    {
        const data =
        {
            ...records[recordID],
            recordID
        }
        
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/record/edit', data, config)
        .catch(error => { console.log(error) })

        setEditing(prev => {return {...prev, [recordID]: false}})
        getRecord(recordID)
    }, [getRecord, records, token])

    const purchaseReport = useCallback(async (recordID, reportID) =>
    {
        const data =
        {
            creditPoolID,
            kind: 'transaction_recordReport',
            recordID: recordID,
            reportID: reportID
        }
      
        const config =
        {
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token
            }
        }

        await axios.post(process.env.REACT_APP_API_URL + '/transaction/purchase', data, config)
        .catch(error => { console.log(error) })

        // refresh purchase statuses
        const record = records[recordID]
        const testID = record.test._id.toString()
        const reportsForTest = reports[testID]
        await getPurchaseStatuses(recordID, reportsForTest)
    }, [creditPoolID, getPurchaseStatuses, records, reports, token])

    const renderData = useCallback(recordID =>
    {
        const data = records[recordID].data

        return (
            <textarea
                className={styles['data']}
                defaultValue={JSON.stringify(data, null, '\t')}
                readOnly
            />
        )
    }, [records])

    const renderGenerateButton = useCallback((reportID, recordID) =>
    {
        const isPurchased = purchased[recordID] ? purchased[recordID][reportID] : null

        // if data[`report_${reportName}_complete`] is false, the report will be considered incomplete, else complete.
        // in other words: if data[`report_${reportName}_complete`] does not exist, it's default value is considered to be true.
        const testID = records[recordID].test._id
        const reportName = reports[testID].find(report => report._id === reportID).name
        const recordIsIncomplete = records[recordID].data[`report_${reportName}_complete`] === false

        return <Button
                    block
                    className={styles['ant-btn']}
                    danger={isPurchased === false && recordIsIncomplete === false}
                    disabled={isPurchased === null || recordIsIncomplete === true}
                    onClick={() =>
                    {
                        if (isPurchased) window.open(`${window.location.origin}/report/${recordID}/${reportID}`, '_blank')
                        else if (!isPurchased) purchaseReport(recordID, reportID)
                    }}
                    type='primary'
                >
                    {recordIsIncomplete === true ? 'Incomplete' : isPurchased === false ? 'Purchase' : 'View'}
                </Button>
    }, [purchased, purchaseReport, records, reports])

    const renderPanelHeader = useCallback(dir =>
    {
        return (
            <div className={styles['panel-header__container']}>
                <div className={styles['panel-header_record-label']}>
                    <label className={styles['panel-header__label']} >{dir.label}</label>
                </div>
                <div className={styles['panel-header_test-name']}>
                    <label className={styles['panel-header__label']} >{dir.test.name}</label>
                </div>
                <div className={styles['panel-header_test-date']}>
                    <label className={styles['panel-header__label']} >{new Date(dir.testDate).toDateString()}</label>
                </div>
                <div
                    className={styles['panel-header_edit-buttons']}
                    onClick={event => { if (activePanels.includes(dir._id)) event.stopPropagation() }}
                >
                    {
                        activePanels.includes(dir._id) ?
                            editing[dir._id] ?
                                <>
                                    <div
                                        className={styles['panel-header_button']}
                                        onClick={event => showDeleteConfirm(dir._id)}
                                    >
                                        <DeleteOutlined/>
                                    </div>
                                    <div
                                        className={styles['panel-header_button']}
                                        onClick={event => editRecord(dir._id)}
                                    >
                                        <SaveOutlined/>
                                    </div>
                                    <div
                                        className={styles['panel-header_button']}
                                        onClick={event =>
                                    {
                                        setEditing(prev => { return {...prev, [dir._id]: false} })
                                        getRecord(dir._id)
                                    }}
                                    >
                                        <RollbackOutlined/>
                                    </div>
                                </>
                            :
                                <div
                                    className={styles['panel-header_button']}
                                    onClick={event => setEditing(prev => { return {...prev, [dir._id]: true} })}
                                >
                                    <EditOutlined/>
                                </div>
                        :
                            null
                    }
                </div>
            </div>
        )
    }, [activePanels, editing, editRecord, getRecord, showDeleteConfirm])

    const renderReports = useCallback((testID, recordID) =>
    {
        let columns =
        [
            {
                dataKey: 'name',
                type: 'input'
            },
            {
                dataKey: 'generate',
                type: 'node'
            }
        ]

        let data = (reports[testID] || []).map(report =>
        {
            return {
                name: report.name,
                generate: renderGenerateButton(report._id, recordID)
            }
        })

        return (
            <Table
                columns={columns}
                data={data}
            />
        )
    }, [renderGenerateButton, reports])

    const renderResponses = useCallback((record) =>
    {
        let columns =
        [
            {
                dataKey: 'label',
                title: 'Label',
                type: 'input'
            },
            {
                dataKey: 'score',
                title: 'Score',
                type: 'number'
            },
            {
                dataKey: 'response',
                title: 'Response',
                type: 'input'
            },
            {
                dataKey: 'duration',
                title: 'Duration',
                type: 'number'
            },
            {
                dataKey: 'note',
                title: 'Note',
                type: 'textarea'
            }
        ]

        let data = (record.test.items || []).map(item =>
        {
            return {
                itemID: item._id,
                label: item.label,
                score: record.scores[item._id],
                response: record.responses[item._id],
                duration: record.durations[item._id],
                note: record.notes[item._id]
            }
        })

        return (
            <Table
                canAdd={false}
                canDelete={false}
                columns={columns}
                data={data}
                dataDidChange={newData =>
                {
                    const newRecord = records[record._id]

                    for (const row of newData)
                    {
                        newRecord.durations[row.itemID] = row.duration
                        newRecord.notes[row.itemID] = row.note
                        newRecord.responses[row.itemID] = row.response
                        newRecord.scores[row.itemID] = row.score
                    }

                    setRecords(prev => {return {...prev, [record._id]: newRecord}})
                }}
                isEditing={!!editing[record._id]}
            />
        )
    }, [editing, records])

    const renderPanel = useCallback(dir =>
    {
        const record = records[dir._id]

        return (
            <Collapse.Panel
                header={renderPanelHeader(dir)}
                key={dir._id.toString()}
            >
                {
                    record ?
                    <div className={styles['panel_body-container']}>
                        <div className={styles['panel_container-a']}>
                            {renderResponses(record)}
                        </div>
                        <div className={styles['panel_container-b']}>
                            <div className={styles['panel_container-b']}>
                                <label className={styles['panel_container-b__label']}>Data</label>
                                {renderData(record._id)}
                            </div>
                            <div className={styles['panel_container-b']}>
                                <label className={styles['panel_container-b__label']}>Reports</label>
                                {renderReports(record.test._id.toString(), record._id.toString())}
                            </div>
                        </div>
                    </div>
                    : <LoadingOutlined />
                }
            </Collapse.Panel>
        )
    }, [records, renderData, renderPanelHeader, renderReports, renderResponses])

    const initialize = useCallback(async () =>
    {
        getDefaultCreditPoolID()
        getExamineeInfo()
        getRecordDir()
    }, [getDefaultCreditPoolID, getExamineeInfo, getRecordDir])

    const initActivePanel = useCallback(async recordID =>
    {
        const record = await getRecord(recordID)
        const reports = await getReports(record.test._id.toString())
        getPurchaseStatuses(recordID, reports)
    }, [getPurchaseStatuses, getRecord, getReports])

    useEffect(() =>
    {
        initialize()
    }, [initialize])

    useEffect(() =>
    {
        // get record for active panels that don't have a record
        for (const recordID of activePanels)
        {
            if (!records[recordID])
            {
                initActivePanel(recordID)
            }
        }
    }, [activePanels, getRecord, initActivePanel, records])


    return (
        <Dashboard>
            <div className={styles['card']}>
                <div className={styles['header']}>
                    <label className={styles['name']}>
                        {examinee.firstName || examinee.lastName ?
                            `${examinee.firstName} ${examinee.lastName}` :
                            null
                        }
                    </label>
                </div>
                <hr className={styles['horizontal-line']}></hr>
                <div className={styles['info-container']}>
                    <div
                        className={styles['item-container'] + ' hide-scrollbars'}
                        height-100='true'
                        scroll-vertical='true'
                        width-100='true'
                    >
                        <Collapse
                            bordered={false}
                            expandIconPosition='right'
                            onChange={activePanels => setActivePanels(activePanels)}
                        >
                            { recordDir.map(dir => renderPanel(dir)) }
                        </Collapse>
                    </div>
                </div>
            </div>
        </Dashboard>
    )
}


export default ExamineeTestHistoryPage