import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Editor from './item-content-editor'
import { Editable, useSlateStatic } from 'slate-react'
import styles from '../../style/item-content/item-content.module.css'
import AspectRatioWrapper from '../aspect-ratio-wrapper'
import FontSizeWrapper from '../font-size-wrapper'
import SizeWrapper from '../size-wrapper'
import { evaluate, isNull } from '../expression'
import { DatePicker, notification } from 'antd'
import { useCurrentItem, useData, useDataActions, useTimestamps } from './polling-context'
import { useReactToPrint } from 'react-to-print'
import { Transforms } from 'slate'
import moment from 'moment'

// TODO: either disable drag and drop and data transfer of files, or properly implement it.

/*
    ItemContent
    props:
    {
        passive: Bool // true || undefined. function element will not modify data. media muted by default.
        printDocumentTitle: String // optional. A name to call the printed document. defaults to 'WICI APP'.
        printRef: Ref // optional. If a ref is provided, it's current will be set to the function that triggers printing the item content.
    }
    - note: varkey attributes from data override (not replace) element properties if present; so element will have default property after unsetting data override.
*/
const ItemContent = props =>
{
    const editor        = useSlateStatic()
    const currentItem   = useCurrentItem()

    const containerRef  = useRef()

    const passive = useMemo(() => { return props.passive }, [props.passive])

    const [editableStyleOverride, setEditableStyleOverride]     = useState({})
    const [containerStyleOverride, setContainerStyleOverride]   = useState({})
    const [shouldPrint, setShouldPrint]                         = useState(false)
    const [renderCount, setRenderCount]                         = useState(0) // a countdown of extra renders before printing


    const triggerPrint = useCallback(() =>
    {
        setContainerStyleOverride({height: '118.2mm', width: '210mm'})
        setEditableStyleOverride({MozDisplay: 'block', overflow: 'visible'})
        setShouldPrint(true)
        setRenderCount(10)
    }, [])

    const print = useReactToPrint(
    {
        content: () => containerRef.current,
        onAfterPrint: () =>
        {
            setContainerStyleOverride({})
            setEditableStyleOverride({})
            setShouldPrint(false)
        },
        pageStyle:`@page { margin: 10%; }`,
        documentTitle: props.printDocumentTitle,
        removeAfterPrint: true
    })

    const renderElement = useCallback(props =>
    {
        switch (props.element.type) {
            case 'audio': return <AudioElement {...props} passive={passive} />
            case 'code': return <CodeElement {...props} />
            case 'fade': return <FadeElement {...props} />
            case 'function': return <FunctionElement {...props} passive={passive} />
            case 'hide': return <HideElement {...props} />
            case 'input': return <InputElement {...props} />
            case 'dateInput': return <DateInputElement {...props} />
            case 'image': return <ImageElement {...props} />
            case 'outlineCycle': return <OutlineCycleElement {...props} />
            case 'table': return <TableElement {...props} />
            case 'tableRow': return <TableRowElement {...props} />
            case 'tableCell': return <TableCellElement {...props} />
            case 'video': return <VideoElement {...props} passive={passive} />
            default: return <DefaultElement {...props} />
        }
    }, [passive])


    // scroll content to top when item changes
    useEffect(() =>
    {
        Editor.scrollToTop(editor)
    }, [currentItem, editor])

    // assign triggerPrint to ref passed down by parent
    useEffect(() =>
    {
        if (props.printRef) props.printRef.current = triggerPrint
    }, [triggerPrint, props.printRef])

    // burn a few extra renders before printing to allow dynamicly sized components to do their thing
    useEffect(() =>
    {
        if (!shouldPrint) return
        if (renderCount === 0) print()
        else setRenderCount(renderCount - 1)
    }, [print, renderCount, shouldPrint])


    const renderLeaf = useCallback(props => <Leaf {...props} />, [])

    return  <div className={styles['container']} style={containerStyleOverride} >
                <SizeWrapper>
                    <AspectRatioWrapper aspectRatio={16 / 9} >
                        <FontSizeWrapper
                            fontSizeForDimensions={(height, width) => Math.min(height, width) * 0.013}
                            ref={containerRef}
                        >
                            <Editable
                                style={editableStyleOverride}
                                className={styles['content-body']}
                                canselect={editor.readOnly ? 'false' : 'true'}
                                readOnly={editor.readOnly}
                                renderElement={renderElement}
                                renderLeaf={renderLeaf}
                            />
                        </FontSizeWrapper>
                    </AspectRatioWrapper>
                </SizeWrapper>
            </div> 
}

export default ItemContent

const AudioElement = ({ attributes, children, element, passive }) =>
{
    return  <span
                {...attributes}
                contentEditable={false}
                draggable={false}
            >
                <audio
                    // autoPlay
                    controls
                    controlsList='nodownload'
                    muted={!!passive}
                    style={{ width: `${element.width}%` }}
                >
                    <source src={element.url} />
                </audio>
                {children}
             </span>
}

const CodeElement = ({ attributes, children, element }) =>
{
    return  <pre
                {...attributes}
                style={{ textAlign: element.textAlign }}
            >
                <code>{children}</code>
            </pre>
    
}

const DefaultElement = ({ attributes, children, element }) =>
{
    return  <div
                {...attributes}
                style={{
                    lineHeight: element.lineHeight || 1.2, // TODO: 1.2 is a temp default. remove when lineHeight is implemented.
                    marginBottom: '0.5vmin',
                    textAlign: element.textAlign
                }}
            >
                {children}
            </div>
}

const FadeElement = ({ attributes, children, element }) =>
{
    const editor = useSlateStatic()

    return  <span
                {...attributes}
                onMouseDown={event => {
                    if (!editor.interactionEnabled) return
                    const nodePath = Editor.findPath(editor, element)
                    Editor.setNodeProperties(editor, element.type, { didStart: true }, nodePath)
                }}
                style={editor.readOnly && (!element.manualStart || element.didStart) ? {
                    animation: element.name,
                    animationDelay: `${element.delay}s`,
                    animationDuration: `${element.duration}s`,
                    animationFillMode: element.fillMode,
                    opacity: element.opacity
                } : null}
            >
                {children}
            </span>
}

const FunctionElement = ({ attributes, children, element, passive }) =>
{
    const editor = useSlateStatic()
    const data = useData(element.dataKeyDependencies)
    const { currentItemTimestamp } = useTimestamps()


    useEffect(() =>
    {
        if (editor.readOnly && !isNull(element.initExpression) && currentItemTimestamp === editor.currentItemTimestamp)
        {
            // evaluate then unset initExpression
            Editor.withoutNormalizing(editor, () =>
            {
                const path = Editor.findPath(editor, element)
                try { evaluate({node: element.initExpression, editor: editor, path, passive}) }
                catch (error)
                {
                    notification['warning']({
                        message: 'Expression failed to evaluate:',
                        description: error.message,
                    })
                }

                // unset the init expression so it doesn't run again
                Transforms.unsetNodes(editor, 'initExpression', {at: path})
            })
        }
    }, [currentItemTimestamp, data, editor, element, passive])

    // eval update expression on data or node change
    useEffect(() =>
    {
        // don't eval update expressions until init has been eval'd
        if (!isNull(element.initExpression)) return

        // evaluate update expression
        if (editor.readOnly && element.updateExpression && !isNull(element.updateExpression) && currentItemTimestamp === editor.currentItemTimestamp)
        {
            const path = Editor.findPath(editor, element)
            try { evaluate({node: element.updateExpression, editor: editor, path, passive}) }
            catch (error)
            {
                notification['warning']({
                    message: 'Expression failed to evaluate:',
                    description: error.message,
                })
            }
        }
    }, [currentItemTimestamp, data, editor, element, passive])


    return  <span
                {...attributes}
                onMouseDown={event =>
                {            
                    if (editor.readOnly && editor.interactionEnabled && element.expression && !isNull(element.expression) && currentItemTimestamp === editor.currentItemTimestamp)
                    {
                        try { evaluate({node: element.expression, editor: editor, path: Editor.findPath(editor, element), passive}) }
                        catch (error)
                        {
                            notification['warning']({
                                message: 'Expression failed to evaluate:',
                                description: error.message,
                            })
                        }
                    }
                }}
                style={!editor.readOnly ? {
                    border: '2px solid green',
                    borderRadius: '5px'
                } : {}}
            >
                {children}
            </span>
}

const HideElement = ({ attributes, children, element }) =>
{
    const data = useData()
    const editor = useSlateStatic()

    return  <div
                {...attributes}
                style={editor.readOnly ? {
                    display: data[element.varKey] === true ? 'none' : ''
                } : {
                    border: '2px solid blue',
                    borderRadius: '5px'
                }}
            >
                {children}
            </div>
}

/*
attributes:
_opacity number 0.0 - 1.0
_width number % of slate width
*/
const ImageElement = ({ attributes, children, element }) =>
{
    const data = useData()

    // opacity
    let opacity = ''
    if (typeof data[`${element.varKey}_opacity`] === 'number') opacity = data[`${element.varKey}_opacity`]
    else if (typeof element.opacity === 'number') opacity = element.opacity

    return  <span
                {...attributes}
                contentEditable={false}
                draggable={false}
            >
                <img
                    alt=''
                    className={styles['image']}
                    draggable={false}
                    src={element.url}
                    style={{
                        opacity: opacity,
                        width: `${data[`${element.varKey}_width`] || element.width}%`
                    }}
                />
                    {children}
             </span>
}

// TODO: override addMark and removeMark to decend into voids, instead of rewritting leaf mark logic
const InputElement = ({ attributes, children, element }) =>
{
    const data = useData()
    const editor = useSlateStatic()
    const { updateData } = useDataActions()

    const style = useMemo(() =>
    {
        return {
            backgroundColor: '#f0f5f5',
            border: '2px dashed #669999',
            borderRadius: '0.2vmin'
        }
    }, [])

    // render as a regular text leaf node while editing
    if (!editor.readOnly)
    {
        return  <span
                    {...attributes}
                    style={style}
                >
                    {children}
                </span>
    }

    // render the void input element while not editing
    return  <input
                {...attributes}
                onChange={event => updateData({[element.key]: event.target.value})}
                style={{
                    ...style,
                    backgroundColor:    element.children[0].backgroundColor || '',
                    color:              element.children[0].color || '',
                    fontFamily:         element.children[0].fontFamily || '',
                    fontSize:           element.children[0].fontSize ? `${(element.children[0].fontSize) * 10}%` : '',
                    fontStyle:          element.children[0].italic ? 'italic' : 'normal',
                    fontWeight:         element.children[0].bold ? 'bold' : 'normal',
                    height:             '1.2em',  // TOOD: this will need to be calculated dynamically 'calc(1em * ancestorParagraphLineHeight)' when line height is implemented
                    width:              'calc(1em * 10)' // TODO: this will need to be dynamic
                }}
                type='text'
                value={data[element.key] || ''}
            >
                {children}
            </input>
}

const DateInputElement = ({ attributes, children, element }) =>
{
    const data = useData()
    const editor = useSlateStatic()
    const { updateData } = useDataActions()

    const style = useMemo(() =>
    {
        return {
            backgroundColor: '#f0f5f5',
            border: '2px dashed #669999',
            borderRadius: '0.2vmin'
        }
    }, [])

    // render as a regular text leaf node while editing
    if (!editor.readOnly)
    {
        return  <span
                    {...attributes}
                    style={style}
                >
                    {children}
                </span>
    }

    // render the void input element while not editing
    return <span
                {...attributes}
                contentEditable={false}
                draggable={false}
            >
                <DatePicker
                    format='MM/DD/YYYY'
                    onChange={(date, dateString) => updateData({[element.varKey]: dateString})}
                    style={{
                        fontSize: '1.75vmin',
                        width: '16vmin',
                        height: '3vmin'
                    }}
                    value={data[element.varKey] ? moment(data[element.varKey], 'MM/DD/YYYY') : null}
                />
            </span>
}

/*
attributes:
_backgroundColor string
_color string
_fontFamily string
_fontSize number
_fontSyle string
_fontWeight string
_opacity number 0.0 - 1.0
_strikethrough truthy
_underline truthy
*/
const Leaf = ({ attributes, children, leaf }) =>
{
    // properties from data override (not replace) leaf properties if present; so leaf will have default property after unsetting data override.
    const data = useData(leaf.dataKeyDependencies)

    // opacity
    let opacity = ''
    if (typeof data[`${leaf.varKey}_opacity`] === 'number') opacity = data[`${leaf.varKey}_opacity`]
    else if (typeof leaf.opacity === 'number') opacity = leaf.opacity

    // strikethrough / underline
    let textDecoration = ''
    if (data[`${leaf.varKey}_strikethrough`] !== undefined) { if (data[`${leaf.varKey}_strikethrough`]) {textDecoration += 'line-through '} }
    else if (leaf.strikethrough) {textDecoration += 'line-through '}
    if (data[`${leaf.varKey}_underline`] !== undefined) { if (data[`${leaf.varKey}_underline`]) {textDecoration += 'underline '} }
    else if (leaf.underline) {textDecoration += 'underline '}
    
    return (
        <span
            {...attributes}
            style={{
                backgroundColor:    data[`${leaf.varKey}_backgroundColor`] || leaf.backgroundColor || '',
                color:              data[`${leaf.varKey}_color`] || leaf.color || '',
                display:            data[`${leaf.varKey}_display`] || leaf.display || '',
                fontFamily:         data[`${leaf.varKey}_fontFamily`] || leaf.fontFamily || '',
                fontSize:           data[`${leaf.varKey}_fontSize`] ? `${(data[`${leaf.varKey}_fontSize`]) * 10}%` : leaf.fontSize ? `${(leaf.fontSize) * 10}%` : '',
                fontStyle:          data[`${leaf.varKey}_italic`] !== undefined ? (data[`${leaf.varKey}_italic`] ? 'italic' : 'normal') : (leaf.italic ? 'italic' : 'normal'),
                lineHeight:         data[`${leaf.varKey}_lineHeight`] || leaf.lineHeight || 'normal',
                opacity:            opacity,
                fontWeight:         data[`${leaf.varKey}_bold`] !== undefined ? (data[`${leaf.varKey}_bold`] ? 'bold' : 'normal') : (leaf.bold ? 'bold' : 'normal'),
                textDecoration:     textDecoration
            }}
        >
            {children}
        </span>
    )
}

// TODO: remove, replace functionality with data attributes
const OutlineCycleElement = ({ attributes, children, element }) =>
{
    const editor = useSlateStatic()

    return  <span
                {...attributes}
                onClick={event => {
                    if (!editor.interactionEnabled) return
                    const nodePath = Editor.findPath(editor, element)
                    const nextOutlineIndex = (element.currentOutlineIndex + 1) % element.outlines.length
                    Editor.setNodeProperties(editor, element.type, { currentOutlineIndex: nextOutlineIndex }, nodePath)
                }}
                style={{ outline: element.outlines[element.currentOutlineIndex] }}
            >
                {children}
            </span>
}

const TableElement = ({ attributes, children, element }) =>
{
    return  <span
                {...attributes}
                style={{
                    alignItems: 'stretch',
                    borderLeft: `${element.border || ''}`,
                    borderTop: `${element.border || ''}`,
                    display: 'inline-flex',
                    flexDirection: 'column',
                    verticalAlign: 'middle',
                    width: `${element.width}%`
                }}
            >
                {children}
            </span>
}

const TableRowElement = ({ attributes, children, element }) =>
{
    return  <span
                {...attributes}
                style={{
                    display: 'flex',
                    flex: `0 0 ${100/element.rowCount}%`,
                    flexDirection: 'row',
                    width: '100%'
                }}
            >
                {children}
            </span>
}

const TableCellElement = ({ attributes, children, element }) =>
{
    return  <span
                {...attributes}
                style={{
                    borderBottom: `${element.border || ''}`,
                    borderRight: `${element.border || ''}`,
                    flex: `0 0 ${element.colWidth || 100/element.colCount}%`,
                    overflow: 'clip',
                    textAlign: element.textAlign || ''
                }}
            >
                {children}
            </span>
}

// TODO: need to add time to attributes to have the time consumables. it only affects first player that gets and clears the consummable attribute.
/*
attributes:
_controls bool // show/hide controls
_loop bool
_paused bool
_seek-absolute number consumed
_seek-relative number consumed
_stop bool true consumed
_volume number 0.0 - 1.0
_volume-relative number consumed
*/
const VideoElement = ({ attributes, children, element, passive }) =>
{
    const data = useData()
    const { updateData } = useDataActions()
    const ref = useRef()


    const onPause = useCallback(() =>
    {
        if (!element.varKey) return
        updateData({[`${element.varKey}_paused`]: true})
    }, [element, updateData])

    const onPlay = useCallback(() =>
    {
        if (!element.varKey) return
        updateData({[`${element.varKey}_paused`]: false})
    }, [element, updateData])

    const onVolumeChange = useCallback(() =>
    {
        if (!element.varKey) return
        updateData({[`${element.varKey}_volume`]: ref.current.volume})
    }, [element, updateData])

    const play = useCallback(async () =>
    {
        try { await ref.current.play() }
        catch (error) { console.log(error) }
    }, [])

    const pause = useCallback(() =>
    {
        ref.current.pause()
    }, [])


    // init
    useEffect(() =>
    {
        if (!element.varKey || data[`${element.varKey}_volume`] !== undefined) return
        updateData({[`${element.varKey}_volume`]: ref.current.volume})
    }, [data, element, updateData])

    // event listeners
    useEffect(() =>
    {
        const player = ref.current

        player.addEventListener('pause', onPause)
        player.addEventListener('play', onPlay)
        player.addEventListener('volumechange', onVolumeChange)

        return () =>
        {
            player.removeEventListener('pause', onPause)
            player.removeEventListener('play', onPlay)
            player.removeEventListener('volumechange', onVolumeChange)
        }
    }, [onPause, onPlay, onVolumeChange])

    // apply data attributes
    useEffect(() =>
    {
        if (!element.varKey) return

        // play/pause
        if (data[`${element.varKey}_paused`] === false && ref.current.paused === true) play()
        else if (data[`${element.varKey}_paused`] === true && ref.current.paused === false) pause()

        // seek
        if (typeof data[`${element.varKey}_seek-absolute`] === 'number')
        {
            ref.current.currentTime = data[`${element.varKey}_seek-absolute`]
            updateData({[`${element.varKey}_seek-absolute`]: null})
        }
        else if (typeof data[`${element.varKey}_seek-relative`] === 'number')
        {
            ref.current.currentTime = ref.current.currentTime + data[`${element.varKey}_seek-relative`]
            updateData({[`${element.varKey}_seek-relative`]: null})
        }

        // stop
        if (data[`${element.varKey}_stop`] === true)
        {
            updateData({[`${element.varKey}_paused`]: true, [`${element.varKey}_stop`]: null})
            ref.current.currentTime = 0
        }

        // volume
        if (typeof data[`${element.varKey}_volume`] === 'number' && data[`${element.varKey}_volume`] !== ref.current.volume)
        {
            let newVolume = data[`${element.varKey}_volume`]
            
            if (newVolume > 1 || newVolume < 0)
            {
                newVolume = Math.min(1, Math.max(0, newVolume)) // constrain to 0.0 - 1.0
                updateData({[`${element.varKey}_volume`]: newVolume})
            }
            else
            {
                // apply volume to player
                ref.current.volume = newVolume
            }
        }
        else if (typeof data[`${element.varKey}_volume-relative`] === 'number')
        {
            updateData({[`${element.varKey}_volume`]: ref.current.volume + data[`${element.varKey}_volume-relative`], [`${element.varKey}_volume-relative`]: null})
        }
    }, [data, element.varKey, play, pause, updateData])


    return  <span
                {...attributes}
                contentEditable={false}
                draggable={false}
            >
                <video
                    controls={data[`${element.varKey}_controls`] === false ? false : true}
                    controlsList='nodownload'
                    loop={data[`${element.varKey}_loop`] === true ? true : false}
                    muted={!!passive}
                    playsInline
                    preload='auto'
                    ref={ref}
                    style={{ width: `${element.width}%` }}
                >
                    <source src={element.url} />
                </video>
                {children}
            </span>
}